import { Injectable, Type } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
/**
 * This is a service which enables us to open any component inside a dialog at run-time.
 * It will lazy load that component whenever required
 */
@Injectable()
export class LsDialogService<T> {
  /**
   * The constructor
   * @param dialog 
   */
  constructor(private dialog:MatDialog ) {
   
   }
   /**
    * This open method will take a component as input and open it. It also take a initial configuration.
    * @param t 
    * @param config 
    * @param dataList 
    */
  public open(t:Type<any>,config,dataList) {
   let lsDialog;
   
   lsDialog=this.dialog.open(t,config);
   lsDialog.componentInstance.dataList=dataList;
   let dialog=new LsMdDialog( lsDialog);
   lsDialog.componentInstance.dialog=dialog;
   return lsDialog.afterClosed();
  }

}
/**
 * Export md dialog class
 */
export class LsMdDialog<T>{
  /**
   * The constructor
   * @param matDialogRef 
   */
  constructor(
    private matDialogRef : MatDialogRef<T>
  ){}
    /**
     * dialog close 
     * @param value 
     */
  public close(value){
    /**
     * Dialog close ref
     */
  this.matDialogRef.close(value);
  }
}

import { Component, OnInit } from '@angular/core';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';

@Component({
  selector: 'app-okay-message',
  templateUrl: './okay-message.component.html',
  styleUrls: ['./okay-message.component.scss']
})
export class OkayMessageComponent implements OnInit {

  msg;
  dialog: LsMdDialog<OkayMessageComponent>
  dataList;

  constructor() { }

  ngOnInit() {
   this.msg = this.dataList;
  }

  closeDialog(){
    this.dialog.close(null);
  }

}

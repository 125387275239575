
import { Component, OnInit, Input, AfterViewInit, OnDestroy, Output, EventEmitter, ElementRef, ViewChild, Renderer2, OnChanges } from '@angular/core';
import * as $ from 'jquery'
declare const tinymce: any
/**
 * Provides the view for the TinyMCE rich text editor
 */
@Component({
  selector: 'ls-rte',
  templateUrl: './rte.component.html',
  styleUrls: ['./rte.component.css']
})
export class RteComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  /**
 * Id at which RTE will be attached.
 */
  @Input('elementId') elementId: string;
  /**
  * List of plugins
  */
  @Input('plugins') plugins: Array<string>;
  /**
  * Max height of the RTE
  */
  @Input('max-height') maxheight: number;
  /**
   * Max width of the RTE
   */
  @Input('max-width') maxwidth: number;
  /**
   * Options to visible in toolbar
   */
  @Input('toolbar') toolbar: string;
  /**
   * Allow resize flag
   */
  @Input('resize') resize: any;
  /**
   * Show status bar flag
   */
  @Input('statusbar') statusbar: boolean;
  /**
   * height of the RTE
   */
  @Input('height') height: string;
  /**
   * width of the RTE
   */
  @Input('width') width: string;
  /**
   * Show menu bar flag.
   */
  @Input('menubar') menubar: boolean;
  /**
   * CDN url for theme loading 
   */
  @Input('cdn-url') cdn: string;
  /**
  * CDN file upload link
  */
  @Input('cdn-upload') cdnUpload: string;
  /**
  * CDN file download link
  */
  @Input('cdn-download') cdnDownload: string;
  /**
   * Base url for fetching theme files
   */
  @Input('base-url') baseUrl: string;
  /**
   * RTE content.
   */
  @Input('content') content: string;
  /**
   * For reloading the RTE
   */
  @Input('init') init: boolean
  /** 
   * To Provide default styling of the editor.
   * Stylings must be given .${class-name}{} format string.
   * class-name: rte default styling class
   */
  @Input('content_style') content_style: string = "";
  @Input('comment_AuthorName') commentAuthorName: string = "";
  @Input('comment_Author') commentAuthor: string = "";
  /**
   * Key Up event Emitter
   */
  @Output() onEditorKeyUp = new EventEmitter<any>();
  /**
   * On focus event emitter
   */
  @Output() onFocus = new EventEmitter<any>();


  /**
   * Editor Reference
   */
  editor;
  /**
   * File uploader reference
   */
  @ViewChild('lsrtefile', { static: true }) fileRef: ElementRef;
  /**
   * 1. Intialize and Load the RTE.
   */
  ngAfterViewInit() {
    var cdnuplink, cdndownlink;
    cdnuplink = this.cdnUpload;
    cdndownlink = this.cdnDownload;
    // tinymce.baseURL = this.baseUrl;

    // console.log(this.fileRef); *FOR DEBUG PURPOSE*   
    tinymce.init({
      selector: '#' + this.elementId,
      plugins: this.plugins,
      skin_url: this.cdn,
      toolbar: this.toolbar,
      branding: false,
      max_height: this.maxheight,
      max_width: this.maxwidth,
      menubar: this.menubar,
      resize: this.resize,
      statusbar: this.statusbar,
      width: this.width,
      height: this.height,
      content_style: this.content_style,
      file_picker_callback: function (cb, value, meta) {
        var input = <HTMLInputElement>document.getElementById('lsrtefile');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function () {
          var file = input.files[0];

          var reader = new FileReader();
          reader.onload = function () {
            var id = 'blobid' + (new Date()).getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.toString().split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      //   automatic_uploads: true,

      setup: editor => {
        this.editor = editor;
        editor.on('click', () => {
          const content = editor.getContent();
          this.onEditorKeyUp.emit(content);
        })
        editor.on('keyup', () => {
          const content = editor.getContent();
          this.onEditorKeyUp.emit(content);
        })
        editor.on('ExecCommand', () => {
          const content = editor.getContent();
          this.onEditorKeyUp.emit(content);
        })
        editor.on('paste', (e) => {
          let clipboardData = e.clipboardData || window['clipboardData'];
          let pastedData;
          if (clipboardData) {
            pastedData = clipboardData.getData('Text');
          }
          // Stop data actually being pasted into div
          e.stopPropagation();
          e.preventDefault();
          if (!pastedData) {
            return;
          }

          if (tinymce && tinymce.activeEditor) {
            tinymce.activeEditor.execCommand('mceInsertContent', false, pastedData);
            // tinymce.activeEditor.setContent(tinymce.activeEditor.getContent() + pastedData);

            let a = document.createElement('div')
            a.innerHTML = tinymce.activeEditor.getContent();
            a.style.visibility = "hidden";
            let b = document.body.appendChild(a);
            let ch = b.clientHeight;

            a.remove()
            tinymce.activeEditor.iframeElement.style.height = tinymce.activeEditor.getBody().clientHeight + ch + 100 + 'px';
          }




        })
      },
      images_upload_handler: function (blobInfo, success, failure) {
        var xhr, formData;
        formData = new FormData();
        formData.append('file0', blobInfo.blob());
        var nameWOExt = blobInfo.filename().substr(0, blobInfo.filename().lastIndexOf('.'));

        $.ajax({
          type: "POST",
          url: cdnuplink + nameWOExt,
          data: formData,
          processData: false,
          contentType: false,
          success: function (data) {
            success(cdndownlink + blobInfo.filename());
          },
          error: function (data) {
            failure(data);
          }
        });
      },
      tinycomments_author: this.commentAuthor,
      tinycomments_author_name: this.commentAuthorName,
      tinycomments_mode: 'embedded'
    });
  }
  /**
    * Removes tinymce.
    */
  ngOnDestroy() {
    tinymce.remove(this.editor);
  }
  /**
   * constructor
   */
  public constructor() { }
  /**
   * Because After view init is not running.
   * @ignore
   */
  ngOnInit() {
    this.ngAfterViewInit()
  }
  /**
   * Tracks the content change
   */
  ngOnChanges() {
    if (this.init) {
      if (tinymce && tinymce.activeEditor) {
        if (this.editor)
          this.editor.setContent(this.content);
        // tinymce.activeEditor.setContent(this.content)
      }
    }
  }

}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../common/pipes/safe-html.pipe';
import { ExpandMenuDirective } from './expand-menu.directive';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ICONARRAY } from '../published/common-data/icon.array';
import { DomSanitizer } from '@angular/platform-browser';
import { LsRatingModule } from '../LSNG/components/ls-rating/ls-rating.module';
import { LsCarouselModule } from '../LSNG/components/ls-carousel/ls-carousel.module';
import { LsDialogModule } from '../LSNG/components/ls-dialog/ls-dialog.module';
import { LsTextFieldModule } from '../LSNG/components/ls-textfield/ls-textfield.module';
import { LsDatePickerModule } from '../LSNG/components/ls-date-picker/ls-date-picker.module';
import { LsRadioModule } from '../LSNG/components/ls-radio/ls-radio.module';
import { LsComboModule } from '../LSNG/components/ls-combo/ls-combo.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LsTabPanelModule } from '../LSNG/components/ls-tab-panel/ls-tab-panel.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { LsSuggestionFieldModule } from '../LSNG/components/ls-suggestion-field/ls-suggestion-field.module';
import { LsTagContainerModule } from '../LSNG/components/ls-tag-container/ls-tag-container.module';
import { SafeUrlPipe } from '../common/pipes/safe-url.pipe';
import { IsUserLoginPipe } from '../common/pipes/is-user-login.pipe';
import { LsRteModule } from '../LSNG/components/ls-rte/ls-rte.module';
import { ProductQueryPipe } from '../common/pipes/product-query.pipe';
import { FilterArrayPipe } from '../common/pipes/filter-array.pipe';
import { QueryParamExtractPipe } from '../common/pipes/query-param-extract.pipe';
import { RemoveQueryParamPipe } from '../common/pipes/remove-query-param.pipe';
import { SetVariantNamePipe } from '../common/pipes/set-variant-name.pipe';
import { IsPopupModePipe } from '../common/pipes/is-popup-mode.pipe';
import { UrlFormatterPipe } from '../common/pipes/url-formatter.pipe';
import { GetStoreNamePipe } from '../common/pipes/getStoreName.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    LsRatingModule,
    LsCarouselModule,
    LsDialogModule,
    LsTextFieldModule,
    LsDatePickerModule,
    LsComboModule,
    LsRadioModule,
    LsTabPanelModule,
    LsSuggestionFieldModule,
    LsTagContainerModule,
    ReactiveFormsModule,
    MatTabsModule,
    FormsModule,
    FlexLayoutModule,
    LayoutModule,
    RouterModule,
    HttpClientModule,
    LsRteModule
  ],
  declarations: [
    ExpandMenuDirective,
    IsUserLoginPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    ProductQueryPipe,
    FilterArrayPipe,
    QueryParamExtractPipe,
    RemoveQueryParamPipe,
    SetVariantNamePipe,
    IsPopupModePipe,
    ExpandMenuDirective,
    UrlFormatterPipe,
    GetStoreNamePipe
  ],
  exports: [
    RouterModule,
    ExpandMenuDirective,
    IsUserLoginPipe,
    SafeHtmlPipe,
    ExpandMenuDirective,
    MatIconModule,
    LsRatingModule,
    UrlFormatterPipe,
    GetStoreNamePipe,
    LsCarouselModule,
    LsDialogModule,
    LsTextFieldModule,
    LsDatePickerModule,
    LsComboModule,
    LsRadioModule,
    LsTabPanelModule,
    LsSuggestionFieldModule,
    LsTagContainerModule,
    ReactiveFormsModule,
    MatTabsModule,
    FormsModule,
    FlexLayoutModule,
    CommonModule,
    LayoutModule,
    SafeUrlPipe,
    HttpClientModule,
    LsRteModule,
    ProductQueryPipe,
    FilterArrayPipe,
    QueryParamExtractPipe,
    RemoveQueryParamPipe,
    SetVariantNamePipe,
    IsPopupModePipe
  ],
  providers: [
    IsUserLoginPipe,
    QueryParamExtractPipe
  ]
})

export class SharedModuleModule {
}

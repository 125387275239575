import { Component, OnInit, Output, Input, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CategoryListPageService } from '../../../services/categoryListPage.service';
import { FilterModel } from '../../../common/models/filterModel';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {

  @Output() onSelectingFilter: EventEmitter<any> = new EventEmitter<any>()
  @Output() applyFilterClose: EventEmitter<any> = new EventEmitter<any>()
  @Input('filter-list') filterList: Array<FilterModel> = new Array<FilterModel>();
  showDetails = []
  filterModel: Array<FilterModel> = []
  dataToParent: Array<{ filterModel: Array<FilterModel>, checkbox: boolean }> = []
  filterIdArr = []
  filterValueIdArr = []
  qParams
  params
  route;
  _routeSubscription;
  _paramsSubscription;

  constructor(
    private categoryListService: CategoryListPageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route = this.router.url
    this._routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((data: NavigationEnd) => {
      this.route = data.url
      if (!data.urlAfterRedirects.includes('?')) {
        if (data.url.includes('collections/')) {
          this.filterModel = [];
        }
      }
    });
    if (this.activatedRoute.snapshot.queryParams != undefined && this.activatedRoute.snapshot.queryParams.setTypeData != undefined
      && this.activatedRoute.snapshot.queryParams.dataFor != undefined) {
      this.activatedRoute.snapshot.queryParams.setTypeData.split("#").forEach(ele => {
        this.filterIdArr.push(parseInt(ele));
      })
      this.activatedRoute.snapshot.queryParams.dataFor.split("#").forEach(ele => {
        this.filterValueIdArr.push(parseInt(ele));
      })
      this.getFilters();
    }
  }

  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._paramsSubscription) {
      this._paramsSubscription.unsubscribe();
    }
  }

  getFilters() {
    if (this.filterIdArr.length > 0) {
      for (let i = 0; i < this.filterIdArr.length; i++) {
        this.checkAdd(this.filterIdArr[i], this.filterValueIdArr[i])
      }
      this.setDefaultData(this.filterIdArr, this.filterValueIdArr);
    }
  }

  setDefaultData(filterId, filterValueId) {
    if (filterId.length != 0 && filterValueId.length != 0 && this.filterModel != undefined && this.filterList != undefined) {
      for (let i = 0; i < filterId.length; i++) {
        let model: FilterModel = new FilterModel(); let flag = false;
        if (this.filterModel != undefined && this.filterModel.length > 0) {
          for (let filter = 0; filter < this.filterModel.length; filter++) {
            if (this.filterModel[filter].filterId == filterId[i]) {
              for (let j = 0; j < this.filterList.length; j++) {
                if (this.filterList[j].filterId == filterId[i]) {
                  for (let k = 0; k < this.filterList[j].filterModelList.length; k++) {
                    if (this.filterList[j].filterModelList[k].filterValueId == filterValueId[i]) {
                      this.filterModel[filter].filterModelList.push(this.filterList[j].filterModelList[k]);
                      flag = true;
                      break;
                    }
                  }
                  break;
                }
              }
            }
          }
        }
        if (!flag) {
          for (let i1 = 0; i1 < this.filterList.length; i1++) {
            if (this.filterList[i1].filterId == filterId[i]) {
              for (let j1 = 0; j1 < this.filterList[i1].filterModelList.length; j1++) {
                if (this.filterList[i1].filterModelList[j1].filterValueId == filterValueId[i]) {
                  model.colorAvailable = this.filterList[i1].colorAvailable
                  model.filterId = this.filterList[i1].filterId
                  model.filterName = this.filterList[i1].filterName
                  model.filterType = this.filterList[i1].filterType
                  model.filterTypeId = this.filterList[i1].filterTypeId
                  model.seqNo = this.filterList[i1].seqNo;
                  model.filterModelList = new Array();
                  model.filterModelList.push(this.filterList[i1].filterModelList[j1]);
                  this.filterModel.push(model);
                  break
                }
              }
              break
            }
          }
        }
      }
    }
  }

  toggleDetails(index, event) {
    if (event.target.className.includes('colSpan')) {
      event.target.className = "expColSpan fa expSpan"
    } else {
      event.target.className = "expColSpan fa colSpan"
    }
    this.showDetails[index] = !this.showDetails[index];
  }


  filterData(event, currentIndex, parentIndex, filterId, filterValueId) {
    let model: FilterModel = new FilterModel(); let flag = false;
    if (event.target.checked) {
      if (this.filterModel[0] != undefined && this.filterModel[0].filterModelList.length == 0) {
        this.filterModel = []
      }
      for (let i = 0; i < this.filterModel.length; i++) {
        if (this.filterModel[i].filterId == filterId) {
          this.filterModel[i].filterModelList.push(this.filterList[parentIndex].filterModelList[currentIndex]);
          flag = true;
        }
      }
      if (!flag) {
        model.colorAvailable = this.filterList[parentIndex].colorAvailable
        model.filterId = this.filterList[parentIndex].filterId
        model.filterName = this.filterList[parentIndex].filterName
        model.filterType = this.filterList[parentIndex].filterType
        model.filterTypeId = this.filterList[parentIndex].filterTypeId
        model.seqNo = this.filterList[parentIndex].seqNo;
        model.filterModelList = new Array();
        model.filterModelList.push(this.filterList[parentIndex].filterModelList[currentIndex]);
        this.filterModel.push(model);
      }
      this.filterIdArr.push(filterId)
      this.filterValueIdArr.push(filterValueId)
      this.params = {
        setTypeData: this.filterIdArr.join('#'),
        dataFor: this.filterValueIdArr.join('#')
      }
      this.qParams = Object.assign({}, this.params)
      this.router.navigate([this.route.split("?")[0]], { queryParams: this.qParams })
    } else {
      if (this.filterModel != undefined) {
        for (let i = 0; i < this.filterModel.length; i++) {
          if (this.filterModel[i].filterModelList.length > 0) {
            if (this.filterModel[i].filterType != undefined && this.filterModel[i].filterId == filterId) {
              for (let j = 0; j < this.filterModel[i].filterModelList.length; j++) {
                if (this.filterModel[i].filterModelList[j].filterValueId != undefined &&
                  this.filterModel[i].filterModelList[j].filterValueId == filterValueId) {
                  this.filterModel[i].filterModelList.splice(j, 1)
                }
              }
            }
          } else {
            this.filterModel.splice(i, 1)
          }
        }
      }
      let len = this.filterIdArr.length
      for (let i = 0; i <= len; i++) {
        if (this.filterIdArr[i] == filterId) {
          this.filterIdArr.splice(i, 1)
          this.filterValueIdArr.splice(i, 1)
        }
      }
      this.params = {
        setTypeData: this.filterIdArr.join('#'),
        dataFor: this.filterValueIdArr.join('#')
      }
      if (this.filterIdArr.length > 0) {
        this.qParams = Object.assign({}, this.params)
        this.router.navigate([this.route.split("?")[0]], { queryParams: this.qParams })
      } else {
        this.router.navigate([this.route.split("?")[0]])
      }
    }
    this.onSelectingFilter.emit(this.filterModel);
  }

  checkRemove(filterId, filterValueId) {
    let len = this.filterIdArr.length
    for (let i = 0; i < len; i++) {
      if (this.filterIdArr[i] == filterId && this.filterValueIdArr[i] == filterValueId) {
        this.filterIdArr.splice(i, 1)
        this.filterValueIdArr.splice(i, 1)
      }
    }

    for (let index = 0; index < this.filterList.length; index++) {
      var element = this.filterList[index];
      if (element.filterId == filterId) {
        for (var index1 = 0; index1 < element.filterModelList.length; index1++) {
          var element1 = element.filterModelList[index1];
          if (element1.filterValueId == filterValueId) {
            element1.checked = false
            break
          }
        }
        break
      }
    }
  }

  checkRemoveAll() {
    for (let index = 0; index < this.filterList.length; index++) {
      var element = this.filterList[index];
      for (var index1 = 0; index1 < element.filterModelList.length; index1++) {
        var element1 = element.filterModelList[index1];
        element1.checked = false
      }
    }
    this.filterModel = [];
    this.filterIdArr = [];
    this.filterValueIdArr = [];
  }

  checkAdd(filterId, filterValueId) {
    if (this.filterList.length > 0) {
      for (let index = 0; index < this.filterList.length; index++) {
        var element = this.filterList[index];
        if (element.filterId == filterId) {
          for (var index1 = 0; index1 < element.filterModelList.length; index1++) {
            var element1 = element.filterModelList[index1];
            if (element1.filterValueId == filterValueId) {
              element1.checked = true
              break
            }
          }
          break
        }
      }
    }
  }

  applyFilters() {
    this.applyFilterClose.emit();
  }

}

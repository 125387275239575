import { Injectable } from '@angular/core';
import { HttpService } from './http.service'
import { Constants } from '../common/constants/lsnetx.constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnquiryModel } from '../common/models/enquiry-model';

/**
 * It sends,deletes the enquiry.
 * It fetches the enquiries.
 * It downloads the attachments.
 * @class EnquireService
 */
@Injectable()
export class EnquireService {
  /**
   * BS of enquiry Cart
   */
  enquiryCartBehaviour: BehaviorSubject<EnquiryModel>;
  /**
   * Observable of EnquiryModel
   */
  enquiryCart: Observable<EnquiryModel>;
  /**
   * EnquiryModel state 
   */
  private enquiryCartState: {
    enqCart: EnquiryModel;
  }

  /**
   * DI
   * @param httpService HttpService
   */
  constructor(
    public httpService: HttpService
  ) {
    this.enquiryCartBehaviour = <BehaviorSubject<EnquiryModel>>new BehaviorSubject(new EnquiryModel());
    this.enquiryCart = this.enquiryCartBehaviour.asObservable();
    this.enquiryCartState = { enqCart: undefined };
  }

  /**
   * It  sends the enquiry.
   * @param formData - data of the form 
   * @param enquireModel - contains the data of the enquiry.
   * @param {boolean} updateEnquiry - update enquiry or not 
   * @param callback fn
   */
  sendEnquiry(formData, enquireModel, updateEnquiry, callback: (data) => void) {
    let url;
    if (updateEnquiry) {
      url = Constants.BASE_URL + "/api/updateUserEnquiryCart";
    } else {
      url = Constants.BASE_URL + "/api/addToUserEnquiryCart";
    }
    let requestParams = new Map();
    // requestParams.set("enquiryModel", enquireModel);
    this.httpService.post(url, formData, requestParams, (resp) => {
      // console.log(resp);
      callback(resp);
    })
  }

  /**
   * It fetches the enquiries
   * @param callback fn 
   */
  getMyEnquiries(callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/getMyEnquiriesCart";
    let requestParams = new Map();
    let params = new Map();
    this.httpService.post(url, params, requestParams, (resp) => {
      callback(resp);
    })
  }

  /**
   * It posts the enquiry message.
   * @param enquireModel - It contains the enquiry information.
   * @param callback fn
   */
  postMessage(enquireModel, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/addConversationRegardingEnquiry";
    let requestParams = new Map();
    this.httpService.post(url, enquireModel, requestParams, (resp) => {
      callback(resp);
    })
  }

  /**
   * It fetches the conversation regarding the enquiry.
   * @param cartId - cart Id
   * @param callback fn
   */
  fetchAllConversation(cartId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/getConversationRegardingEnquiry";
    let requestParams = new Map();
    requestParams.set('cartId', cartId);
    this.httpService.post(url, {}, requestParams, (resp) => {
      callback(resp);
    })
  }

  /**
   * It fetches the particular cart according to the cartId.
   * @param cartId - number
   * @param callback fn
   */
  getParticularCart(cartId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/getMyEnquiriesParticularCart";
    let requestParams = new Map();
    requestParams.set('cartId', cartId);
    this.httpService.post(url, {}, requestParams, (resp) => {
      callback(resp);
    })
  }

  /**
   * It deletes the particular item from enquiry based on cartId.
   * @param model - UserCartDetailDto of product which is to be deleted
   * @param cartId - cartId
   * @param callback fn
   */
  deleteParticularEnquiry(model, cartId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/deleteItemFromEnquiry";
    let requestParams = new Map();
    requestParams.set('cartId', cartId);
    // requestParams.set('cartProductModel', model);
    this.httpService.post(url, model, requestParams, (resp) => {
      callback(resp);
    })
  }

  /**
   *It deletes enquiry based on cartId. 
   * @param cartId - cartId
   * @param callback fn
   */
  deleteEnquiry(cartId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/deleteUserEnquiry";
    let requestParams = new Map();
    requestParams.set('cartId', cartId);
    this.httpService.post(url, {}, requestParams, (resp) => {
      callback(resp);
    })
  }

  /**
   * It is used to download attachment.
   * @param cartId - cartId
   * @param callback fn
   */
  downloadFile(cartId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/getEnquiryAttachment";
    let requestParams = new Map();
    requestParams.set('cartId', cartId);
    this.httpService.post(url, {}, requestParams, (resp) => {
      callback(resp);
    })
  }

  /**
   * updates current enquiryCartObservable
   * @param enquiryModel EnquiryModel
   */
  updateEnquiryCart(enquiryModel: EnquiryModel) {
    this.enquiryCartState.enqCart = enquiryModel;
    this.enquiryCartBehaviour.next(Object.assign({}, this.enquiryCartState).enqCart);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LsComboComponent } from './combo/combo.component';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms'
import { FormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule
  ],
  exports: [LsComboComponent],
  declarations: [LsComboComponent]
})

export class LsComboModule { }

export class BannerModel{
    
companyId:number;
storeId:number;
templateId:number;
categoryId:string;
bannerType:number;
recNo:number;
imageName:string;
imageHref:string;
videoURL:string;
altText:string;
title:string;
titleColorCode:string;
desc:string;
descColorCode:string;
imageName1 : string;
imageName2:string;
seqNo:number;
visibility:number;
mobilUrl:string;
    
}




import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
/**
 * Open login obs 
 */
@Injectable()
export class LoginRedirectService {
  /**
   * Subject of boolean
   */
  openLogin: Subject<boolean> = new Subject<boolean>()
  /**
   * Intialization 
   */
  constructor(
  ) { }
  /**
   * subject next true
   */
  public open() {
    this.openLogin.next(true);
  }
  /**
 * subject next false
 */
  public close() {
    this.openLogin.next(false);
  }
  /**
   * Get observable 
   */
  public getLoinOpenObs() {
    return this.openLogin
  }
}

import { Injectable } from '@angular/core';
import { DetailsComponent } from '../published/components/details/details.component';
import { SeoService } from './seo.service';
import { CanDeactivate } from '@angular/router';
/**
 * On route unload 
 * empty meta informations 
 */
@Injectable()
export class DetailGuard implements CanDeactivate<DetailsComponent> {
  /**
   * DI
   * @param seo SeoService Di
   */
  constructor(
    private seo: SeoService
  ) { }
  /**
   * based on this method return value route uload will be decided 
   * @param component component 
   */
  canDeactivate(component: any): boolean {
    this.seo.initializeTagsAgain();
    return true;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LsDialogService} from './ls-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  imports: [
    CommonModule,MatDialogModule
  ],
  declarations: []
})
export class LsDialogModule { }

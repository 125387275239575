/**
 * Angular components
 */
import {
  Component,
  OnInit,
  ViewChildren,
  ViewChild,
  QueryList,
  ComponentFactoryResolver,
  Input,
  Output,
  EventEmitter,
  Type,
  AfterViewInit,
  ElementRef,
  Renderer2,
  OnChanges
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
/**
 * DTO and Models
 */
import { SuggestionOption } from '../suggestion-option';
import { SearchDTO } from '../../../commons/searchDTO';
/**
 * Services
 */
import { DataFetchService } from '../data-fetch.service';
/**
 * Angular components
 */
import { SuggestionOptionHostDirective } from '../suggestion-option-host.directive';
import { SuggestionTagDirective } from '../suggestion-tag.directive';
/**
 * This is the parent component of Multiple Suggestion
 */
@Component({
  selector: 'app-multiple-suggestion',
  templateUrl: './multiple-suggestion.component.html',
  styleUrls: ['./multiple-suggestion.component.css']
})
/**
 * Parent class of the components
 */
export class MultipleSuggestionComponent implements OnInit, AfterViewInit, OnChanges {
  /**
   * Varriable to store last key clicked
   */
  prev
  /**
   * Variable to store value of key clicked
   */
  keyCode
  /**
   * Constant to store how much time has passed through last button click
   */
  timeDiff: number;
  /**
   * Constant for Enter Key
   */
  enter_key_event: number = 13

  /**
   * Constructor of the Classroom
   * @param _dataFetchService Dependency Injection
   * @param componentFactoryResolver Dependency Injection
   * @param _eleRef Dependency Injection
   * @param renderer Dependency Injection
   */
  constructor(
    private _dataFetchService: DataFetchService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private _eleRef: ElementRef,
    private renderer: Renderer2
  ) {
    let curr = 0
    this.timeDiff = 0;
    /**
     * Listen to global keypress event
     */
    renderer.listen('document', 'keypress', (event) => {
      this.prev = curr
      curr = new Date().getTime()
      this.timeDiff = curr - this.prev
      this.keyCode = event.keyCode
    })
  }

  /**
   * Angular life cycle hook
   */
  ngOnChanges() {
    if (this.close) {
      this.closeSuggestionModal();
    }
  }

  /**
   * Angular life cycle hook
   */
  ngOnInit() {
    if (this.initialAutoRequest) {
      /**
       * If the request type is 1 then use Post method else get
       */
      if (this.requestType == 1) {
        this.reFetchGet();
      } else if (this.requestType == 2) {
        this.reFetchPost();
      }
    }
  }

  /**
   * Variable to store value in Input
   */
  textValue: string;
  /**
   * Variabel to store whether to show the search results or not
   */
  openSuggestionModal: boolean;
  /**
   * URL where requests are sent
   */
  _fetchurl: string;
  /**
   * Response received from a Request
   */
  _response: any;
  /**
   * Variable to store error message 
   */
  _errorMsg: any;
  /**
   * List to store data shown in list1
   */
  dataList1: any;
  /**
   * List to store data shown in list2
   */
  dataList2: any;
  /**
   * X cordinate where the search field will be shown
   */
  modalLeft: number;
  /**
   * Y cordinate where the search field will be shown
   */
  modalTop: number;
  /**
   * X cordinate where list will be shown
   */
  left: string;
  /**
   * Y cordinate where list will be shown
   */
  top: string;
  tagList = [];
  /**
   * Special key codes for which request won't be send
   */
  specialKeyCodes = [38, 40, 13];
  /**
   * 
   */
  currentOption: number = -1;
  prevOption: number = -1;

  /**
   * Placeholder to be shown in the search field
   */
  @Input('placeholder') placeholder;
  /**
   * Base URL for the end point 
   */
  @Input('base-url') baseurl;
  /**
   * Parameter name where search is made, to be passed in the URL of the API
   */
  @Input('searchon-param-name') searchOnName;
  /**
   * Parameter value where search is made, to be passed in the URL of the API
   */
  @Input('searchon-param-value') searchOnValue;
  /**
   * Current name to be passed in the API
   */
  @Input('current-param-name') currentName;
  /**
   * Component to be open
   */
  @Input('container') container: Type<any>;
  /**
   * 
   */
  @Input('tag') tagContainer: Type<any>;
  @Input('tags') tags: boolean;
  /**
   * Input property to set max height of the option list
   */
  @Input('max-list-height') maxHeight: string;
  @Input('key-speed-handling') keySpeedHandling: boolean;
  /**
   * whether initial request is required on component loading or not
   */
  @Input('inital-auto-request') initialAutoRequest: boolean = false;
  /**
   * maxlength of field
   */
  @Input('maxlength') maxLength: number;
  /**
   * 1 - get
   * 2 - post
   */
  @Input('request-type') requestType: number = 1;
  /**
   * Event emitter to emit the selected option.
   */
  @Output('optionSelected') optionSelected = new EventEmitter<any>();
  /**
   * 
   */
  @Output('tagsSelected') tagsSelected = new EventEmitter<any>();
  /**
   * Event emitted when enter key is pressed.
   */
  @Output('enterKeyPressed') enterKeyPressed = new EventEmitter<any>();
  /**
   * Event emitted when input is blurred
   */
  @Output('blur') blurEvent = new EventEmitter<any>()
  /**
   * Directive associated with each option shown.
   */
  @ViewChildren(SuggestionOptionHostDirective) suggestionOptions: QueryList<SuggestionOptionHostDirective>;
  /**
   * Reference to add tags to the list, if required.
   */
  @ViewChildren(SuggestionTagDirective) tagHosts: QueryList<SuggestionTagDirective>;
  /**
   * Reference of the text field where user is typing.
   */
  @ViewChildren('textfield') textField;
  /**
   * Reference to the options shown after user has typed.
   */
  @ViewChildren('option') optionlist;
  /**
   * Reference of the container that contains the option list
   */
  @ViewChild('modal') modal;
  /**
   * Search model to be sent to the BE Team.
   */
  @Input('searchModel') searchModel: SearchDTO;
  /**
   * Used to set initial value in the suggestion component.
   */
  @Input('initialValue') set initialValue(value: string) {
    if (value && value.length) {
      this.textValue = value;
    }
  }
  /**
   * Input propert to manually close the Overlay
   */
  @Input('close') close: boolean = false;
  /**
   * Variable to store previous value of the text field
   */
  prevTextValue: string = '';
  /**
   * Variable to store subscription object
   */
  subscription: Subscription;

  /**
   * Method called to open the suggestions
   * @param event Event as received from DOM
   * @param ref Reference, if any
   */
  public openSuggestions(event, ref: ElementRef): void {
    if (this.specialKeyCodes.indexOf(event.keyCode) >= 0) {
      return;
    }
    this.currentOption = -1;
    this.prevOption = -1;
    this.modalLeft = this._eleRef.nativeElement.offsetLeft;
    this.modalTop = this._eleRef.nativeElement.offsetTop + this._eleRef.nativeElement.offsetHeight;
    this.left = this.modalLeft + 'px';
    this.top = this.modalTop + 'px';
    if (this.textValue !== undefined && this.textValue.trim().length > 0 && event.keyCode != 32) {
      if (event.keyCode == 8) {
        if (this.prevTextValue.trim() === this.textValue.trim()) {
        } else {
          this.openSuggestionModal = true;
          if (this.requestType == 1) {
            this.reFetchGet();
          } else if (this.requestType == 2) {
            this.reFetchPost();
          }
          if (this.keySpeedHandling) {
            if (this.timeDiff > 25) {
              this.openSuggestionModal = true;
            } else {
              this.openSuggestionModal = false;
            }
          }
        }
      } else {
        this.openSuggestionModal = true;
        if (this.requestType == 1) {
          this.reFetchGet();
        } else if (this.requestType == 2) {
          this.reFetchPost();
        }
        if (this.keySpeedHandling) {
          if (this.timeDiff > 25) {
            this.openSuggestionModal = true;
          } else {
            this.openSuggestionModal = false;
          }
        }
      }
    } else {
      this.openSuggestionModal = false;
    }
  }

  /**
   * Method called to set data to be shown as options
   * @param data Data 
   */
  onFetch(data): void {
    if (data && data.data && Object.keys(data).length) {
      if (data.data['keyWordList'] && data.data['keyWordList'].length) {
        this.dataList1 = data.data['keyWordList'];
        this.dataList1.forEach(element => {
          element.label = element.word
        })
      }
      if (data.data['titleList'] && data.data['titleList'].length) {
        this.dataList2 = data.data['titleList'];
        this.dataList2.forEach(element => {
          element.label = element.word
        })
      }
    }

  }

  /**
   * Method used to pass the options to the Resolve Factory Component.
   */
  initComponents() {
    if (this.suggestionOptions === undefined || this.suggestionOptions.length <= 0)
      return;
    let suggCompList = this.suggestionOptions.toArray();
    if (this.dataList1 && this.dataList1.length) {
      for (let i = 0; i < this.dataList1.length; i++) {
        if (suggCompList[i] === undefined)
          continue;
        let factory = this.componentFactoryResolver.resolveComponentFactory(this.container);
        let viewContainerRef = suggCompList[i].viewContainerRef;
        viewContainerRef.clear();
        let componentRef = viewContainerRef.createComponent(factory);
        (<SuggestionOption>componentRef.instance).buildComponent(this.dataList1[i]);
      }
    }
    if (this.dataList2 && this.dataList2.length) {
      for (let i = this.dataList1.length; i < this.dataList2.length + this.dataList1.length; i++) {
        let j = 0;
        if (suggCompList[i] === undefined)
          continue;
        let factory = this.componentFactoryResolver.resolveComponentFactory(this.container);
        let viewContainerRef = suggCompList[i].viewContainerRef;
        viewContainerRef.clear();
        let componentRef = viewContainerRef.createComponent(factory);
        (<SuggestionOption>componentRef.instance).buildComponent(this.dataList2[j]);
        j++;

      }
    }
  }

  /**
   * Angular life cycle hook
   */
  ngAfterViewInit() {
    /**
     * Subcribe to any change in the text field and open suggestions dialog.
     */
    fromEvent(this.textField.first.nativeElement, 'keyup').pipe(
      filter((el: any) => !(this.specialKeyCodes.indexOf(el.keyCode) >= 0)),
      map((el: any) => el.target.value.trim()),
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe(ele => {
      this.openSuggestions(ele, null)

    })
    this.suggestionOptions.changes.subscribe(() => {
      this.initComponents();
    });
    this.tagHosts.changes.subscribe(() => {
      this.addTags();
    });
  }

  /**
   * Method called to remove white spaces from the search field
   * @param str variable whose white spaces has to be removed
   */
  removeWhiteSpaces(str: string): string {
    return str.trim();
  }

  /**
   * Method called to fetch data with Post method
   */
  reFetchPost() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this._fetchurl = this.baseurl;
    this.searchModel.searchdata = this.removeWhiteSpaces(this.textValue);
    this._dataFetchService.postJSONResponse(this._fetchurl, this.searchModel).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.onFetch(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);
    this.subscription = this._dataFetchService.postJSONResponse(this._fetchurl, this.searchModel).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.onFetch(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);

  }

  /**
   * Method called to fetch data with Get method
   */
  reFetchGet() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.textValue !== undefined)
      this._fetchurl = this.baseurl + '?' + this.searchOnName + '=' + this.searchOnValue + '&' + this.currentName + '=' + this.textValue;
    else
      this._fetchurl = this.baseurl + '?' + this.searchOnName + '=' + this.searchOnValue + '&' + this.currentName + '=';
    this._dataFetchService.getJSONResponse(this._fetchurl).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.onFetch(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);
    this.subscription = this._dataFetchService.postJSONResponse(this._fetchurl, this.searchModel).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.onFetch(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);

  }

  /**
   * Methods called to close the suggestion model
   */
  closeSuggestionModal(): void {
    if (!this.openSuggestionModal) {
      setTimeout(() => {
        this.openSuggestionModal = false;
        this.currentOption = -1;
        this.prevOption = -1;
      }, 250);
    }
    else {
      this.openSuggestionModal = false;
      this.currentOption = -1;
      this.prevOption = -1;
    }
    this.openSuggestionModal = false;
    this.currentOption = -1;
    this.prevOption = -1;
    this.blurEvent.emit(this.textValue);
  }

  /**
   * Method called when an option is clicked from the list
   * @param option Option which was selected
   */
  selectOption(option): void {
    this.currentOption = -1;
    this.prevOption = -1;
    this.openSuggestionModal = false;
    this.textValue = option.value || option.word;
    if (this.tags) {
      this.tagList.push(option);
      this.tagsSelected.emit(this.tagList);
      this.textValue = '';
    }
    this.optionSelected.emit(option);
    this.dataList1 = [];
    this.dataList2 = [];
  }

  /**
   * Method called to add tags
   */
  addTags(): void {
    if (this.tagHosts === undefined || this.tagHosts.length <= 0)
      return;
    let tagHostArray = this.tagHosts.toArray();
    for (let i = 0; i < this.tagList.length; i++) {
      if (tagHostArray[i] === undefined)
        continue;
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.tagContainer);
      let viewContainerRef = tagHostArray[i].viewContainerRef;
      viewContainerRef.clear();
      let componentRef = viewContainerRef.createComponent(factory);
      (<SuggestionOption>componentRef.instance).buildComponent(this.tagList[i]);
    }
  }

  /**
   * Method called to close the tag
   * @param tag Tag to be removed
   */
  closeTag(tag): void {
    for (let i = 0; i < this.tagList.length; i++) {
      if (this.tagList[i].value === tag.value) {
        this.tagList.splice(i, 1);
        this.tagsSelected.emit(this.tagList);
        break;
      }
    }
  }

  /**
   * Method called to clear the Tags
   */
  clearTags(): void {
    this.tagList = [];
    this.tagsSelected.emit(this.tagList);
    this.textValue = '';
  }

  /**
   * Method called to set placeholder
   */
  getPlaceholder(): string {
    if (this.tagList.length > 0) {
      return '';
    }
    else {
      return this.placeholder
    }
  }

  /**
   * Method called to focus the text field if required.
   */
  focusTextField(): void {
    this.textField.first.nativeElement.focus();
  }

  /**
   * Method called when there is a change in the text field
   * @param event Event as received from DOM
   */
  handleKeys(event): void {
    this.prevTextValue = event.target.value;
    if (this.specialKeyCodes.indexOf(event.keyCode) < 0) {
      return;
    }
    if ((event.keyCode == 40 || event.keyCode == 38) && !this.openSuggestionModal) {
      this.openSuggestionModal = true;
      this.modalLeft = this._eleRef.nativeElement.offsetLeft;
      this.modalTop = this._eleRef.nativeElement.offsetTop + this._eleRef.nativeElement.offsetHeight;
      this.left = this.modalLeft + 'px';
      this.top = this.modalTop + 'px';
    } else if (event.keyCode == 40 || event.keyCode == 38) {
      let optionArray = this.optionlist.toArray();
      this.prevOption = this.currentOption;
      if (event.keyCode == 40)
        this.currentOption++;
      else if (event.keyCode == 38)
        this.currentOption = (this.currentOption - 1 + optionArray.length) % optionArray.length;
      optionArray.forEach(element => {
        if (element && element.nativeElement && element.nativeElement.classList && element.nativeElement.classList.contains("focusedOption")) {
          element.nativeElement.classList.remove("focusedOption")
        }
      })
      optionArray[this.currentOption % optionArray.length].nativeElement.classList.add("focusedOption");
      let modal = this.modal.nativeElement;
      let current = optionArray[this.currentOption % optionArray.length].nativeElement;
      let maxHeight = 0;
      if (modal.style.maxHeight != undefined && modal.style.maxHeight != '')
        maxHeight = parseInt(modal.style.maxHeight.substr(0, modal.style.maxHeight.length - 2));
      let viewHeight = modal.scrollTop + maxHeight;
      if (current.offsetTop + current.offsetHeight > viewHeight || current.offsetTop < modal.scrollTop) {
        modal.scrollTop = current.offsetTop;
      }
    }
    if (event.keyCode == 8 && this.textValue.trim().length <= 0 && this.tagList.length > 0) {
      this.tagList.pop();
      this.tagsSelected.emit(this.tagList);
    }
    if (event.keyCode == 13) {
      this.enterKeyPressed.emit();
      if (this.currentOption == -1) {
        this.selectOption({
          label: this.textValue,
          value: this.textValue,
          special: true,
          data: this.dataList1
        })
        this.selectOption({
          label: this.textValue,
          value: this.textValue,
          special: true,
          data: this.dataList2
        })
      }
      let optionArray = this.optionlist.toArray();
      if (this.currentOption >= 0 && this.currentOption < optionArray.length) {
        optionArray[this.currentOption].nativeElement.click();
      }
    }
  }

}


import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeStyle, SafeScript,SafeUrl, SafeResourceUrl} from '@angular/platform-browser';

/**
 * for adding url to any DOM element safely.
 */
@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  /**
   * @constructor
   * @param sanitizer - DOM sanitizer
   */
  constructor(private sanitizer:DomSanitizer){}

  /**
   * @param value - url to be added in DOM element 
   * @param args 
   */
  transform(value: any, args?: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

}

<mat-form-field [appearance]="appearance" [ngClass]="{'disable': disabled}" [color]="underlineColor" #field
  [floatLabel]="floatPlaceholder">
  <i matPrefix class="material-icons" *ngIf="iconLeft">{{iconClass}}</i>
  <span matPrefix>{{prefix}}</span>
  <input [attr.aria-label]="label" [autocomplete]="autocomplete" (blur)="onBlur()" [pattern]="pattern" matInput [disabled]="disabled"
    [required]="required" [placeholder]="placeholder" [(ngModel)]="textFieldValue" [type]="contentType"
    [value]="textFieldValue" [min]="min" [max]="max" [step]="step" (keypress)="onKeyPress($event)"
    (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (change)="onChangeEvent()" #textfieldRef
    [attr.maxlength]="maxLength" (focus)="focusEmitter($event)" [attr.tabindex]="tabIndex">
  <span matSuffix>{{suffix}}</span>
  <i matSuffix *ngIf="clearOption && textFieldValue && (textFieldValue.length > 0)" (click)="textClear($event)"
    class="fa fa-times" style="float: right;"></i>
  <i matSuffix *ngIf="iconRight" class="material-icons">{{iconClass}}</i>
  <mat-hint>{{hintLabel}}</mat-hint>
  <mat-hint *ngIf="characterCount" align="end">{{textfieldRef.value?.length || 0}} / {{maxLength}}</mat-hint>
</mat-form-field>
import { Directive, HostListener, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[LsDragDrop]'
})
export class LsDragDropDirective {

  @Output() dragDropEvent = new EventEmitter<any>();

  constructor() { }

  @HostListener("drop",['$event'])
  onDrop( event ){
    this.dragDropEvent.emit(event);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { Constants } from '../../../common/constants/lsnetx.constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPwdForm : FormGroup

  constructor(
    public formBuilder : FormBuilder,
    private router: Router,        
    public loginService : LoginService,
    public dialogService: LsDialogService<OkayMessageComponent>,
    
    
  ) { }

  ngOnInit() {
    this.forgotPwdForm = this.formBuilder.group({
      email : ["", [Validators.email, Validators.required]]
    })
  }

  forgotPwdRequest($event){
    let email = this.forgotPwdForm.value.email;
    if(email === "" || email === null){
      this.dialogService.open( OkayMessageComponent, {},"Id Required!").subscribe(response=>{
      })
    }else{
      this.loginService.login(false,email,"", true,Constants.UserType.registered, (resp)=>{
        this.forgotPwdForm.reset();
        this.dialogService.open( OkayMessageComponent, {},resp.msgList[0]).subscribe(response=>{
        })
      })
    }
    
  }

  navigateToHome(){
    this.router.navigate(['']);
  }

}

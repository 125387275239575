import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginService } from '../../../services/login.service';
import { UserModel } from '../../../common/models/user-model';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { CartCountService } from '../../../services/cart-count.service';
import { CustomerSurveyService } from '../../../services/customerSurvey.service'
import { CustomerSurveyComponent } from '../../commonComponent/customer-survey/customer-survey.component'
import { AnswerModel } from '../../../common/models/answerModel'

@Component({
    selector: 'app-dashboard-options',
    templateUrl: './dashboard-options.component.html',
    styleUrls: ['./dashboard-options.component.scss'],
})

export class DashboardOptionComponent implements OnInit {
    userModel: UserModel
    userName: string
    dialog: LsMdDialog<DashboardOptionComponent>;
    formId: number

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private loginService: LoginService,
        public dialogService: LsDialogService<OkayMessageComponent>,
        private customerSurveyService: CustomerSurveyService,
        private cartCountService: CartCountService,
        public customerSurveyDialogService: LsDialogService<CustomerSurveyComponent>
    ) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('currentUser') !== null) {
                this.userModel = JSON.parse(localStorage.getItem('currentUser'));
                this.userName = this.userModel.userName;
            }
        }

    }

    onClick() {
        this.dialog.close(null);
    }

    // logOut() {
    //     this.loginService.logOut(resp => {
    //         this.dialog.close(null);
    //         if (resp != null && resp != undefined) {
    //             this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
    //             })
    //         }
    //     });
    // }


    logOutFunc() {
        var maintainLocalCart = false;
        this.loginService.logOut(maintainLocalCart, resp => {
            if (resp != null && !resp.error) {
                this.dialogService.open(OkayMessageComponent, {}, "You have successfully Logged-out!").subscribe(response => {
                    if (isPlatformBrowser(this.platformId)) {
                        window.location.href = "";
                    }
                })
            }
        });
    }

    logOut() {
        // this.openSurvey()
        this.logOutFunc();
    }

    openSurvey() {
        let data: Array<{ formId: number }> = []
        this.customerSurveyService.getSurveyForm(resp => {
            if (!resp.error) {
                if (resp.data != undefined && resp.data != null && resp.data.formId != undefined && resp.data.formId != null)
                    this.formId = resp.data.formId
                data.push({
                    formId: this.formId
                })
                this.customerSurveyDialogService.open(CustomerSurveyComponent, {}, data).subscribe((response) => {
                    if (response == 'yes') {
                        let answerModel = new AnswerModel()
                        answerModel.formId = this.formId
                        let isSurveyFormFilled = false
                        this.cartCountService.surveyFormFillObservable.subscribe((flag) => {
                            isSurveyFormFilled = flag
                        })
                        if (!isSurveyFormFilled) {
                            this.customerSurveyService.submitQuestionsForSurvey(answerModel, (resp) => {
                                if (!resp.error)
                                    this.logOutFunc()
                            })
                        } else {
                            this.logOutFunc()
                        }
                    }
                })
            } else {
                this.logOutFunc()
            }
        })
    }
}

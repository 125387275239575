import { Directive, HostListener, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[LsDragOver]'
})
export class LsDragOverDirective {

  @Output() dragOverEvent = new EventEmitter<any>();

  constructor() { }

  @HostListener("dragover", ['$event'])
  onDragOver(event) {
    this.dragOverEvent.emit(event);
    event.dataTransfer.dropEffect = "all";
  }

}

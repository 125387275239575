import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy, ApplicationRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Constants } from './common/constants/lsnetx.constants'
// import  * as google from  '@types/google.analytics'
import { isPlatformBrowser } from '@angular/common';
/**
 * Google Analytics
 */
declare var ga: Function;
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { LsDialogService } from './LSNG/components/ls-dialog/ls-dialog.service';
import { LoginRedirectService } from './services/login-redirect.service';
import { LoginComponent } from './published/components/login/login.component';
import { take, filter, tap, distinctUntilChanged } from 'rxjs/operators';
/**
 * BootStrap Comp.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * title 
   */
  title = 'app';
  /**
   * data of dialog 
   */
  data;//d
  /**
   *  value
   */
  value;
  /**
   * master
   */
  master: string
  /**
   * headerVisibility
   */
  headerVisibility: boolean = true
  /**
   * breadcrumbsVisibility
   */
  breadcrumbsVisibility: boolean = true
  /**
   * googleAnalyticsCode
   */
  googleAnalyticsCode = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO != undefined ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.googleAnalyticsCode : '';
  /**
   * List of DI
   * @param router  router DI
   * @param route  route DI
   * @param platformId  platformId DI
   * @param loginPopupService  loginPopupService DI
   * @param loginRedirectService  loginRedirectService DI
   * @param appRef  appRef DI
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private loginPopupService: LsDialogService<LoginComponent>,
    private loginRedirectService: LoginRedirectService,
    private appRef: ApplicationRef

  ) {
    if (isPlatformBrowser(this.platformId) && this.googleAnalyticsCode != '' && this.googleAnalyticsCode) {
      this.appendGaTrackingCode()
    }
    router.events.
      pipe(distinctUntilChanged((previous: any, current: any) => {
        if (current instanceof NavigationEnd) {
          return previous.url === current.url;
        }
        return true;
      }))
      .subscribe((x: any) => {
        // ga('create',this.googleAnalyticsCode,'auto');
        if (typeof ga === 'function') {
          ga('set', 'page', x.url);
          ga('send', 'pageview');
          // console.log("%%% google analytics page view event %%%");
        }
      })

    this.loginRedirectService.getLoinOpenObs().subscribe((obs) => {
      if (obs) {
        this.loginPopupService.open(LoginComponent, {}, null).subscribe(res => {
          console.log("received", res);
          if (res) {
            this.router.navigate([this.router.url])
          }
        })
      } else {

      }
    })

  }

  /**
   * append google analytics code 
   */
  appendGaTrackingCode() {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const script = document.createElement('script');
        script.setAttribute('defer', '');
        script.type = `text/javascript`
        script.text = `
        (function(i,s,o,g,r,a,m){ i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }, i[r].l = 1 * new Date(); a = s.createElement(o), m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m) })
        (window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');ga('create', '`+ this.googleAnalyticsCode + `' , 'auto');
        `;
        document.head.appendChild(script);
      } catch (ex) {
        console.log("Error appending google analytics")
        console.log(ex)
      }
    }
  }
  /**
   * LCH
   */
  ngOnInit() {

  }
  /**
   * LCH
   */
  ngOnDestroy() {

  }

}


// <!-- <script type='text/javascript'>
// (function (i, s, o, g, r, a, m) { i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }, i[r].l = 1 * new Date(); a = s.createElement(o), m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m) })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
// </script> -->
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';
import { AnswerModel } from '../common/models/answerModel'

/**
 * It submits and fetches the data of survey.
 * It checks the visibility of survey.
 * @class CustomerSurveyService
 */
@Injectable()
export class CustomerSurveyService {
  /**
   * DI
   * @param httpService DI 
   */
  constructor(
    public httpService: HttpService
  ) { }

  /**
   * It fetches the question for survey.
   * @param {number} formId - Id of the form
   * @param callback fn
   */
  getQuestionsForSurvey(formId: number, callback: (data) => void) {
    let url = Constants.BASE_URL + "/survey/getQuestions";
    let requestParams = new Map();
    requestParams.set('formId', formId)
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp.data);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It submits the answer for the survey questions.
   * @param {AnswerModel} answerModel 
   * @param callback fn
   */
  submitQuestionsForSurvey(answerModel: AnswerModel, callback: (data) => void) {
    let url = Constants.BASE_URL + '/survey/addSurvey'
    let requestParams = new Map();
    this.httpService.post(url, answerModel, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It checks the surveyForm visibility
   * @param callback fn
   */
  getSurveyForm(callback: (data) => void) {
    let url = Constants.BASE_URL + '/survey/getSurveyForm'
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';

/**
 * It fetches categories or products specific to category
 * @class CategoryService
 */
@Injectable()
export class CategoryService {
  /**
   * DI
   * @param httpService DI
   */
  constructor(public httpService: HttpService) { }

  /**
   * It fetches categories corresponding to the mode.
   * @param {number} mode - specify which category to fetch i.e. parent, child or all.
   * @param callback fn
   */
  getCategories(mode: number, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getCategories";
    let requestParams = new Map();
    // requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId + "");
    requestParams.set("mode", mode);
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches all the products corresponding to the categoryId. 
   * @param {string} categoryId - categoryId 
   * @param callback fn
   */
  getProductsOnCategoryId(categoryId: string, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getProductsOnCategoryId";
    let requestParams = new Map();
    // requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId + "");
    requestParams.set("categoryId", categoryId);
    this.httpService.get(url, requestParams, (resp) => {
      // console.log("resp",resp)
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

}

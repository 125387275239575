export class OrderProductDetailDto {
    storeId: number;
    orderDetailId: number;
    titleId: number;
    variantId: number;
    packageId: number;
    sku: string;
    productDisplayText: string;
    HSN: string;
    currencyId: number;
    MRP: number;
    productPrice: number;
    amount: number;
    shippingCharges: number;
    CODCharges: number;
    handlingCharges: number;
    numCopies: number;
    cancelRefundStatus: number;
    cancellationDate: Date;
    reasonForCancellation: string;
    orderStatus: number;
    SGST: number;
    IGST: number;
    CGST: number;
    isGift: number;
    giftWrapAmount;
    giftTo: string;
    giftFrom: string;
    giftMsg: string;
    offer: boolean;
    offerAmount: number;
    offerIds: Array<number>;
    returnGroupId: number;
}
import { Directive, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[LsDragLeave]'
})
export class LsDragLeaveDirective {

  @Output() dragLeaveEvent = new EventEmitter<any>();

  constructor() { }

  @HostListener("dragleave", ['$event'])
  onDragLeave( event ) {
    this.dragLeaveEvent.emit(event);
  }

}

import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Constants } from '../common/constants/lsnetx.constants';
import { CanDeactivate } from '@angular/router';
import { DetailsComponent } from '../published/components/details/details.component';


/**
 * set meta data for detail page.
 * set and update meta data.
 * @class SeoService
 */
@Injectable()
export class SeoService {
  /**
   * current Date 
   */
  currentDate: Date = new Date();
  /**
   * is firstTime 
   */
  firstTime: boolean = true;
  /**
   * DI
   * @param title Title
   * @param meta Meta
   */
  constructor(
    private title: Title,
    private meta: Meta
  ) { }


  /**
   * It is used to set meta data.
   * @param title - title 
   * @param keyword - keyword 
   * @param description  - description 
   */
  setMetaData(title, keyword, description) {
    this.title.setTitle(title);
    this.meta.updateTag({
      name: 'keyword', content: keyword
    })
    this.meta.updateTag({
      name: 'description', content: description
    })
    this.meta.updateTag(
      { name: 'google-site-verification', content: (Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.googleWebMasterCode != undefined) ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.googleWebMasterCode : '' }
    );
    this.meta.updateTag({ property: 'og:site_name', content: Constants.VENDOR_DETAILS.storeDomain });
    this.meta.updateTag({ name: 'twitter:site', content: '@' + Constants.VENDOR_DETAILS.storeDomain });
    /**
     * to be used when objective and values are confirmed. 
     * date - 11/09/2019
     * sonam
     */
    // this.meta.updateTag({ property: 'fb:admins', content: '100003077477008' });
    // this.meta.updateTag({ property: 'fb:page_id', content: '229742290423992' });
  }

  /**
   * It updates the meta tags.
   * @param title - title 
   * @param keyword - keyword 
   * @param description - description 
   */
  updateMetaTags(title, keyword, description) {
    this.title.setTitle(title);
    this.meta.updateTag({
      name: 'keyword', content: keyword
    })
    this.meta.updateTag({
      name: 'description', content: description
    })
  }

  /**
   * used to set meta data for detail page.
   * @param currencyType - currency type
   * @param image - image 
   * @param product - product
   * @param title - title
   * @param keyword - keyword
   * @param description - description
   */
  setMetaDataForDetailsPage(currencyType, image, product, title, keyword, description) {
    this.title.setTitle(title);
    this.meta.updateTag({
      name: 'keyword', content: keyword
    });
    this.meta.updateTag({
      name: 'description', content: description
    });
    this.meta.updateTag({ property: 'og:title', content: product.productName });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:url', content: Constants.VENDOR_DETAILS.storeDomain + '/' + Constants.VENDOR_DETAILS.warName + '/details/' + product.productURLText + '?t=' + product.titleId });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:type', content: 'og:product' });
    this.meta.updateTag({ name: 'product_price_amount', property: 'product:price:amount', content: product.salePrice });
    this.meta.updateTag({ name: 'product_price_currency', property: 'product:price:currency', content: currencyType });
    this.meta.updateTag({ name: 'twitter:card', content: 'product' });
    this.meta.updateTag({ name: 'twitter:title', content: product.productName });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:img:src', content: image });
    this.meta.updateTag({ name: 'twitter:data1', content: product.salePrice });
    this.meta.updateTag({ name: 'twitter:label1', content: 'PRICE' });
  }

  /**
   * used to re-initialize meta tags for pages, other than product-detail page.
   */
  initializeTagsAgain() {
    this.meta.updateTag({ property: 'og:title', content: "" });
    this.meta.updateTag({ property: 'og:description', content: "" });
    this.meta.updateTag({ property: 'og:url', content: "" });
    this.meta.updateTag({ property: 'og:image', content: "" });
    this.meta.updateTag({ property: 'og:type', content: "" });
    this.meta.updateTag({ name: 'product_price_amount', property: 'product:price:amount', content: "" });
    this.meta.updateTag({ name: 'product_price_currency', property: 'product:price:currency', content: "" });
    this.meta.updateTag({ name: 'twitter:card', content: "" });
    this.meta.updateTag({ name: 'twitter:title', content: "" });
    this.meta.updateTag({ name: 'twitter:description', content: "" });
    this.meta.updateTag({ name: 'twitter:img:src', content: "" });
    this.meta.updateTag({ name: 'twitter:data1', content: "" });
    this.meta.updateTag({ name: 'twitter:label1', content: "" });
  }

}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';
import { isPlatformBrowser } from '@angular/common';
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * It add and update address.
 * It fetches the state list and captcha code.
 * @class PaymentService 
 */
@Injectable()
export class PaymentService {

  /**
   * cartAmountObservable - cart amount observable. 
   */
  cartAmountObservable: Observable<number>;

  /**
   * cartAmountBehaviour - cart amount behaviour.
   */
  cartAmountBehaviour: BehaviorSubject<number>;

  /**
   * cartCODObservable - cart cod observable.  
   */
  cartCODObservable: Observable<number>;

  /**
   * cartCODBehaviour - cart cash on delivery behaviour subject.
   */
  cartCODBehaviour: BehaviorSubject<number>;
  /**
   *  enqCartIDObservable  Observable
   */
  enqCartIDObservable: Observable<number>;
  /**
   * BS of  enqCartIDObservable
   */
  enqCartIDBehaviour: BehaviorSubject<number>;

  /**
   * List of DI
   * @param httpService HttpService
   * @param platformId PLATFORM_ID
   */
  constructor(public httpService: HttpService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.cartAmountBehaviour = <BehaviorSubject<number>>new BehaviorSubject(0);
    this.cartAmountObservable = this.cartAmountBehaviour.asObservable();
    this.cartCODBehaviour = <BehaviorSubject<number>>new BehaviorSubject(0);
    this.cartCODObservable = this.cartCODBehaviour.asObservable();
    this.enqCartIDBehaviour = <BehaviorSubject<number>>new BehaviorSubject(0);
    this.enqCartIDObservable = this.enqCartIDBehaviour.asObservable();
  }

  /**
   * It fetches the state list.
   * @param callback fn
   */
  getStateList(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getStateList";
    let requestParams = new Map();
    // requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId)
    requestParams.set("countryId", 1)
    let body
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It add and update the address.
   * @param addressDto - It contains address information.
   * @param defaultFlag -It is used to set default address.
   * @param callback fn
   */
  addUpdateAddress(addressDto, defaultFlag: boolean, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/addUpdateAddress";
    let requestParams = new Map();
    requestParams.set("makeDefaultAddress", defaultFlag)
    requestParams.set("countryId", 1)
    let body = addressDto
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * get Captcha Image
   * @param callback It fetches the captcha code.
   */
  getCaptcha(callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/generateCaptcha";
    let requestParams = new Map();
    this.httpService.post(url, {}, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It is used to place order.
   * @param placeOrderDto - contains the order information.
   * @param callback fn
   */
  placeOrder(placeOrderDto, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/placeOrder";
    let requestParams = new Map();
    if (isPlatformBrowser(this.platformId)) {
      if (window.sessionStorage.getItem('currencyId') != null) {
        requestParams.set("currencyId", JSON.parse(window.sessionStorage.getItem('currencyId')))
      }
    }
    this.httpService.post(url, placeOrderDto, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * fetches list of all delivery slots present in the store.
   * @param callback fn
   */
  getAllDeliverySlots(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getAllDeliverySlots";
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  // validateCoupon(coupon:string,callback : (data)=>void){
  //   let url = Constants.BASE_URL + "/api/validateCoupon";    
  //   let requestParams = new Map();
  //   requestParams.set("couponCode",coupon)
  //   if(isPlatformBrowser(this.platformId)){
  //     if(window.sessionStorage.getItem('currencyId') != null){
  //       requestParams.set("currencyId",JSON.parse(window.sessionStorage.getItem('currencyId')))
  //     }
  //   }
  //   this.httpService.post(url,{}, requestParams,(resp)=>{
  //     callback(resp);
  //   }, (error)=>{
  //     console.log(error);
  //   })
  // }

}

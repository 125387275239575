import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as $ from 'jquery';

window["$"] = $;
window["jQuery"] = $;

if (environment.production) {
  enableProdMode();
}


//Done 4 disabling flickering(Refresh) Refresh 

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));
  document.addEventListener('DOMContentLoaded', () => {  
    platformBrowserDynamic().bootstrapModule(AppModule)    
    .catch(err =>{
      let msg="Unknown Error"
      if(err && err.msgList && err.msgList instanceof Array ){
          msg=err.msgList.join('/n');
      } else if(err && typeof err === 'string'){
        msg=err;
      }


      
      document.getElementsByTagName('body')[0].innerHTML= `
     
      <div style="
      height: 100%;
      display: flex;
      place-content: center;
      align-items: center;
      ">
      <h1 style="padding: 3em;font-size:4vw;
      text-align: center; color: #ff0000;"> 
      
     Sorry:  ${msg}
      
      </h1>
      </div>
      
      `;
      document.getElementsByTagName('body')[0].style.cssText= "height: 100% !important"

      
      console.log(err)
    });
  });
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagContainerComponent } from './tag-container/tag-container.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { LsDragModule } from '../../directives/ls-drag/ls-drag.module';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    LsDragModule
  ],
  declarations: [
    TagContainerComponent,
  ],
  exports: [TagContainerComponent]
})
export class LsTagContainerModule { }

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[lsSuggestionOptionHost]'
})
export class SuggestionOptionHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }


}

<mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
        <mat-tab *ngFor="let tab of mainTabList;let index=index" [disabled]="disableTab(index)">
                <ng-template mat-tab-label>
                        <div class="tab" (click)="onTabClick(tab)">
                                <span class="tabTitle">
                                        {{ tab?.titleText }}
                                </span>
                                <button mat-icon-button *ngIf="tab.closable" (click)="close(tab.index)">
                                        <i class="fa fa-remove"></i>
                                </button>
                        </div>
                </ng-template>
                <ng-container [ngTemplateOutlet]="tab.templateRef">
                </ng-container>
        </mat-tab>
</mat-tab-group>
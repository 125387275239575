import { Injectable } from '@angular/core';
import { CartModel } from '../common/models/cartModel';
import { Constants } from '../common/constants/lsnetx.constants';
import { CartProductModel } from '../common/models/cartProductModel';
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * It contains all the operation associated with the cart and wislist.
 * @class CartCountService
 */
@Injectable()
export class CartCountService {
  /**
   *  surveyFormFillObservable - It is a survey form obsevable to check whether form is filled or not.
   */
  surveyFormFillObservable: Observable<boolean>;

  /**
   *  surveyFormFillBehaviour - It is a survey form behaviour subject.
   */
  surveyFormFillBehaviour: BehaviorSubject<boolean>;

  /**
   *  wishlistCartBehaviour - It is a wishlist cart behaviour subject.
   */
  private wishlistCartBehaviour: BehaviorSubject<CartModel>;

  /**
   *  wishlistCart - It is a wishlist cart observable to keep track on wishlist updates.
   */
  wishlistCart: Observable<CartModel>;

  /**
   *  cartBehaviour - It is a cart behaviour subject.
   */
  private cartBehaviour: BehaviorSubject<CartModel>;

  /**
   *  cart - It is a cart observable to keep track on cart changes.
   */
  cart: Observable<CartModel>;

  /**
   * current enquiry-cart observable
   */
  updateCurrentEnqCartObservable: Observable<boolean>;

  /**
   * current enquiry-cart behaviour subject
   */
  updateCurrentEnqCartBehaviour: BehaviorSubject<boolean>;

  /**
   * wish-list cart state
   */
  private wishlistCartState: {
    wishlistCart: CartModel;
  }

  /**
   * cart-state
   */
  private cartState: {
    cart: CartModel;
  }

  /**
   * Initialize Service State 
   * cart State
   * Wish Cart state 
   */
  constructor() {
    this.wishlistCartState = { wishlistCart: undefined };
    this.wishlistCartBehaviour = <BehaviorSubject<CartModel>>new BehaviorSubject(new CartModel());
    this.wishlistCart = this.wishlistCartBehaviour.asObservable();
    this.cartState = { cart: undefined };
    this.cartBehaviour = <BehaviorSubject<CartModel>>new BehaviorSubject(new CartModel());
    this.cart = this.cartBehaviour.asObservable();
    this.surveyFormFillBehaviour = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.surveyFormFillObservable = this.surveyFormFillBehaviour.asObservable();
    this.updateCurrentEnqCartBehaviour = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.updateCurrentEnqCartObservable = this.updateCurrentEnqCartBehaviour.asObservable();
  }

  /**
   * It updates wishlist cart observable
   * @param {CartModel} cartModel - contains current cart information
   */
  updateWishlistCart(cartModel: CartModel) {
    this.wishlistCartState.wishlistCart = cartModel;
    this.wishlistCartBehaviour.next(Object.assign({}, this.wishlistCartState).wishlistCart);
  }

  /** 
  * It updates the cart observable
  *  @param {CartModel} cartModel - contains current cart information
  */
  updateCart(cartModel: CartModel) {
    this.cartState.cart = cartModel;
    this.cartBehaviour.next(Object.assign({}, this.cartState).cart);
  }

  /**
   * Updates current shopping-cart in local-storage as 'localCart'.
   * Prepare new local-cart after adding new-product to previous local-cart.
   * While adding new-product, check if already present in the local-cart or not.
   * If 'yes', then update num-Copies, else push it in cartProductModels list.
   * Now for saving new local-cart in local-storage, if store if multi-store, then fetch local-cart for current selected store and update i,
   * and save in local-storage, else save new local-cart as it is in local-storage.
   * @param cartProduct - product to be added in localCart.
   */
  updateLocalCart(cartProduct: CartProductModel) {
    let localCart = this.getLocalCartForStore();
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      localCart.cartProductModels.forEach(element => {
        if (element.titleId == cartProduct.titleId) {
          if (element.variantId && cartProduct.productModel.id) {
            if (element.variantId == cartProduct.productModel.id) {
              element.numCopies = cartProduct.numCopies;
              if (cartProduct.giftWrapping) {
                element.giftWrapping = cartProduct.giftWrapping;
                element.giftFrom = cartProduct.giftFrom;
                element.giftTo = cartProduct.giftTo;
                element.giftMsg = cartProduct.giftMsg;
              }
            }
          } else {
            element.numCopies = cartProduct.numCopies;
            if (cartProduct.giftWrapping) {
              element.giftWrapping = cartProduct.giftWrapping;
              element.giftFrom = cartProduct.giftFrom;
              element.giftTo = cartProduct.giftTo;
              element.giftMsg = cartProduct.giftMsg;
            }
          }
        }
      });
      /**
      * update updated local-cart
      */
      if (Constants.IS_MULTI_STORE && this.getSelectedStoreId()) {
        let localCartStoreIdx = this.getLocalCartForStoreIdx();
        let localCartForAllStore = this.getLocalCart();
        if ((localCartStoreIdx || localCartStoreIdx == 0) && localCartStoreIdx != -1 && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
          localCartForAllStore[localCartStoreIdx].cartProductModels = localCart.cartProductModels;
        }
        localStorage.setItem("localCart", JSON.stringify(localCartForAllStore));
      } else {
        localStorage.setItem("localCart", JSON.stringify(localCart));
      }
    }
  }

  /**
   * Helper method for removing product from cart.
   * Removes product from local-cart, and then again update local-cart in local-storage.
   * @param cartProduct : cartProductModel to be removed 
   */
  removeFromLocalCart(cartProduct: CartProductModel) {
    var localCart: CartModel;
    localCart = this.getLocalCartForStore();
    localCart.cartProductModels.forEach(element => {
      /**
       * first, check for titleId, if that product is present in cart or not,
       */
      if (element.titleId == cartProduct.titleId) {
        /**
         * if titleId matches and variantId id available for that product, then check for vaiantId.
         */
        if (element.variantId && cartProduct.productModel.id) {
          if (element.variantId == cartProduct.productModel.id) {
            localCart.cartProductModels.splice(localCart.cartProductModels.indexOf(element), 1);
          }
        } else {
          localCart.cartProductModels.splice(localCart.cartProductModels.indexOf(element), 1);
        }
      }
    });
    /**
     * update updated local-cart
     */
    if (Constants.IS_MULTI_STORE && this.getSelectedStoreId()) {
      let localCartStoreIdx = this.getLocalCartForStoreIdx();
      let localCartForAllStore = this.getLocalCart();
      if ((localCartStoreIdx || localCartStoreIdx == 0) && localCartStoreIdx != -1 && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
        localCartForAllStore[localCartStoreIdx].cartProductModels = localCart.cartProductModels;
      }
      localStorage.setItem("localCart", JSON.stringify(localCartForAllStore));
    } else {
      localStorage.setItem("localCart", JSON.stringify(localCart));
    }
  }

  /**
   * this method removes a product from local-wishlist-cart from local-storage.
   * @param cartProduct {CartProductModel} - product to be removed.
   */
  removeItemFromLocalWishlistCart(cartProduct: CartProductModel) {
    var localWishlistCart: CartModel = JSON.parse(localStorage.getItem('wishlist'));
    localWishlistCart.cartProductModels.forEach(element => {
      if (element.titleId == cartProduct.titleId) {
        if (element.variantId && cartProduct.productModel.id) {
          if (element.variantId == cartProduct.productModel.id) {
            localWishlistCart.cartProductModels.splice(localWishlistCart.cartProductModels.indexOf(element), 1);
          }
        } else {
          localWishlistCart.cartProductModels.splice(localWishlistCart.cartProductModels.indexOf(element), 1);
        }
      }
    });
    localStorage.setItem("wishlist", JSON.stringify(localWishlistCart));
  }

  /**
   * This method saves shopping-cart or wishlist-cart in local-storage.
   * First, prepare new CartModel after excluding products having offer 'true', then
   * If cart is of type 'wishlist-cart', 
   *    simply save prepared new CartModel as local-wishlist in local-storage.
   * Else if, cart is of type 'shopping-cart',
   *    then check if multi-store or otherwise,
   *      If 'multi-store', then check if local-cart is already present for the selected store,
   *          If 'yes', then update the store's local-cart with new local-cart
   *          Else, push new local-cart into local-storage.
   *      Else if, not multi-store, update new local-cart direclty in local-storage.
   * @param cart {CartModel} - shopping-cart or wishlist-cart to be saved in local-storage.
   * @param isWishlist {boolean} - 'true' is wishlist-cart else 'false'
   */
  setLocalCart(cart: CartModel, isWishlist: boolean) {
    /**
     * prepare new local cart
     */
    let newLocalCart: CartModel = new CartModel();
    if (cart && cart.cartProductModels && cart.cartProductModels.length > 0) {
      newLocalCart.cartProductModels = [];
      /**
       *  exclude products having offer
       */
      cart.cartProductModels.forEach((ele) => {
        if (!ele.offer) {
          let localCartEle: CartProductModel = new CartProductModel();
          if (ele.titleId)
            localCartEle.titleId = ele.titleId;
          if (ele.variantId)
            localCartEle.variantId = ele.variantId;
          if (ele.numCopies)
            localCartEle.numCopies = ele.numCopies;
          newLocalCart.cartProductModels.push(localCartEle);
        }
      });
    }
    if (!isWishlist) {
      if (Constants.IS_MULTI_STORE && this.getSelectedStoreId()) {
        newLocalCart.storeId = parseInt(this.getSelectedStoreId());
        let localCart = this.getLocalCart();
        if (localCart && localCart.length > 0) {
        } else {
          localCart = [];
        }
        let localCartForStoreIdx = this.getLocalCartForStoreIdx();
        if ((localCartForStoreIdx || localCartForStoreIdx == 0) && localCartForStoreIdx != -1) {
          localCart[localCartForStoreIdx] = newLocalCart;
        } else {
          localCart.push(newLocalCart);
        }
        /**
         *  If cart is of shopping-cart type, then update shopping-cart in local-storage,
         */
        localStorage.setItem('localCart', JSON.stringify(localCart));
      } else {
        localStorage.setItem('localCart', JSON.stringify(newLocalCart));
      }
    } else {
      /**
       * else if cart is of wishlist type, then update wishlist cart in local-storage,
       */
      localStorage.setItem('wishlist', JSON.stringify(newLocalCart));
    }
    let currCart = newLocalCart;
    return currCart;
  }

  /**
   * removes local-cart from local-storage.
   * In case of multi-store, removes local-cart for the current selected store.
   */
  removeLocalCart() {
    if (Constants.IS_MULTI_STORE && this.getSelectedStoreId()) {
      let storeCartIdx = this.getLocalCartForStoreIdx();
      if ((storeCartIdx || storeCartIdx == 0) && storeCartIdx != -1) {
        let localCartForAllStores = this.getLocalCart();
        localCartForAllStores.splice(storeCartIdx, 1);
        if (!(localCartForAllStores && localCartForAllStores.length > 0)) {
          localCartForAllStores = null;
        }
        localStorage.setItem('localCart', JSON.stringify(localCartForAllStores));
      }
    } else {
      localStorage.removeItem('localCart');
    }
  }

  /**
   * return local-cart for the current store.
   */
  getLocalCartForStore() {
    let localCart: CartModel;
    if (Constants.IS_MULTI_STORE && this.getSelectedStoreId()) {
      let storeCartIdx = this.getLocalCartForStoreIdx();
      if ((storeCartIdx || storeCartIdx == 0) && storeCartIdx != -1) {
        let cartForAllStores = this.getLocalCart();
        localCart = cartForAllStores[storeCartIdx];
      }
    } else {
      localCart = this.getLocalCart();
    }
    return localCart;
  }

  /**
   * In case of multi-store, this method returns
   * index of local-cart for current selected store from local-carts saved store-wise in local-storage.
   */
  getLocalCartForStoreIdx() {
    if (this.getLocalCart()) {
      let localCart = this.getLocalCart();
      if (localCart && localCart.length > 0) {
        return localCart.findIndex(ele => ele.storeId == this.getSelectedStoreId());
      }
    }
  }

  /**
   * returns local shopping-cart saved as 'localCart' from local-storage.
   */
  getLocalCart() {
    return JSON.parse(localStorage.getItem('localCart'));
  }

  /**
   * returns 'storeId' from local-storage.
   */
  getSelectedStoreId() {
    return JSON.parse(localStorage.getItem('storeId'));
  }

  /**
   * returns 'storeDomain' from local-storage
   */
  getSelectedStoreDomain() {
    return JSON.parse(localStorage.getItem('storeDomain'));
  }

}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Constants } from '../common/constants/lsnetx.constants';
import { LoaderService } from './loader.service';
import { UserService } from './user.service'
import { UserModel } from '../common/models/user-model'
import { CustomHttpClient } from './custom-http.service';
import { LoginRedirectService } from './login-redirect.service';
import { catchError } from 'rxjs/operators';

/**
 * This service acts as a layer for http-communication with BE. It contains all http methods; get, post, put etc.
 * These methods contains common operations which are to be performed for every http-request of the method-type.
 * These methods also handles loader-handling, token invalidate-handling, error-handling.
 */
@Injectable()
export class HttpService {

  /**
   * List of DI
   * @param platformId platformId DI 
   * @param http http DI 
   * @param loaderService loaderService DI 
   * @param userService userService DI 
   * @param loginRedirectService loginRedirectService DI 
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: CustomHttpClient,
    private loaderService: LoaderService,
    private userService: UserService,
    private loginRedirectService: LoginRedirectService
  ) { }

  /**
   * for every http get request, set siteId, storeId, storeType, companyId in request parameter.
   * @param url url
   * @param requestParams HttpParams
   * @param callback fn
   * @param error fn
   */
  public get(url: string, requestParams: Map<string, string>, callback: (data) => void, error?: (error) => void): Object {
    let params: HttpParams = new HttpParams()
    requestParams.forEach((value, key) => {
      params = params.set(key, value)
    });
    params = params.set('siteId', Constants.SITE_ID + "")
    params = params.set('storeId', Constants.STORE_ID + "")
    params = params.set('storeType', Constants.STORE_TYPE + "")
    if (Constants.VENDOR_DETAILS != undefined && Constants.VENDOR_DETAILS.companyId != undefined) {
      params = params.set("companyId", Constants.VENDOR_DETAILS.companyId + "");
    } else if (Constants.COMPANY_DETAILS != undefined && Constants.COMPANY_DETAILS.companyId != undefined) {
      params = params.set("companyId", Constants.COMPANY_DETAILS.companyId + "");
    }
    // no use
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('sessionId') != null) {
        params = params.set('sessionId', localStorage.getItem('sessionId'));
      }
    }
    this.showLoader();
    return this.http.get(url, { params: params }).pipe(
      catchError((err): any => {
        console.log("Unknown Error", err);

        if (err && err.status == 401) {
          this.navigateToUrlAfterLogin();
          this.tokenInvalidateHandler()
        }
        this.hideLoader();
        if (error instanceof Function) {
          error(err)
        }

      })).subscribe(data => {
        this.hideLoader();
        if (isPlatformBrowser(this.platformId)) {
          if (data != undefined && data['msgList'] != undefined && (data['msgList'][0] === Constants.INVALID_TOKEN)) {
            this.tokenInvalidateHandler()
          }
          if (data != undefined && data['token']) {
            localStorage.setItem('token', data['token']);
          }
        }
        callback(data);
      }, err => {
        if (err.status == 401) {
          this.navigateToUrlAfterLogin();
          this.tokenInvalidateHandler()
        }
        this.hideLoader();
        console.log(err);

      });
  }

  /**
   * for every http post request, set siteId, storeId, storeType, companyId in request parameter.
   * @param url endpoint
   * @param body body
   * @param requestParams HttpParams 
   * @param callback fn
   * @param error fn
   */
  public post(url: string, body: any, requestParams: Map<string, string>, callback: (data) => void, error?: (error) => void): Object {
    let params: HttpParams = new HttpParams()
    requestParams.forEach((value, key) => {
      params = params.set(key, value)
    });
    params = params.set('siteId', Constants.SITE_ID + "")
    params = params.set('storeId', Constants.STORE_ID + "")
    params = params.set('storeType', Constants.STORE_TYPE + "")
    if (Constants.VENDOR_DETAILS != undefined && Constants.VENDOR_DETAILS.companyId != undefined) {
      params = params.set("companyId", Constants.VENDOR_DETAILS.companyId + "");
    } else if (Constants.COMPANY_DETAILS != undefined && Constants.COMPANY_DETAILS.companyId != undefined) {
      params = params.set("companyId", Constants.COMPANY_DETAILS.companyId + "");
    }
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('sessionId') != null) {
        params = params.set('sessionId', localStorage.getItem('sessionId'));
      }
    }
    this.showLoader();
    return this.http.post(url, body, { params: params }).subscribe(data => {
      if (isPlatformBrowser(this.platformId)) {
        if (data != undefined && data['msgList'] != undefined && (data['msgList'][0]) === Constants.INVALID_TOKEN) {
          this.tokenInvalidateHandler()
        }
        this.hideLoader();
        if (data != undefined && data['token']) {
          localStorage.setItem('token', data['token']);
        }
      }
      callback(data);
    }, err => {
      if (err.status == 401) {
        this.tokenInvalidateHandler()
      }
      this.hideLoader();
      this.logError(url, err);
      if (error)
        error(err);
    })
  }

  /**
   *for every http put request, if token is received in output, it is saved in local-storage.
   * @param url api endpoint 
   * @param object body
   * @param callback fn 
   */
  public put(url: string, object: Object, callback: (data) => void): Object {
    this.showLoader();
    return this.http.put(url, object).subscribe(data => {
      this.hideLoader();
      if (isPlatformBrowser(this.platformId)) {
        if (data['token']) {
          localStorage.setItem('token', data['token']);
        }
      }
      callback(data);
    }, err => {
      this.hideLoader();
      console.log(err);
    });
  }


  /**
   * logs specific http errors.
   * @param url api endpoint 
   * @param err error 
   */
  logError(url, err) {
    console.log('POST failed for ' + url, err);
    let errObj: HttpErrorResponse = err;
    if (errObj.status == 400) {
      console.error('Malformed request.');
    } else if (errObj.status == 401) {
      console.error('Authentication problem.');
    }
  }

  /**
   * required Params 
   */
  requiredParams: Array<string> = [
    'siteId',
    'storeId'
  ];

  /**
   * updates loader-state to 'show' in loader-service
   */
  private showLoader() {
    this.loaderService.show();
  }

  /**
   * updates loader-state to 'hide' in loader-service
   */
  private hideLoader() {
    this.loaderService.hide();
  }

  /**
   * If token is to be found invalidate, then log-out user.
   * remove 'currentUser' from local-storage and
   * navigate to home-page.
   */
  tokenInvalidateHandler() {
    if (isPlatformBrowser(this.platformId) && localStorage) {
      if (localStorage.getItem("currentUser") != null) {
        localStorage.removeItem("currentUser")
        this.userService.update(new UserModel)
        localStorage.setItem("sessionTimedOut", "true")
        // window.location.href = ""
        this.http.router.navigate([''])
      }
    }
  }
  /**
   * navigate back after login 
   */
  navigateToUrlAfterLogin() {
    this.loginRedirectService.getLoinOpenObs().next(true);
    // this.http.router.navigate(['/login'], {queryParams: {'returnUrl': this.http.router.url }})
  }

  /**
   * fetches content from CDN
   * @param url {string} - CDN url
   * @param callback fn
   */
  getCDNContent(url, callback: (data) => void) {
    return this.http.get(url, { responseType: "text" }).subscribe((resp) => {
      callback(resp);
    }, err => {
      console.log("error fetching cdn url" + url, err);
    });
  }

}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from "../published/common-data/routes.array"

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
/**
 * Routing module 
 */
export class RoutingModule {

}
/**
 * routing Components 
 */
export const routingComponents = [];
import { CartProductModel } from './cartProductModel';

export class EnquiryModel {
    cartId: number;
    cartProductModels: Array<CartProductModel>;
    codCharges: number;
    couponCode: string;
    editable: false;
    enquiryAttachment: string;
    enquiryAttachmentUrl: string;
    enquiryDateStr: string;
    enquiryMessage: string;
    enquiryName: string;
    enquiryNo: string;
    enquiryTimeStr: string;
    fileContent;
    fromCart: boolean;
    gst: number;
    handlingCharges: number;
    offerAmount: number;
    offerIds: Array<number>;
    orderId: number;
    reponse: boolean;
    shippingCharges: number;
    statusStr: string;
    taxes: number;
    totalAmount: number;
    totalSavings: number;
}
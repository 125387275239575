import { Directive, Input, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: 'ls-tab'
})

export class LsTabDirective {
  @Input('titleText') titleText: string;
  @Input('closable') closable: boolean;
  @Input('disable') disable: boolean;
  @Input('visible') visible: boolean;

  @ContentChild('lsTabBody') lsTabBody: TemplateRef<any>;
  @Output('visibleChange') visibleChangeEvent = new EventEmitter<any>();

  constructor() { }

  ngOnChanges(changes: any){
    if(changes.visible){
      this.visibleChangeEvent.emit();
    }
  }

}

import { Injectable, Inject, PLATFORM_ID, forwardRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { Constants } from '../common/constants/lsnetx.constants';
import { UserModel } from '../common/models/user-model';
import { LoginRequestModel } from '../common/models/loginRequestModel'
import { FacebookLoginProvider, GoogleLoginProvider, AuthService } from 'angularx-social-login';
import { CartModel } from '../common/models/cartModel';

/**
 * It is used to login,logout and sign-up the user to the portal.
 * @class LoginService 
 */
@Injectable()
export class LoginService {
  /**
   * User Model
   */
  user: UserModel
  /**
   * loginRequestModel
   */
  loginRequestModel: LoginRequestModel = new LoginRequestModel()
  /**
   * List of DI
   * @param httpService  httpService
   * @param userStateService  userStateService
   * @param platformId  platformId
   * @param socialAuthService  socialAuthService
   */
  constructor(
    @Inject(forwardRef(() => HttpService)) private httpService: HttpService,
    private userStateService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private socialAuthService: AuthService,

  ) { }

  /**
   * It is used to login user to the system.
   * @param rememberMe - no use (as rememberMe functionality is handled at client-side)
   * @param email - email.
   * @param password  - password.
   * @param forgotPassword - 'true' when the user forgets the password else 'false'.
   * @param userType - type of user.
   * @param callback fn
   */
  login(rememberMe, email, password, forgotPassword, userType, callback: (data) => void) {
    let url = Constants.BASE_URL + "/login";
    let requestParams = new Map();
    requestParams.set("forgotPassword", forgotPassword);
    requestParams.set('rememberMe', rememberMe);
    let storeUserModel: UserModel = new UserModel();
    storeUserModel.email = email;
    storeUserModel.password = password;
    storeUserModel.userType = userType;
    if (isPlatformBrowser(this.platformId)) {
      let clientIp = window.location.origin;
      requestParams.set("clientIP", window.location.host);
    }
    this.httpService.post(url, storeUserModel, requestParams, (resp) => {
      if (resp.data != undefined && resp.token != "" && resp.data.data != undefined) {
        this.user = resp.data.data;
        this.userStateService.update(this.user);
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('currentUser', JSON.stringify(this.user));
          localStorage.setItem('token', resp.token);
          localStorage.setItem('sessionId', resp.data.addlData);
        }
      }
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It sign up the user to the portal.
   * @param storeUserModel - stores the user information.
   * @param subscribeNewsletter - It is used to subscribe the news letter.
   * @param mode Constants.LOGIN_MODE
   * @param callback fn
   */
  sign_up(storeUserModel: UserModel, subscribeNewsletter, mode, callback: (data) => void) {
    let url = Constants.BASE_URL + "/signUp";
    let requestParams = new Map();
    let params = new Map();
    if (mode === Constants.LOGIN_MODE.OAUTH && isPlatformBrowser(this.platformId)) {
      requestParams.set("clientIP", window.location.host);
    }
    requestParams.set("mode", mode);
    if (subscribeNewsletter != null || subscribeNewsletter != undefined) {
      requestParams.set("subscribeNewsletter", subscribeNewsletter)
    }
    if (storeUserModel && !storeUserModel.userType) {
      storeUserModel.userType = Constants.UserType.guest;
    }
    this.httpService.post(url, storeUserModel, requestParams, (resp) => {
      if (resp.data != undefined && resp.token != "" && resp.data.data) {
        if (resp.data != null && resp.data != undefined && resp.data.data) {
          this.user = resp.data.data;
          this.userStateService.update(this.user);
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('currentUser', JSON.stringify(this.user));
            localStorage.setItem('token', resp.token);
            localStorage.setItem('sessionId', resp.data.addlData);
          }
        } else {
        }
      }
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It logout the user from the portal.
   * @param {boolean} maintainLocalCart - local cart to be maintain or not. 
   * @param callback fn 
   */
  logOut(maintainLocalCart: boolean, callback: (data) => void) {
    let user: UserModel = new UserModel()
    let url = Constants.BASE_URL + "/signOut";
    let requestParams = new Map();
    let params = new Map();
    if (isPlatformBrowser(this.platformId)) {
      requestParams.set('token', localStorage.getItem('token'));
    }
    this.httpService.post(url, params, requestParams, (resp) => {
      callback(resp);
    })
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('currentUser');
      if (!maintainLocalCart) {
        localStorage.removeItem('localCart');
      }
      localStorage.removeItem('wishlist');
      localStorage.removeItem('token');
      localStorage.removeItem('sessionId');
      localStorage.removeItem('CART_APPND');
    }
    this.userStateService.update(user);
  }

  /**
   * It set's login request model.
   * @param {LoginRequestModel} loginRequestModel - login request model. 
   */
  setLoginRequestModel(loginRequestModel: LoginRequestModel) {
    this.loginRequestModel = loginRequestModel
  }

  /**
   * It fetches LoginRequestModel .
   */
  getLoginRequestModel(): LoginRequestModel {
    return this.loginRequestModel
  }

  /**
   * This method logs-in user using open-authentication.
   * AuthService from 'angularx-social-login', performs social-sign-in process after providing social-platformId, and returns userData (name, email).
   * Using this data, UserModel is prepared and sign-up is requested with login-mode of type OAuth.
   * After successful sign-in, fetch shopping-cart and wishlist-cart from response's otherInfo and return this data.
   * If cart data is not present, then fetch local shopping-cart from local-storage and return it.
   * Handle errors.
   * @param socialPlatform {string} - social-platform ('google'|'facebook')
   * @param errorLabel - element-reference where error-label is displayed
   */
  public socialSignIn(socialPlatform: string, errorLabel) {
    return new Promise((resolve, reject) => {
      let socialPlatformProvider;
      if (socialPlatform === "facebook") {
        socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      } else if (socialPlatform === "google") {
        socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      }

      this.socialAuthService.signIn(socialPlatformProvider).then(
        (userData) => {
          let resData = new Object();
          let user: UserModel = new UserModel();
          user.userName = userData.name;
          user.email = userData.email;
          user.userType = Constants.UserType.registered;
          resData['user'] = user;
          this.sign_up(user, null, Constants.LOGIN_MODE.OAUTH, resp => {
            if (!resp.error && resp.data) {
              if (resp.otherInfo && resp.otherInfo.cartWishlist && resp.otherInfo.cartWishlist.data && resp.otherInfo.cartWishlist.data.length > 0) {
                let data: Array<CartModel> = resp.otherInfo.cartWishlist.data;
                let wishlistCart: CartModel, userCart: CartModel;
                if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
                  userCart = data[0];
                } else if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
                  wishlistCart = data[0];
                }
                if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
                  userCart = data[1];
                } else if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
                  wishlistCart = data[1];
                }
                resData['wishlistCart'] = wishlistCart;
                resData['userCart'] = userCart;
                resData['resp'] = resp
                resolve(resData);
              } else {
                if (isPlatformBrowser(this.platformId)) {
                  let localCart = new CartModel();
                  if (localStorage.getItem('localCart')) {
                    localCart = JSON.parse(localStorage.getItem('localCart'));
                  }
                  if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
                    localCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
                    resData['localCart'] = localCart;
                    resolve(resData);
                  } else {
                    reject(resp)
                  }
                }
              }
            } else {
              errorLabel.className = "errorLabel show";
              errorLabel.innerHTML = resp.msgList[0];
            }
          })
        }
      ).catch(err => {
        errorLabel.className = "errorLabel show";
        errorLabel.innerHTML = "Social Provider not available" + err;
      })
    })
  }

}

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { LsDialogService } from '../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginComponent } from '../published/components/login/login.component';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from '../common/constants/lsnetx.constants';
/**
 * this class is used to redirect to login, after successfull login redirect back
 */
@Injectable({
  providedIn: 'root'
})
export class GetloginService {
  /**
   * List of DI
   * @param router router  
   * @param authService authService 
   * @param loginPopupService loginPopupService 
   * @param media media 
   */
  constructor(
    private router: Router,
    private authService: AuthService,
    private loginPopupService: LsDialogService<LoginComponent>,
    private media: MediaObserver,

  ) { }
  /**
   * this method authenticate user if  authenticated return true
   * 
   * if returns error if Constants.POPUP_ACTION set to true then open login in popup and after successful authentication redirect back
   * if not action popup redirecting from shopping cart to checkout return true 
   * @param redirectUrl url where to navigate after authentication
   * @param navigate navigate or not
   */
  loginAndBack(redirectUrl: string, navigate = true): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.authService.isAuthenticated(resp => {
        /**
         * If we want to restrict dashboard routes to guest user, then case would be
         * if(resp.error || resp.mode == Constants.UserType.guest)
         * else, following
         */
        if (resp.error) {
          if (Constants.POPUP_ACTION) {
            if (this.media.isActive('lt-sm')) {
              this.router.navigate(['/login'], { queryParams: { 'loginSucess': encodeURI(redirectUrl) } });
              observer.next(false);
              observer.complete();
            } else {
              this.loginPopupService.open(LoginComponent, {}, null).subscribe(res => {
                if (res) {
                  observer.next(true);
                  if (navigate)
                    this.router.navigate([redirectUrl])
                  observer.complete();
                } else {
                  observer.next(false);
                  observer.complete();
                }
              })
            }
          } else {
            console.log(redirectUrl, this.router.url);
            if ((redirectUrl && redirectUrl.includes('checkout') && this.router.url.includes('shopping-cart') && !Constants.POPUP_ACTION)) {
              observer.next(true);
              observer.complete();
            } else {
              this.router.navigate(['/login'], { queryParams: { 'loginSucess': encodeURI(redirectUrl) } });
            }
          }
        } else {
          observer.next(true);
          observer.complete();
        }
      })
    })
  }
}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { LsDialogService } from '../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginComponent } from '../published/components/login/login.component';
import { isPlatformBrowser } from '@angular/common';
import { Constants } from '../common/constants/lsnetx.constants';
import { Observable } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import { GetloginService } from './getlogin.service';
/**
 * Gurd for first login then authenticate the page 
 */
@Injectable()
export class LoginAuthService implements CanActivate {
  /**
   * List of DI 
   * @param platformId platform id DI
   * @param getLogIn GetloginService
   */
  constructor(
    // private router: Router,
    // private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
    // private loginPopupService: LsDialogService<LoginComponent>,
    // private media: MediaObserver,
    private getLogIn: GetloginService

  ) { }

  /**
   * If user is not logged-in , then returns false o/w returns true.
   * In case of unauthenticated user, removes'currentUser' from local-storage of browser and also navigates to home-page.
   * @see AuthService - returns if user is authenticated or not
   * @method canActivate method 
   * @returns true/false
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.getLogIn.loginAndBack(state.url);
  }

}

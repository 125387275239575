import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'queryParamExtract'
})
export class QueryParamExtractPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value && typeof value === 'string' && value.split('?').length>1 ){
    value= value.split('?')[1];
    return (/^[?#]/.test(value)? value.slice(1) : value).split('&').reduce((params, param)=>{
      let [key, val]= param.split('=');
      params[key]= val ? decodeURIComponent(val.replace(/\+/g, '')) : '';
      return params;
    }, {})
    }else{
      return value
    }
  }

}

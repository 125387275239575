import { ProductVariantDTO } from './productVariantDTO';
import { ProductPackagingDTO } from './productPackagingDTO';

export class ProductDTO {
    titleId: string;
    companyId: number;
    industryId: number;
    categoryId: string;
    brandId: number;
    brandName: string;
    brandUrl: string;
    productName: string;
    sku: string;
    defaultMRP: number;
    UOMType: number;
    UOMValue: number;
    desc: string;
    descPage: string;
    HSN: string;
    madeIn: number;
    images: Array<string>;
    tags: Array<string>;
    attachments: Array<string>;
    attributeValues: Map<String, Array<any>>;
    createDate: Date;
    releaseDate: Date;
    lastModifiedDate: Date;
    hasVariants: number;
    hasPackaging: number;
    onlineStorePresence: number;
    offlineStorePresence: number;
    visibility: number;
    productVariantDTOList: Array<ProductVariantDTO>;
    productPackagingDTOList: Array<ProductPackagingDTO>;
}
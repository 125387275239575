import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { Constants } from '../common/constants/lsnetx.constants';

/**
 * This class is used to restrict routes which are only accessible to registered user only when logged in i.e; Authenticated User.
 * Uses canActivate class of '@angular/router'.
 * @class AuthGuardService 
 */
@Injectable()
export class AuthGuardService implements CanActivate {
  /**
   * 
   * @param router router DI 
   * @param authService authService DI 
   * @param platformId platformId DI 
   */
  constructor(
    private router: Router,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,

  ) { }

  /**
   * If user is not logged-in , then returns false o/w returns true.
   * In case of unauthenticated user, removes'currentUser' from local-storage of browser and also navigates to home-page.
   * @see AuthService - returns if user is authenticated or not
   * @method canActivate guard
   * @returns true/false
   */
  canActivate(): boolean {
    this.authService.isAuthenticated(resp => {
      /**
       * If we want to restrict dashboard routes to guest user, then case would be
       * if(resp.error || resp.mode == Constants.UserType.guest)
       * else, following
       */
      if (resp.error) {
        if (isPlatformBrowser(this.platformId)) {
          this.router.navigate(['login']);
          localStorage.removeItem('currentUser');
        }
        return false;
      } else {
        return true;
      }
    })
    return true;
  }

}

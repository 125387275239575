import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DatePickerComponent } from './date-picker/date-picker.component';
import { NativeDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter, MatNativeDateModule } from '@angular/material/core'
import { MatDateFormats } from "@angular/material/core";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule, MatDatepickerIntl } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

const CUSTOM_FORMAT = {
  parse: {
    dateInput: {
      month: 'short',
      year: 'numeric',
      day: 'numeric'
    }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
}


const MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    monthYearLabel: {
      year: 'numeric',
      month: 'short'
    },
    dateA11yLabel: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    monthYearA11yLabel: {
      year: 'numeric',
      month: 'long'
    }
  }
}
export class CustomDateAdapter extends NativeDateAdapter {

  format(date: Date, displayFormat: Object): string {
    if (displayFormat == 'input') {
      let day = date.getDate()
      let locale = 'en-IN'
      let weekday = date.toLocaleString(locale, { weekday: 'short' })
      let month = date.toLocaleString(locale, { month: 'long' })
      let year = date.getFullYear()
      return weekday + ', ' + this._to2digit(day) + ' ' + month + ' ' + year
      //return this._to2digit(day) + '-' + this._to2digit(month) + '-' + year
    } else {
      return date.toDateString()
    }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2)
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MomentDateModule,
    MatMomentDateModule
  ],
  declarations: [DatePickerComponent],
  exports: [DatePickerComponent],

  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }
  ]
})
export class LsDatePickerModule {
  constructor() {
  }
}

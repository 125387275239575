import { Pipe, PipeTransform } from '@angular/core';

/**
 * pipe for fetching route after removing query-parameters from it.
 */
@Pipe({
  name: 'removeQueryParam'
})
export class RemoveQueryParamPipe implements PipeTransform {

  /**
   * returns url after removing query-parameters.
   * @param value - current route or url
   * @param args - none
   */
  transform(value: any, ...args: any[]): any {
    if(value && typeof value === 'string' && value.split('?').length>0 ){
      return value.split('?')[0];
    }
  }

}

<div fxLayout="column" class="dialogMainPanel" *ngIf="isImages">
  <div class="productImagePanel">
    <div fxLayoutAlign="center center" class="mediumImage">
      <img [src]="imageSrc">
    </div>
    <span class="fa fa-close" (click)="closeDialog()"></span>
  </div>
  <div fxLayout="row" class="imageThumbnailPanel">
    <div fxFlex="5" fxLayoutAlign="center center" class="leftArrowPnl">
      <span class="arrow fa fa-chevron-left" [ngClass]="{'opacity-none': imageThumbnailsList.length <= 3}" (click)="slideRight()"></span>
    </div>
    <div fxFlex="90" class="imgThumbnailContainer">
      <div class="thumbnailIterator" [style.left]="leftUpdatedValue + '%'">
        <div *ngFor="let thumbnail of imageThumbnailsList; let i=index" class="thumbnailDiv" (click)="updateImageSrc(i)">
          <img [src]="thumbnail" class="center-position">
        </div>
      </div>
    </div>
    <div fxFlex="5" fxLayoutAlign="center center" class="rightArrowPnl">
      <span class="arrow fa fa-chevron-right" [ngClass]="{'opacity-none': imageThumbnailsList.length <= 3}" (click)="slideLeft()"></span>
    </div>
  </div>
</div>
<div *ngIf="isSharingDialog" class="dialogMainPanel">
  <div fxLayout="row" fxLayoutAlign="center center" class="socialLinks">
    <a *ngIf="isFbHash" class="socialLink fb" href={{socialShareFbUrl}} target="_blank"></a>
    <a *ngIf="isGoogleHash" class="socialLink google" href={{socialShareGoogleUrl}} target="_blank"></a>
    <a *ngIf="isLinkedInHash" class="socialLink linkedIn" href={{socialShareLinkedInUrl}} target="_blank"></a>
    <a *ngIf="isTwitterHash" class="socialLink twitter" href={{socialShareTwitterUrl}} target="_blank"></a>
  </div>
</div>
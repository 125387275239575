<mat-form-field>
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let item of items" [removable]="true" (remove)="remove(item)">{{item}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <input matInput [matChipInputFor]="chipList" style="width : 500px !important;">
</mat-form-field>
<br>
<mat-form-field color="{{underlineColor}}">
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let item of items" [removable]="true" (remove)="remove(item)">{{item}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <input matInput placeholder="{{placeholder}}" [matChipInputFor]="chipList" [matAutocomplete]="auto" value="{{inputTextValue}}"
    [formControl]="autocompleteForm" (keyup)="reFetch($event)" [(ngModel)]="current">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChange($event)">
    <mat-option *ngFor="let data of optionList | async ;let indx = index;" [hidden]="!data.visible" [disabled]="data.disabled"
      [value]="data.value" style="height: auto !important; width : auto !important; overflow : visible !important;">
      <ng-template lsSuggestionOptionHost></ng-template>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
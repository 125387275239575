import { Output, Component, OnInit, Input, forwardRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

/**
 * This component is as a wrapper to text-field of material component
 */
@Component({
  selector: 'ls-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextFieldComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => TextFieldComponent),
    multi: true
  }]
})
export class TextFieldComponent implements OnInit, ControlValueAccessor, Validator {

  /**
   * @ignore
   */
  onChange = (_: any) => { };
  /**
   * @ignore
   */
  onTouched = (_: any) => { };
  /**
  * Label required for WCAG Implementation
  */
  @Input('label') label: string = "Label"


  /**
   * value of text-field
   */
  @Input('value') textFieldValue: string;
  /**
   * disable the text-field
   */
  @Input('disabled') disabled: boolean;
  /**
   * makes text-field required
   */
  @Input('required') required: boolean;
  /**
   * maxlength of field
   */
  @Input('maxlength') maxLength: number;
  /**
   * set content-type to field
   */
  @Input('type') contentType: string;
  /**
   * placeholder of text-field
   */
  @Input('placeholder') placeholder: string;
  /**
   * floating placeholder of text-field
   */
  @Input('float-placeholder') floatPlaceholder: string;
  /**
   * provide a clear-option in text-field which will clear the content present in the field
   */
  @Input('clear-option') clearOption: boolean;
  /**
   * class to be applied on the icon
   */
  @Input('icon-class') iconClass: string;
  /**
   * icon to placed at right side of the field
   */
  @Input('icon-right') iconRight: boolean;
  /**
   * icon to placed at left side of the field
   */
  @Input('icon-left') iconLeft: boolean;
  /**
   * prefix to be added to text present in field
   */
  @Input('prefix') prefix: string;
  /**
   * suffix to be added to text present in field
   */
  @Input('suffix') suffix: string;
  /**
   * hint label
   */
  @Input('hint-label') hintLabel: string;
  /**
   * underline color of text-field
   */
  @Input('underline-color') underlineColor: string;
  /**
   * @ignore
   */
  @Input('character-count') characterCount: boolean;
  /**
   * for input-type number, set minimum value
   */
  @Input('min') min: number;
  /**
   * for input-type number, set maximum value
   */
  @Input('max') max: number;
  /**
   * for input-type number, set increment to more than one
   */
  @Input('step') step: number;
  /**
   * pattern for validation in field
   */
  @Input('pattern') pattern: string;
  /**
   * @ignore
   */
  @Input('autofocus') autofocus: boolean;
  /**
   * set the focus order on tab
   */
  @Input('tabIndex') tabIndex: number = 0;
  /**
   * Event for keypress event in field
   */
  @Output('keypressText') keyPress = new EventEmitter<any>();
  /**
   * Event for keyup event in field 
   */
  @Output('keyupText') keyUp = new EventEmitter<any>();
  /**
   * Event for keydown event in field
   */
  @Output('keydownText') keyDown = new EventEmitter<any>();
  /**
   * Event whenever value present in field changes
   */
  @Output('changeText') change = new EventEmitter<any>();
  /**
   * Event when text-field is out of focus
   */
  @Output('blur') blur = new EventEmitter<void>();
  /**
   * Event when text-field is in focus
   */
  @Output('focusEvent') focusEvent = new EventEmitter<any>();
  /**
   * element-reference of input field
   */
  @ViewChild('textFieldRef', { static: true }) textFieldRef: ElementRef;
  /**
   * element-reference of material text-field
   */
  @ViewChild('field', { static: true }) field: ElementRef;
  /**
   * default value for autocomplete
   */
  @Input('autocomplete') autocomplete: string;
  /**
   * list of validators
   */
  validator: ValidatorFn;
  /**
   * Apperance
   */
  @Input() appearance: string = 'legacy'

  /**
   * @constructor
   */
  constructor() {
    this.validator = this.validationCheck();
  }

  /**
   * validates field
   * @param c 
   */
  validate(c: UntypedFormControl) {
    return this.validator(c);
  }

  /**
   * validation for min and max value
   */
  validationCheck(): ValidatorFn {
    return (c: UntypedFormControl) => {
      if (c.value != null) {
        if (this.min && c.value < this.min) {
          return {
            minError: {
              valid: false
            }
          }
        }
        if (this.max && c.value > this.max) {
          return {
            maxError: {
              valid: false
            }
          }
        }
        return null;
      } else {
        return {
          required: {
            valid: false
          }
        }
      }
    }
  }

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * clears out input-field
   */
  textClear(event) {
    this.textFieldValue = "";
    this.onChangeEvent();
  }

  /**
   * write field value
   * @param value 
   */
  writeValue(value: any) {
    if (value !== undefined)
      this.textFieldValue = value;
  }

  /**
* Set the disabled state of field
* @param isDisabled 
*/
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  /**
   * propagate changes
   */
  propagateChange = (_: any) => { };

  /**
   * propagate changes on touch
   */
  propagateTouch = (_: any) => { };

  /**
   * changes on register
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
    this.propagateTouch = fn;
  }

  /**
   * changes on touch
   * @param fn 
   */
  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  /**
   * 
   * @param event 
   */
  onFocusEvent(event) {
    this.propagateTouch(event.target.value);
  }

  /**
   * special key-codes
   */
  specialCodes = [8, 9, 37, 39]

  /**
   * heypress event
   * @param event 
   */
  onKeyPress(event: KeyboardEvent) {
    this.propagateChange(event.target['value']);
    this.keyPress.emit(event);
  }

  /**
   * keyup event
   * @param event 
   */
  onKeyUp(event) {
    this.propagateChange(event.target.value);
    this.keyUp.emit(event);
  }

  /**
   * Keydown event
   * @param event 
   */
  onKeyDown(event) {
    if (this.contentType && this.contentType.trim().toLowerCase() == 'numeric') {
      if (this.specialCodes.indexOf(event.keyCode) >= 0 || (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {

      }
      else {
        event.preventDefault();
        return;
      }
    }
    this.propagateChange(event.target.value);
    this.keyDown.emit(event);
  }

  /**
   * event on value change
   */
  onChangeEvent() {
    this.propagateChange(this.textFieldValue);
    this.change.emit(this.textFieldValue);
  }

  /**
   * emits event when input-field gets focused
   */
  focusEmitter($event) {
    this.focusEvent.emit($event);
    this.propagateTouch({});
  }

  /**
   * emits event when input-field gets blurred
   */
  onBlur() {
    this.blur.emit();
  }

}

import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';
import { ReviewModel } from '../common/models/reviewModel';
import { BehaviorSubject } from 'rxjs';

/**
 * It fetches and add reviews
 * @class GetReviewListService
 */
@Injectable()
export class GetReviewListService {
  /**
   * BS of Review List
   */
  public refreshReviewList = new BehaviorSubject<boolean>(false);
  /**
   * DI
   * @param httpService HttpService
   */
  constructor(public httpService: HttpService) { }

  /** 
   * It fetches the review list.  
   * @param titleId - titleId of the product .
   * @param pageNum - page number to fetch data.
   * @param numRecords -number of records to be fetch for each page.
   * @param callback fn
   */
  getReviewList(titleId, pageNum, numRecords, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getReviewList";
    let requestParams = new Map();
    requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId)
    requestParams.set("titleId", titleId)
    requestParams.set("pageNum", pageNum)
    requestParams.set("numRecords", numRecords)
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It adds the review of a product.
   * @param {ReviewModel} reviewModel - It contains information of reviews. 
   * @param callback fn
   */
  addReview(reviewModel: ReviewModel, callback: (data) => void) {
    let url = Constants.BASE_URL + "/addReview";
    let requestParams = new Map();
    this.httpService.post(url, reviewModel, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

}

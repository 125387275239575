import {ProductModel} from '../../common/models/productModel'

export class UserCartDetailDto{
    titleId:number;
    variantId:number;
    packageId:number;
    numCopies:number;
    giftWrapping:number;
    giftTo:string;
    giftFrom:string;
    giftMsg:string;
    updateDate:Date;
    visibility:number;
    // productModel:ProductModel
    // isFromCart: boolean;
    // couponAmount:number;
    // couponPercentage:number;
    offer: boolean;
}
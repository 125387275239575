import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../constants/lsnetx.constants';

/**
 * pipe for preparing naviagtion-url for category pages in case of 'multi-store' or otherwise.
 */
@Pipe({
  name: 'urlFormatter',
  pure: true
})
export class UrlFormatterPipe implements PipeTransform {

  /**
   * returns default-url or prepared category-page url.
   * @param value - category-url
   * @param args - url-formatter type
   */
  transform(value: any, args?: any): any {
    if (args == Constants.ULR_FORMATTER.COLLECTION) {
      return this.getCategoryUrl(value);
    } else {
      return this.getDefaultUrl();
    }
  }

  /**
   * If multi-store and store-domain name is present, then 
   *  add 'store/<store-domain>' as the prefix in the category-url.
   * else return default url.
   * @param catUrl - category-page url
   */
  getCategoryUrl(catUrl: string) {
    catUrl = catUrl ? catUrl : '';
    let url = '/collections' + '/' + catUrl;
    if (Constants.IS_MULTI_STORE && JSON.parse(this.getSelectedStoreDomain())) {
      let storeDomain = JSON.parse(this.getSelectedStoreDomain());
      let newUrl = '/store' + '/' + storeDomain + '/' + url;
      return newUrl;
    } else {
      return url;
    }
  }

  /**
   * returns default-url.
   */
  getDefaultUrl() {
    return '';
  }

  /**
   * returns store-domain name from local-storage.
   */
  getSelectedStoreDomain() {
    return localStorage.getItem('storeDomain');
  }

}

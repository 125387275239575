export class CategoryModel {
    categoryId: string;
    parentCategoryId: string;
    categoryName: string;
    categoryDisplayName: string;
    categoryDesc: string;
    categoryURL: string;
    imageName: string;
    buyEnquiryMode: number;
    footerLink: number;
    attachments: string;
    divisionId: number;
    categoryModels : Array<CategoryModel>;
}
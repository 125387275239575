import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LsTabGroupComponent } from './tab-group/tab-group.component';

import { MatTabsModule, MatTab } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { LsTabDirective } from './ls-tab.directive';
import { DynamicTabContainerDirective } from './dynamic-tab-container.directive';
import { LsTabBodyDirective } from './ls-tab-body.directive';

@NgModule({
    imports: [
        CommonModule,
        MatTabsModule,
        MatButtonModule
    ],
    exports: [
        LsTabGroupComponent, LsTabDirective
    ],
    declarations: [
        LsTabGroupComponent, LsTabDirective, DynamicTabContainerDirective, LsTabBodyDirective
    ]
})

export class LsTabPanelModule { 
}

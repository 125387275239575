import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LsDragDropDirective } from './ls-drag-drop/ls-drag-drop.directive';
import { LsDragEnterDirective } from './ls-drag-enter/ls-drag-enter.directive';
import { LsDragLeaveDirective } from './ls-drag-leave/ls-drag-leave.directive';
import { LsDragOverDirective } from './ls-drag-over/ls-drag-over.directive';
import { LsDragStartDirective } from './ls-drag-start/ls-drag-start.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LsDragDropDirective,
    LsDragEnterDirective,
    LsDragLeaveDirective,
    LsDragOverDirective,
    LsDragStartDirective
  ],
  exports: [
    LsDragDropDirective,
    LsDragEnterDirective,
    LsDragLeaveDirective,
    LsDragOverDirective,
    LsDragStartDirective
  ]
})
export class LsDragModule { }

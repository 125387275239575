import { Injectable } from '@angular/core';
import { UserModel } from '../common/models/user-model';
import { Observable, BehaviorSubject } from 'rxjs';
/**
 * carouselModel
 */
class carouselModel {
  /**
   * index 
   */
  index: number;
}
/**
 * It updates user and carousel.
 * @class UserService 
 */
@Injectable()
export class UserService {

  /**
 *  userStateModel : observable .
 */
  userStateModel: Observable<UserModel>;

  /**
 *  appBehaviour : behavior subject of type usermodel. 
 */
  private appBehaviour: BehaviorSubject<UserModel>;

  /**
 *  carouselBehaviour :behavior subject of type carouselmodel.
 */
  private carouselBehaviour: BehaviorSubject<carouselModel>

  /**
   *  carouselObservable : observable carousel. 
   */
  carouselObservable: Observable<carouselModel>
  /**
   * User State 
   */
  private userState: {
    userStateModel: UserModel
  };

  /**
   *  carouselIndex 
   */
  private carouselIndex: {
    carouselModel: carouselModel;
  }
  /**
   * initialize BS's State
   */
  constructor() {
    this.userState = { userStateModel: new UserModel() };
    this.carouselIndex = { carouselModel: new carouselModel() }
    this.appBehaviour = <BehaviorSubject<UserModel>>new BehaviorSubject(new UserModel());
    this.userStateModel = this.appBehaviour.asObservable();

    this.carouselBehaviour = <BehaviorSubject<carouselModel>>new BehaviorSubject(new carouselModel());
    this.carouselObservable = this.carouselBehaviour.asObservable();
  }

  /**
   * It updates user information.
   * @param userState - contains user information. 
   */
  update(userState: UserModel) {
    this.userState.userStateModel = userState;
    this.appBehaviour.next(Object.assign({}, this.userState).userStateModel);
  }

  /**
   * updates index of carousel 
   * @param index - index 
   */
  updateCarousel(index: number) {
    this.carouselIndex.carouselModel.index = index;
    this.carouselBehaviour.next(Object.assign({}, this.carouselIndex).carouselModel);
  }

}

import {
  Component, OnInit, Input, Output, AfterContentInit, EventEmitter, ContentChildren, QueryList,
  ComponentFactoryResolver, ViewChild, Type, ComponentRef, TemplateRef, OnChanges, AfterViewChecked,
} from '@angular/core';

import { MatTabChangeEvent, MatTab } from '@angular/material/tabs';
import { LsTabDirective } from '../ls-tab.directive';
import { DynamicTabContainerDirective } from '../dynamic-tab-container.directive';
/**
 * This component is a wrapper for material based stepper. Tabs organize content into separtate views where only one view can be visible. 
 */
@Component({
  selector: 'ls-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.css']
})
export class LsTabGroupComponent implements OnInit, AfterContentInit, OnChanges {
  /**
   * background color of the tab nav
   */
  @Input('backgroundColor') backgroundColor;
  /**
   * Whether the tab group should grow to the size of the active tab
   */
  @Input('dynamicHeight') dynamicHeight: boolean;
  /**
   * position of the tab header
   */
  @Input('headerPosition') headerPosition: string;
  /**
   * the index of the active tab
   */
  @Input('selectedIndex') selectedIndex: number | null;
  /**
   * disable a particular index
   */
  @Input("disableIndex") disableIndex: Array<number>;
/**
   * emit when active tab changes
   */
  @Output('activeTabChange') activeTabChange = new EventEmitter<LsTabChangeEvent>();
  /**
   * emit when selected tab index changes
   */
  @Output('selectedIndexChange') selectedIndexChange = new EventEmitter<any>();
  /**
   * emit event on tab close
   */
  @Output('closeTab') closeTab = new EventEmitter<Tab>();
  /**
   * emit event on tab focus
   */
  @Output('focusTabChange') focusTabChange = new EventEmitter<Tab>();
  /**
   * emit event on tab click
   */
  @Output('tabClick') tabClick = new EventEmitter<any>();
/**
   * tab container directive
   */
  @ViewChild(DynamicTabContainerDirective)
  /**
   * @ignore
   */
  dynamicTabContainerDirective: DynamicTabContainerDirective;
/**
   * @ignore
   */
  @ContentChildren(LsTabDirective)
  /**
   * list of tabs
   */
  tabList: QueryList<LsTabDirective>;
  /**
   * list of main tab 
   */
  mainTabList: Array<Tab>;
/**
   * disable tab for given index
   */
  disableTab(index) {
    if (this.disableIndex != undefined)
      return (this.disableIndex.indexOf(index) >= 0)
    return false;
  }
/**
   * The constructor
   */
  constructor(
    private resolver: ComponentFactoryResolver
  ) { }
/**
   * @ignore
   */
  ngOnInit() {
  }
/**
   * emit event on tab click
   */
  onTabClick(event) {
    this.tabClick.emit(event);
  }
/**
   * Analize changes on content initaialization
   */
  ngAfterContentInit() {
    this.populateList();
    this.tabList.forEach((tab) => {
      tab.visibleChangeEvent.subscribe(() => {
        this.populateList();
      });
    });
  }
/**
   * populate data list
   */
  populateList() {
    this.mainTabList = new Array<Tab>();
    let tmpList = this.tabList.toArray();
    let tab = null;
    let idx = 0;
    for (let idx = 0; idx < tmpList.length; ++idx) {
      tab = new Tab();
      tab.index = idx;
      tab.isContentDOM = true;
      tab.templateRef = tmpList[idx].lsTabBody;
      tab.titleText = tmpList[idx].titleText;
      tab.closable = tmpList[idx].closable;
      tab.disable = tmpList[idx].disable;
      if (tmpList[idx].visible != undefined && tmpList[idx].visible != null) {
        tab.visible = tmpList[idx].visible;
      }
      if (!this.disableTab(idx) && this.selectedIndex == undefined) {
        this.selectedIndex = idx;
      }
      if (tab.visible) {
        this.mainTabList.push(tab);
      }
    }
  }
/**
   * emit event on tab change
   */
  onTabChange(changedTab: MatTabChangeEvent): void {
    this.selectedIndex = changedTab.index;
    this.selectedIndexChange.emit(this.selectedIndex);
    let tabChangeEvent = new LsTabChangeEvent();
    tabChangeEvent.index = changedTab.index;
    tabChangeEvent.tab = this.mainTabList[tabChangeEvent.index];
    this.activeTabChange.emit(tabChangeEvent);
  }
/**
   * on tab close
   */
  close(index: number): void {
    let tabToBeClose = this.mainTabList[index];
    this.mainTabList.splice(index, 1);
    for (let idx = index; idx < this.mainTabList.length; ++idx) {
      this.mainTabList[idx].index--;
    }
    this.closeTab.emit(tabToBeClose);
  }
/**
   * on tab update
   */
  updateTab(index: number, property: { titleText?: string, disable?: boolean }) {
    if (index == undefined || index < 0 || index > this.mainTabList.length) return
    if (!property) return
    let temp = <Tab>Object.assign({}, this.mainTabList[index])
    Object.keys(property).forEach((key) => {
      temp[key] = property[key]
    })
    this.mainTabList.splice(index, 1, temp)
  }
/**
   * @ignore
   */
  ngOnChanges() {
  }

}
/**
   * Tab change event
   */
export class LsTabChangeEvent {
  index: number;
  tab: Tab;
}
/**
   * contain variables for a tab
   */
export class Tab {
  index: number;
  templateRef: TemplateRef<any>;
  isContentDOM: boolean = false;
  titleText: string;
  isActive: boolean = false;
  closable: boolean = false;
  disable: boolean = false;
  visible: boolean = true;
}

import { UserCartDetailDto } from './userCartDetailDto';
import { ProductModel } from './productModel';

export class CartProductModel extends UserCartDetailDto {
    isFromCart: boolean;
    productModel: ProductModel;
    offerAmount: number;
    offerIds: Array<number>;
    salePrice: number;
    amount: number;
    id: number;
}
import { UserCartDTO } from './userCartDto'
import { CartProductModel } from './cartProductModel';

export class CartModel extends UserCartDTO{
    couponCode: string;
    totalSavings: number;
    totalAmount: number;
    cartNum: number;
    taxes: number;
    shippingCharges: number;
    handlingCharges: number;
    couponId: number;
    couponAmount: number;
    codCharges: number;
    offerIds: Array<number>;
    gst: number;
    offerAmount: number;
    cartProductModels: Array<CartProductModel>;
    currencyId: number;
    userName: string;
    updateDateTime: string;
    totalGiftAmount:number;
}
import { Directive, HostBinding, HostListener } from '@angular/core';
/**
 * add class 'active' if isOpen true
 */
@Directive({
  selector: '[lsExpandMenu]'
})
export class ExpandMenuDirective {
  /**
   * init
   */
  constructor() {
  }
  /**
   * update class based on isOpen var
   */
  @HostBinding('class.active') isOpen = false;
  /**
   * on mouseenter set isOpen true, it will add 'active class '
   */
  @HostListener('mouseenter') toggleOpen() {
    this.isOpen = true;
  }
  /**
   * on mouseenter set isOpen false  it will remove 'active' class
   */
  @HostListener('mouseleave') toggleClose() {
    this.isOpen = false;
  }



}
import { Pipe, PipeTransform } from '@angular/core';

/**
 * pipe for fetching variant-name.
 */
@Pipe({
  name: 'setVariantName'
})
export class SetVariantNamePipe implements PipeTransform {

  /**
   * returns variant-name.
   * @param obj - map of variantId and variant display-name.
   * @param args - none 
   */
  transform(obj: any, ...args: any[]): any {
    if(obj)
    return obj[Object.keys(obj)[0]];
    else
    return obj;
  }

}

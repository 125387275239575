import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataFetchService } from './data-fetch.service';
import { SuggestionFieldComponent } from './suggestion-field/suggestion-field.component';
// import { MatAutocompleteModule,MatInputModule,MatOptionModule, MatChipsModule, MatFormFieldModule, MatIconModule } from '@angular/material';
import {ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SuggestionOptionHostDirective } from './suggestion-option-host.directive';
import { SuggestionComponent } from './suggestion/suggestion.component';
import { SuggestionTagDirective } from './suggestion-tag.directive';
import {  HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MultipleSuggestionComponent } from './multiple-suggestion/multiple-suggestion.component';
import { TestSuggestionComponent } from "./test-suggestion/test-suggestion.component";
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        CommonModule, MatAutocompleteModule, MatInputModule, MatOptionModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        FormsModule
    ],
    declarations: [SuggestionFieldComponent, SuggestionOptionHostDirective, SuggestionComponent, SuggestionTagDirective, MultipleSuggestionComponent, TestSuggestionComponent],
    providers: [DataFetchService],
    exports: [SuggestionFieldComponent, SuggestionComponent, SuggestionOptionHostDirective, MultipleSuggestionComponent]
})
export class LsSuggestionFieldModule { }

import { Pipe, PipeTransform, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

/**
 * Pipe tells whether user is logged-in in the system or not.
 */
@Pipe({
  name: 'isUserLogin',
  pure: false
})
export class IsUserLoginPipe implements PipeTransform {
  
  /**
   * @constructor
   * @param platformId 
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  /**
   * returns true or false when 'currentUser' is present in local-storage or not respectively.
   * @param value - none
   * @param args - none
   */
  transform(value: any, args?: any): any {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('currentUser') !== null
    }
    return false
  }

}

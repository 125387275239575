import { Component, OnInit, Input, EventEmitter, Output, forwardRef, OnChanges, NgModule, Inject, Renderer2, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, UntypedFormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
/**
 * The wrapper component for the material's combo component.
 */
@Component({
  selector: 'ls-combo',
  templateUrl: './combo.component.html',
  styleUrls: ['./combo.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LsComboComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LsComboComponent),
    multi: true
  }]
})
export class LsComboComponent implements OnInit, ControlValueAccessor, Validator, OnChanges {
  id:any
  /**
   * default value of the combo 
   */
  @Input('defaultOptions') optionlist;
  /**
   * field placeholder
   */
  @Input('placeholder') placeholder: String;
  /**
   * floating placeholder of text-field
   */
  @Input('float-placeholder') floatPlaceholder: string = 'never';
  /**
   * this will disable the field
   */
  @Input('disabled') disabled: boolean;
  /**
   * allow multiple options to select from the ddl
   */
  @Input('multiple') multiple: boolean = false;
  /**
   * combo options list
   */
  @Input('options') options: Array<any>;
  /**
   * makes the field required
   */
  @Input('required') required: boolean;
  /**
   * @ignore
   */
  @Input() hintText: string;
  /**
   * list of validators to be applied on combo
   */
  @Input('validators') validators: ValidatorFn[];
  /**
   * if its true then all the options will be selected
   */
  @Input('allSelected') allSelected: boolean;
  /**
   * all selected labels
   */
  @Input('allSelectedLabel') allSelectedLabel: String = 'All';
 
  /**
   * set the focus order on tab
   */
  @Input('tabIndex') tabIndex: number = 0;
  /**
   * It will emit an event if combo value changes
   */
  @Output() updatedList = new EventEmitter<any>();
  /**
   * emit an event on value change
   */
  @Output('change') HTMLUpdatedList = new EventEmitter<any>();
  /**
   * This files should be set to true to set a boder for option in a combo box
   */
  @Input('border_required')  border_required : boolean;
   /**
   * all selected labels
   */
  @Input('showValueAsLabel') showValueAsLabel: boolean = false;
  /**
   * To Allow searching in Combo box
   */
  @Input('allow_searching') allow_searching : boolean = false; 

  filterCtrl : UntypedFormControl = new UntypedFormControl();
  protected _onDestroy = new Subject<void>();
  filteredData = [];
  /**
   * On every change invoke the renderer method
   * @param value 
   */
  ngOnChanges(value) {
    let event = new CustomEvent('change', { bubbles: true })
    // this.renderer.invokeElementMethod(this.element.nativeElement, 'dispatchEvent', [event])
    this.element.nativeElement.dispatchEvent(event)
    if(this.allow_searching){
      this.filteredData = this.options;
    }
  }
  /**
   * @ignore
   */
  matcher = this;
  /**
   * mark field as touched on focus
   */
  focusHandler() {
    this.hasTouched = true;
  }
  /**
   * true if there is an error
   */
  hasError: boolean = false;
  /**
   * true if field is touched
   */
  hasTouched: boolean = false;
  /**
   * @ignore
   */
  as: string;
  /**
   * The constructor
   * @param renderer 
   * @param element 
   */
  constructor(private renderer: Renderer2, private element: ElementRef) { }
  /**
   * Check the validations applied on the field
   * @param c 
   */
  validate(c: AbstractControl) {
    this.hasError = false
    if (!this.validators)
      return null
    for (let i = 0; i < this.validators.length; i++) {
      let a = this.validators[i](c)
      this.hasTouched = c.touched
      if (a != null) {
        this.hasError = true
        return a;
      }
    }
    return null;
  }
  /**
   * Set the disabled state of field
   * @param isDisabled 
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
  /**
   * write the combo value
   * @param data 
   */
  writeValue(data: any) {
    if (!this.multiple && ((data == null) || (data == "")))
      this.optionlist = ""
    else if (data == null && this.multiple)
      this.optionlist = []
    else if (!this.multiple && data && typeof data == "string" && this.options && this.options.findIndex((value, index, obj): boolean => {
      return value.value == data;
    }) != -1) {
      this.optionlist = data;
    }
    else if (this.multiple && data && data instanceof Array && this.options && this.getValue(data)) {
      this.optionlist = data;
    }
  }
  /**
   * return the combo value
   * @param data 
   */
  getValue(data) {
    for (let i = 0; i < data.length; i++) {
      if (this.options.findIndex((value, index, obj) => { return value.value == data[i]; }) == -1)
        return false;
    }
    return true;
  }
  /**
   * @ignore
   */
  propagateChange: any = () => { };
  /**
   * @ignore
   */
  propagateTouch: any = () => { };
  /**
   * @ignore
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  /**
   * @ignore
   */
  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }
  /**
   * execute when combo value changes
   * @param lsitr 
   */
  onchange(lsitr: Array<string> | string) {    
    this.propagateChange(lsitr);
    this.propagateTouch()
    this.updatedList.emit(lsitr);
    let event = new CustomEvent('change', { bubbles: true });
    // this.renderer.invokeElementMethod(this.element.nativeElement, 'dispatchEvent', [event]);
    this.element.nativeElement.dispatchEvent(event)
  }
  /**
   * return default value
   */
  get value() {
    return this.optionlist;
  } 
  /**
   * set option values
   */
  set value(val) {
    this.optionlist = val;
    this.propagateChange(val);
    this.propagateTouch();
  }
  /**
   * @ignore
   */
  ngOnInit() {
    this.id="mat-hint"+ Math.floor(Math.random()*10000) 
    if (this.multiple == true) {
      var notString = true;
      if (this.optionlist instanceof Array) {
        this.optionlist.forEach(function (item) {
          if (typeof item !== 'string')
            notString = false;
        })
      }
      if (!notString) {
        this.optionlist = [];
      }
    }
    else {
      if (!((typeof this.optionlist) === 'string')) {
        this.optionlist = '';
      }
    }
       
    this.filterCtrl.valueChanges.pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      let search = this.filterCtrl.value;
      if(!search){
        this.filteredData = this.options;
        return
      }else{
        search = search.toLowerCase();
        this.filteredData = [];  
      }
      this.options.filter((data,idx) =>  {    
      if(search[0] == data.label[0].toLowerCase()){
        if(data.label.toLowerCase().indexOf(search) > -1 && data.label.toLowerCase().indexOf(search) == 0){
          this.filteredData.push(data);          
        }
      }
      }); 
    })
    
  }
  
  ngOnDestroy(){
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
import { NgModule } from '@angular/core';
import { FlexLayoutModule, BREAKPOINT } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';



/**
 * Breakpoints of media devices 
 */
const PRINT_BREAKPOINTS = [
    {
        alias: 'xxs',
        suffix: 'xxs',
        mediaQuery: "(min-width: 320px) and (max-width: 480px )",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'xxs',
        suffix: 'xxs',
        mediaQuery: "(min-width: 320px) and (max-width: 480px )",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'sm',
        suffix: 'sm',
        mediaQuery: "(min-width: 768px) and (max-width: 1024px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'md',
        suffix: 'md',
        mediaQuery: "(min-width: 1025px) and (max-width: 1280px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'lg',
        suffix: 'lg',
        mediaQuery: "(min-width: 1281px) and (max-width: 1500px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'xl',
        suffix: 'xl',
        mediaQuery: "(min-width: 1501px) and (max-width: 1600px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'xxl',
        suffix: 'xxl',
        mediaQuery: "(min-width: 1601px) and (max-width: 1920px )",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'xxl',
        suffix: 'xxl',
        mediaQuery: " (max-width: 1920px )",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'gt-xxs',
        suffix: 'gt-xxs',
        mediaQuery: "(min-width: 481px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'gt-xs',
        suffix: 'gt-xs',
        mediaQuery: "(min-width: 1025px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'gt-sm',
        suffix: 'gt-sm',
        mediaQuery: "(min-width: 768px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'gt-md',
        suffix: 'gt-md',
        mediaQuery: "(min-width: 1281px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'gt-lg',
        suffix: 'xxs',
        mediaQuery: "(min-width: 1501px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'gt-xl',
        suffix: 'gt-xxl',
        mediaQuery: "(min-width: 1601px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'gt-xxl',
        suffix: 'gt-xxl',
        mediaQuery: "(min-width: 1601px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'lt-xs',
        suffix: 'lt-xs',
        mediaQuery: " (max-width: 480px )",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'lt-sm',
        suffix: 'lt-sm',
        mediaQuery: "(max-width: 767px )",
        overlapping: false,
        // priority: 1001
    },

    {
        alias: 'lt-md',
        suffix: 'lt-md',
        mediaQuery: "(max-width: 1280px )",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'lt-lg',
        suffix: 'lt-lg',
        mediaQuery: "(max-width: 1280px)",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'lt-xl',
        suffix: 'xxs',
        mediaQuery: " (max-width: 1500px )",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'lt-xxl',
        suffix: 'lt-xxl',
        mediaQuery: " (max-width: 1600px )",
        overlapping: false,
        // priority: 1001
    },
    {
        alias: 'lt-xxxl',
        suffix: 'lt-xxl',
        mediaQuery: " (max-width: 1920px )",
        overlapping: false,
        // priority: 1001
    },

]
/**
 * Break point provides
 */
export const CustomBreakpointsProvider = {
    provide: BREAKPOINT,
    useValue: PRINT_BREAKPOINTS,
    multi: true
}


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule.withConfig({}, PRINT_BREAKPOINTS)],
    exports: [FlexLayoutModule],
    providers: [
        CustomBreakpointsProvider
    ]
})
/**
 * Custom BreakPoint Module 
 */
export class CustomBreakpointsModule {
}

import { Component, OnInit } from '@angular/core';
import { LsMdDialog } from '../../../../LSNG/components/ls-dialog/ls-dialog.service';

@Component({
  selector: 'app-product-img-dialog',
  templateUrl: './product-img-dialog.component.html',
  styleUrls: ['./product-img-dialog.component.scss']
})
export class ProductImgDialogComponent implements OnInit {

  dialog: LsMdDialog<ProductImgDialogComponent>
  dataList
  imageThumbnailsList
  imageSrc
  imageUrl: string
  imageList
  productName: string
  leftcheck: number = 0
  leftUpdatedValue: number = 0
  selectedImg: string
  isImages: boolean
  isSharingDialog: boolean
  socialShareFbUrl: string
  socialShareGoogleUrl: string
  socialShareLinkedInUrl: string
  socialShareTwitterUrl: string
  isFbHash: boolean
  isGoogleHash: boolean
  isLinkedInHash: boolean
  isTwitterHash: boolean
  isFirstImg:boolean = true
  
  
  constructor() { }

  ngOnInit() {
    this.isImages = this.dataList.isImages
    this.isSharingDialog = this.dataList.isSharingDialog
    if(this.dataList.isImages){
      this.imageUrl = this.dataList.imageUrl
      this.imageList = this.dataList.imagesList
      this.imageThumbnailsList=  this.dataList.imageThumbnailsList 
      this.productName = this.dataList.productName
      this.selectedImg = this.dataList.selectedImg
      this.updateImageSrc(0);
    }else if(this.dataList.isSharingDialog){
      this.isFbHash = this.dataList.isFb
      this.isGoogleHash = this.dataList.isGoogle
      this.isLinkedInHash = this.dataList.isLinkedIn
      this.isTwitterHash = this.dataList.istwitter
      this.socialShareFbUrl = this.dataList.fbUrl
      this.socialShareGoogleUrl = this.dataList.googleUrl
      this.socialShareLinkedInUrl = this.dataList.linkedInUrl
      this.socialShareTwitterUrl = this.dataList.twitterUrl

    }
  }

  closeDialog(){
    this.dialog.close(null)
  }

  slideLeft() {
    this.leftcheck = (this.imageThumbnailsList.length - 3) * -34.2;
    if (this.leftUpdatedValue > this.leftcheck)
      this.leftUpdatedValue = this.leftUpdatedValue - 34.2;
  }

  slideRight() {
    if (this.leftUpdatedValue < 0)
      this.leftUpdatedValue = this.leftUpdatedValue + 34.2;
  }

  updateImageSrc(thumbnailIndex) {
    this.imageSrc = ''
    if(this.selectedImg != undefined && this.isFirstImg){
      this.imageSrc = this.selectedImg
      this.isFirstImg = false      
    }
    else
     this.imageSrc =  this.imageUrl + '/l/' + this.imageList[thumbnailIndex];
  }

}

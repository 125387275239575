import { Output, Component, OnInit, Input, forwardRef, EventEmitter, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
/**
 * This component is a wrapper for the material based radio button.
 */
@Component({
    selector: 'ls-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RadioComponent),
        multi: true
    }]
})

export class RadioComponent implements OnInit, ControlValueAccessor {
    /**
     * on value change
     */
    onChange = (_: any) => { };
    /**
     * on field touch
     */
    onTouched = (_: any) => { };
    /**
     * radio value
     */
    @Input('value') radioValue: string;
    /**
     * disables the radio
     */
    @Input('disabled') disabled: boolean;
    /**
     * set required on radio
     */
    @Input('required') required: boolean;
    /**
     * list of radio options
     */
    @Input('radio-options') options: Array<any>;
    /**
     * event emitted on radio change
     */
    @Output('changeRadio') change = new EventEmitter<any>();
    /**
     * Whether to show the icon before or after text
     */
    @Input() showIconBeforeText: boolean = false;

    /**
     * @ignore
     */
    ngOnInit() {
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.radioValue && simpleChanges.radioValue.currentValue) {
            this.writeValue(simpleChanges.radioValue.currentValue)
        }
    }

    /**
     * Update the radio value
     * @param value 
     */
    writeValue(value: any) {
        if (value !== undefined)
            this.radioValue = value;
    }
    /**
     * @ignore
     */
    propagateChange = (_: any) => { };
    /**
     * register changes on field value change
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }
    /**
     * register changes on field touch
     */
    registerOnTouched() { }
    /**
     * emit event on value change
     */
    onChangeEvent(event) {
        this.propagateChange(event.value);
        this.change.emit(event);
    }

}
import { FilterModel } from './filterModel'

export class ProductFilterModel{
    categoryUrl : string;
    pageNum : number;
    numRecords : number;
    divisionId : number;
    filterModels : Array<FilterModel>;
    currencyId : number;
    keyword : string;
    isCategorySearch : boolean;
    sortBy : number;
    mode : number;
    fetchCategorySiblingsMap : boolean;
    fetchCategoryMeta : boolean;
    fetchProductReviews : boolean;
    brandUrl: string;
    productOnly: boolean;
}
import { Directive, ElementRef, HostListener, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[LsDragStart]'
})
export class LsDragStartDirective {

  @Output() dragStartEvent = new EventEmitter<any>();

  constructor( private eleRef : ElementRef) {
    eleRef.nativeElement.setAttribute("draggable","true");
  }

  @HostListener("dragstart",['$event']) 
  onDragStart( event ) {
    this.dragStartEvent.emit(event);
  }

}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SuggestionOption } from '../../../LSNG/components/ls-suggestion-field/suggestion-option';

@Component({
  selector: 'app-test-suggestion',
  template: `
  <div>
    <a class="hello" href="details/{{value}}">{{label}}
    </a>
    </div>

  `,
  styleUrls: ['./search.component.scss']
})
export class SuggestionOptionComponent implements OnInit, SuggestionOption {

  built  = false;
  label = "";
  value ="";
  json : any;

  constructor(private _cdr : ChangeDetectorRef) { }

  ngOnInit() {
  }

  buildComponent(json : any) : void {
    this.built = true;
    this.label = json.label;
    this.value = json.value;
    this._cdr.detectChanges();
  }



}

import { Injectable, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Constants } from '../common/constants/lsnetx.constants'
import { Observable } from 'rxjs';
import { timeout, tap } from 'rxjs/operators';

/**
 * This is http-interceptor which intercepts every http-request and peforms some action mentioned below.
 */
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  /**
   * DI
   * @param platformId  DI
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  /**
   * current token
   */
  private token: string = '';
  /**
   * http-request clone
   */
  private authReq;

  /**
   * for secure communication with BE server, BE sends us a token, which we have to send back for authorization.
   * For this, we save recieved token in local-storage.
   * This interceptor sets 'Authorization' header with 'token', if token is present. 
   * @param req -http-request
   * @param next - handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authReq = req.clone({
      headers: req.headers.set('Authorization', '')
    });

    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('token'))
        this.token = localStorage.getItem('token');
      this.authReq = req.clone({
        headers: req.headers.set('Authorization', this.token)
      });
    }
    return next.handle(this.authReq)
    // .pipe(timeout(Constants.TIME_OUT), tap(event => {
    // }, err => {
    //   console.log(err);
    // }));
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../constants/lsnetx.constants';

/**
 * pipe for fetching current selected store-name in case of 'Multi-Store'
 */
@Pipe({
  name: 'getStoreName',
  pure: true
})
export class GetStoreNamePipe implements PipeTransform {

  /**
   * returns storeName after filtering store using storeId in list of all stores.
   * @param value - selected StoreId
   * @param args - none
   */
  transform(value: any, args?: any): any {
    let storeName: string = '';
    if (Constants.STORES && Constants.STORES.length > 0) {
      let idx = Constants.STORES.findIndex(ele => ele.storeId == value);
      storeName = Constants.STORES[idx].storeDomain;
    } 
    return storeName;
  }

}

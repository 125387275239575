
export class FilterModel {
    filterModelList: Array<FilterModel>;
    filterId: number;
    industryId: number;
    categoryId: string;
    categoryUrl: string;
    filterName: string;
    filterType: number;
    filterTypeId: number;
    rangeFilter: number;
    visibility: number;
    filterValueId: number;
    filterValue: string;
    filterDisplayValue: string;
    seqNo: number;
    filterValueIds: string;
    colorCode: string;
    colorAvailable: boolean;
    checked:boolean;
    displayValue: string;
}
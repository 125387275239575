export class SelectItemModel{
    label :string;
    value = new Object();
    valueStr : string;
    valueType : string;

    public SelectItem(value :Object ,label:string){
        this.label = label;
        this.value = value;
    }
}
import { ProductDTO } from './productDTO';
import { PackagingQtyDTO } from './packagingQtyDTO';
import { OfferDTO } from './offerDto';

export class ProductModel extends ProductDTO {
    imageUrl: string;
    images: Array<string>;
    imageMap: Object;
    currentStockLevel: number;
    outOfStock: boolean;
    id: number;
    variantList: Array<ProductModel>;
    packageList: Array<ProductModel>;
    packagingQty: Array<PackagingQtyDTO>;
    productRatingAvg: number;
    addedToCart: boolean;
    addedToWishList: boolean;
    attributes: Object;
    variantAttributes: Object;
    variantAttrMap: Object;
    categoryMap: Object;
    offer: boolean;
    offerDetails: Array<OfferDTO>;
    productPrice: number;
    mrp: number;
    salePrice: number;
    gst: number;
    vat: number;
    cod: number;
    shippingCharges: number;
    handlingCharges: number;
    discount: number;
    discountPercent: number;    
    buyEnquiryMode: number;
    maxOrderQty: number;
    minOrderQty: number;
    shippingDays: number;
    displayDateFrom: Date;
    displayDateTo: Date;
    showSaving: number;
    hideOnOutOfStock: number;
    acceptOrderOnOutOfStock: number;
    youtubeEmbedLink: string;
    metaTagKeyword: string;
    metaTagTitle: string;
    metaTagDesc: string;
    productURLText: string;
    releaseDate: Date;
    lastModifiedDate: Date;
    titleId:string;
    qty: number;
    preOrder: boolean;
    numCopies: number;
}
import { ClassProvider, FactoryProvider, InjectionToken, PLATFORM_ID, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

/**
 * TOKEN of widow 
 */
export const WINDOW = new InjectionToken('WindowToken');
/**
 * class of window ref 
 */
export abstract class WindowRef {
  /**
   * get native window 
   */
  get nativeWindow(): Window | Object {
    throw new Error('Not implemented.');
  }
}
/**
 * Browser window ref 
 */
@Injectable()
export class BrowserWindowRef extends WindowRef {
  /**
   * init 
   */
  constructor() {
    super();
  }
  /**
   * native window 
   */
  get nativeWindow(): Window | Object {
    return window;
  }
}
/**
 * get      window ref 
 * @param browserWindowRef browser window
 * @param platformId PLATFORM_ID
 */
export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: Object): Window | Object {

  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }
  return new Object();
}
/**
 * ClassProvider of browserWindowProvider
 */
export const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef
};
/**
 * FactoryProvider of window 
 */
export const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID]
};

/**
 * WINDOW_PROVIDERS 
 */
export const WINDOW_PROVIDERS = [
  browserWindowProvider,
  windowProvider
];

import {
  Component,
  OnInit,
  ViewChildren,
  ViewChild,
  QueryList,
  ComponentFactoryResolver,
  Input,
  Output,
  EventEmitter,
  Type,
  AfterViewInit,
  ElementRef,
  Renderer2,
  OnChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';


import { SuggestionOption } from '../suggestion-option';
import { Suggestion } from '../suggestion';
import { SuggestionOptionHostDirective } from '../suggestion-option-host.directive';
import { SuggestionTagDirective } from '../suggestion-tag.directive';
import { DataFetchService } from '../data-fetch.service';
import { fromEvent } from 'rxjs';
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'ls-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: [
    './suggestion.component.css'
  ]
})
export class SuggestionComponent implements OnInit, AfterViewInit, OnChanges {

  prev
  keyCode
  timeDiff: number
  enter_key_event: number = 13

  constructor(
    private _dataFetchService: DataFetchService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private _eleRef: ElementRef,
    private renderer: Renderer2
  ) {
    let curr = 0
    this.timeDiff = 0
    renderer.listen('document', 'keypress', (event) => {
      this.prev = curr
      curr = new Date().getTime()
      this.timeDiff = curr - this.prev
      this.keyCode = event.keyCode
    })
  }

  ngOnChanges() {
    if (this.initialList && this.initialList.length) {
      this.textValue = this.initialList[0];
      this.onFetch([], this.initialList);
      this.currentOption = -1;
      this.prevOption = -1;
      setTimeout(() => {
        this.modalLeft = this._eleRef.nativeElement.offsetLeft;
        this.modalTop = this._eleRef.nativeElement.offsetTop + this._eleRef.nativeElement.offsetHeight;
        this.left = this.modalLeft + 'px';
        this.top = this.modalTop + 'px';
        this.openSuggestionModal = true;
      }, 500)
    }
    if (this.clearoptions) {
      this.textValue = '';
      this.dataList = ''
      this.initialList = [];
    }
    if (this.close) {
      this.closeSuggestionModal();
    }
  }

  ngOnInit() {
    if (this.initialAutoRequest) {
      // this.reFetch();
      if (this.requestType == 1) {
        this.reFetchGet();
      } else if (this.requestType == 2) {
        this.reFetchPost();
      }
    }
  }

  //local variables
  textValue: string;
  openSuggestionModal: boolean;
  _fetchurl: string;
  _response: any;
  _errorMsg: any;
  dataList: any;

  modalLeft: number;
  modalTop: number;
  left: string;
  top: string;

  tagList = [];

  specialKeyCodes = [38, 40, 13];
  currentOption: number = -1;
  prevOption: number = -1;

  //Inputs and Outputs
  @Input('placeholder') placeholder;
  @Input('base-url') baseurl;
  @Input('searchon-param-name') searchOnName;
  @Input('searchon-param-value') searchOnValue;
  @Input('current-param-name') currentName;
  @Input('container') container: Type<any>;
  @Input('tag') tagContainer: Type<any>;
  @Input('tags') tags: boolean;
  @Input('max-list-height') maxHeight: string;
  @Input('key-speed-handling') keySpeedHandling: boolean;
  /**
   * maxlength of field
   */
  @Input('maxlength') maxLength: number;
  /**
   * whether initial request is required on component loading or not
   */
  @Input('inital-auto-request') initialAutoRequest: boolean;
  /**
   * 1 - get
   * 2 - post
   */
  @Input('request-type') requestType: number = 1;

  @Output('optionSelected') optionSelected = new EventEmitter<any>();
  @Output('tagsSelected') tagsSelected = new EventEmitter<any>();
  @Output('enterKeyPressed') enterKeyPressed = new EventEmitter<any>();
  @Output('blur') blurEvent = new EventEmitter<any>()

  //children
  @ViewChildren(SuggestionOptionHostDirective) suggestionOptions: QueryList<SuggestionOptionHostDirective>;
  @ViewChildren(SuggestionTagDirective) tagHosts: QueryList<SuggestionTagDirective>;
  @ViewChildren('textfield') textField;
  @ViewChildren('option') optionlist;
  @ViewChild('modal') modal;

  /**
   * Boolean whether to show Tags or not
   */
  @Input() showTags: boolean = true;
  /**
   * Boolean whether to show Tags or not
   */
  @Input() showCancel: boolean = true;
  /**
   * To append keyword in the API only
   */
  @Input() sendKeywordinAPI: boolean = true;
  /**
   * Initail list to be shwown
   */
  @Input() initialList: Array<string> = [];
  /**
   * To send the output event in the format desired by OPAC team
   */
  @Input() forOpac: boolean = false;
  /**
   * Input property passed to clear the options in the suggestion field
   */
  @Input() clearoptions: boolean = false;
  prevTextValue: string = '';
  /**
   * Whether to show Ellipsis property
   */
  @Input() showEllipsis: boolean = false;
  /**
   * Whether to show Title property
   */
  @Input() showTitle: boolean = false;
  /**
 * Input propert to manually close the Overlay
 */
  @Input('close') close: boolean = false;
  public openSuggestions(event, ref: ElementRef): void {
    if (this.specialKeyCodes.indexOf(event.keyCode) >= 0) {
      return;
    }
    this.currentOption = -1;
    this.prevOption = -1;
    this.modalLeft = this._eleRef.nativeElement.offsetLeft;
    this.modalTop = this._eleRef.nativeElement.offsetTop + this._eleRef.nativeElement.offsetHeight;
    this.left = this.modalLeft + 'px';
    this.top = this.modalTop + 'px';
    //for stopping repeating requests
    if (this.textValue !== undefined && this.textValue.trim().length > 0 && event.keyCode != 32) {
      if (event.keyCode == 8) {
        if (this.prevTextValue.trim() === this.textValue.trim()) {
        } else {
          this.openSuggestionModal = true;
          // this.reFetch();
          if (this.requestType == 1) {
            this.reFetchGet();
          } else if (this.requestType == 2) {
            this.reFetchPost();
          }
          if (this.keySpeedHandling) {
            if (this.timeDiff > 25) {
              this.openSuggestionModal = true;
            } else {
              this.openSuggestionModal = false;
            }
          }
        }
      } else {
        this.openSuggestionModal = true;
        // this.reFetch();
        if (this.requestType == 1) {
          this.reFetchGet();
        } else if (this.requestType == 2) {
          this.reFetchPost();
        }
        if (this.keySpeedHandling) {
          if (this.timeDiff > 25) {
            this.openSuggestionModal = true;
          } else {
            this.openSuggestionModal = false;
          }
        }
      }
    } else {
      this.openSuggestionModal = false;
    }
  }

  onFetch(data, initialList?: Array<string>): void {
    this.dataList = [];
    if (data && data.data && data.data.length) {
      data.data.forEach(element => {
        this.dataList.push({ label: element, value: element });
      })
    }
    if (initialList && initialList.length) {
      initialList.forEach(element => {
        this.dataList.push({ label: element, value: element });
      })
    }
  }

  initComponents() {
    if (this.suggestionOptions === undefined || this.suggestionOptions.length <= 0)
      return;
    let suggCompList = this.suggestionOptions.toArray();
    for (let i = 0; i < this.dataList.length; i++) {
      if (suggCompList[i] === undefined)
        continue;
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.container);
      let viewContainerRef = suggCompList[i].viewContainerRef;
      viewContainerRef.clear();
      let componentRef = viewContainerRef.createComponent(factory);
      (<SuggestionOption>componentRef.instance).buildComponent(this.dataList[i]);

    }
  }

  ngAfterViewInit() {

    fromEvent(this.textField.first.nativeElement, 'keyup').pipe(
      filter((el: any) => !(this.specialKeyCodes.indexOf(el.keyCode) >= 0)),
      map((el: any) => el.target.value.trim()),
      debounceTime(200),
      // distinctUntilChanged()
    ).subscribe(ele => {
      this.openSuggestions(ele, null)

    })
    this.suggestionOptions.changes.subscribe(() => {
      this.initComponents();
    });
    this.tagHosts.changes.subscribe(() => {
      this.addTags();
    });
  }

  removeWhiteSpaces(str: string): string {
    return str.trim();
  }

  reFetchPost() {
    let body
    this._fetchurl = this.baseurl + '?' + this.searchOnName + '=' + this.searchOnValue;
    let textValue = this.removeWhiteSpaces(this.textValue);
    body = {
      label: textValue
    }
    this._dataFetchService.postJSONResponse(this._fetchurl, body).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.onFetch(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);
  }

  reFetchGet() {
    if (this.textValue !== undefined) {
      this._fetchurl = this.baseurl + '?' + this.searchOnName + '=' + this.searchOnValue + '&' + this.currentName + '=' + this.textValue;
    }
    else
      this._fetchurl = this.baseurl + '?' + this.searchOnName + '=' + this.searchOnValue + '&' + this.currentName + '=';
    if (this.sendKeywordinAPI) {
      this._fetchurl = `${this.baseurl}${this.textValue}`
    }
    this._dataFetchService.getJSONResponse(this._fetchurl).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.onFetch(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);
  }

  closeSuggestionModal(): void {
    this.openSuggestionModal = false;
    this.currentOption = -1;
    this.prevOption = -1;
    if (this.forOpac)
      this.optionSelected.emit({ value: this.textValue, label: this.textValue });
  }

  selectOption(option): void {
    this.currentOption = -1;
    this.prevOption = -1;
    this.openSuggestionModal = false;
    this.textValue = option.value;
    if (this.tags) {
      this.tagList.push(option);
      this.tagsSelected.emit(this.tagList);
      this.textValue = '';
    }
    this.optionSelected.emit(option);
    this.dataList = [];
  }

  addTags(): void {
    if (this.tagHosts === undefined || this.tagHosts.length <= 0)
      return;
    let tagHostArray = this.tagHosts.toArray();
    for (let i = 0; i < this.tagList.length; i++) {
      if (tagHostArray[i] === undefined)
        continue;
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.tagContainer);
      let viewContainerRef = tagHostArray[i].viewContainerRef;
      viewContainerRef.clear();
      let componentRef = viewContainerRef.createComponent(factory);
      (<SuggestionOption>componentRef.instance).buildComponent(this.tagList[i]);

    }
  }

  closeTag(tag): void {
    for (let i = 0; i < this.tagList.length; i++) {
      if (this.tagList[i].value === tag.value) {
        this.tagList.splice(i, 1);
        this.tagsSelected.emit(this.tagList);
        break;
      }
    }
  }

  clearTags(): void {
    this.tagList = [];
    this.tagsSelected.emit(this.tagList);
    this.textValue = '';
  }

  getPlaceholder(): string {
    if (this.tagList.length > 0) {
      return '';
    }
    else {
      return this.placeholder
    }
  }

  focusTextField(): void {
    this.textField.first.nativeElement.focus();
  }

  handleKeys(event): void {
    this.prevTextValue = event.target.value;
    if (this.specialKeyCodes.indexOf(event.keyCode) < 0) {
      return;
    }
    //handling up and down
    if ((event.keyCode == 40 || event.keyCode == 38) && !this.openSuggestionModal) {
      this.openSuggestionModal = true;
      this.modalLeft = this._eleRef.nativeElement.offsetLeft;
      this.modalTop = this._eleRef.nativeElement.offsetTop + this._eleRef.nativeElement.offsetHeight;
      this.left = this.modalLeft + 'px';
      this.top = this.modalTop + 'px';
    } else if (event.keyCode == 40 || event.keyCode == 38) {
      let optionArray = this.optionlist.toArray();
      this.prevOption = this.currentOption;
      if (event.keyCode == 40)
        this.currentOption++;
      else if (event.keyCode == 38)
        this.currentOption = (this.currentOption - 1 + optionArray.length) % optionArray.length;
      //removing focusedOption class from each element 
      optionArray.forEach(element => {
        if (element && element.nativeElement && element.nativeElement.classList && element.nativeElement.classList.contains("focusedOption")) {
          element.nativeElement.classList.remove("focusedOption")
        }
      })
      optionArray[this.currentOption % optionArray.length].nativeElement.classList.add("focusedOption");
      let modal = this.modal.nativeElement;
      let current = optionArray[this.currentOption % optionArray.length].nativeElement;
      let maxHeight = 0;
      if (modal.style.maxHeight != undefined && modal.style.maxHeight != '')
        maxHeight = parseInt(modal.style.maxHeight.substr(0, modal.style.maxHeight.length - 2));
      let viewHeight = modal.scrollTop + maxHeight;
      if (current.offsetTop + current.offsetHeight > viewHeight || current.offsetTop < modal.scrollTop) {
        modal.scrollTop = current.offsetTop;
      }
    }

    //handling backspace
    if (event.keyCode == 8 && this.textValue.trim().length <= 0 && this.tagList.length > 0) {
      this.tagList.pop();
      this.tagsSelected.emit(this.tagList);
    }

    //handling enter
    if (event.keyCode == 13) {
      this.enterKeyPressed.emit();
      if (this.currentOption == -1) {
        this.selectOption({
          label: this.textValue,
          value: this.textValue,
          special: true,
          data: this.dataList
        })
      }
      let optionArray = this.optionlist.toArray();
      if (this.currentOption >= 0 && this.currentOption < optionArray.length) {
        optionArray[this.currentOption].nativeElement.click();
      }
    }
  }

}

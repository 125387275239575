import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';
/**
 * This component is a wrapper for the material based Tag/Chip. It displays a list of values as individual, keyboard accessible chips
 */
@Component({
  selector: 'ls-tag-container',
  templateUrl: './tag-container.component.html',
  styleUrls: ['./tag-container.component.css']
})
export class TagContainerComponent implements OnInit {
  /**
   * list of tags in a tag container
   */
  @Input('tags') items: Array<any>;
  /**
   * emit event on tag change
   */
  @Output('tagsChange') itemsChange = new EventEmitter<Array<any>>();
  /**
   * emit event when a tag is dropped at a dropable zone
   */
  @Output('tag-dropped') tagDropped = new EventEmitter<any>();
  /**
   * emit event on tag remove
   */
  @Output('tag-removed') removedTag = new EventEmitter<any>()
  /**
   * handle the time out
   */
  private timeoutHandler: any;
  /**
   * varible used for moving a chip
   */
  private movingChip: any;
  /**
   * @ignore
   */
  isString: boolean;
  /**
   * return list of tags
   */
  @ViewChildren('chip') chips: QueryList<any>;
  /**
   * The constructor
   */
  constructor() { }
  /**
   * @ignore
   */
  ngOnInit() {
  }
  /**
   * Trigger on any change
   */
  ngOnChanges() {
    if (this.items && this.items.length > 0) {
      if (typeof this.items[0] != 'string') {
        this.isString = false;
      } else if (typeof this.items[0] == 'string') {
        this.isString = true;
      }
    }
  }
  /**
   * remove the tag
   */
  remove(item): void {
    let index = this.items.indexOf(item);
    if (index >= 0) {
      let temp = this.items.splice(index, 1);
      this.removedTag.emit(temp[0])
      this.itemsChange.emit(this.items);
    }
  }
  /**
   * trigger when a tag is dropped a drop zone
   */
  itemDropped(event: DragEvent): void {
    if (this.items.indexOf(this.movingChip) == -1) {
      let tagData = {};
      let data = event.dataTransfer.getData("tag");
      if (data != undefined && data != null) {
        tagData["data"] = data;
        if (this.items.indexOf(data) < 0) {
          tagData["alreadyPresent"] = false;
          tagData["index"] = this.items.length;
          this.items.push(data);
          this.itemsChange.emit(this.items);
        } else {
          tagData["alreadyPresent"] = true;
        }
      }
      this.tagDropped.emit(tagData);
    }
  }
  /**
   * drag mouse over
   */
  dragOver(event: Event): void {
    event.preventDefault();
  }
  /**
   * assign a tag to the moving chip when drag start
   */
  dragStart(item): void {
    this.movingChip = item;
  }
  /**
   * trigger when chip enter to the drop zone
   */
  chipDragEnter(item): void {
    if (this.items.indexOf(this.movingChip) > -1) {
      let index = this.items.indexOf(item);
      let element: ElementRef = this.chips.toArray()[index];
      let nativeElement = element.nativeElement;
      nativeElement.style.marginLeft = '20px';
    }

  }
  /**
   * trigger when chip drag end
   */
  chipDragLeave(item): void {
    if (this.items.indexOf(this.movingChip) > -1) {
      let index = this.items.indexOf(item);
      let element: ElementRef = this.chips.toArray()[index];
      let nativeElement = element.nativeElement;
      nativeElement.style.marginLeft = '0px';
    }
  }
  /**
   * trigger when chip has been droped
   */
  chipDragDrop(item): void {
    if (this.items.indexOf(this.movingChip) > -1) {
      let index = this.items.indexOf(this.movingChip);
      this.items.splice(index, 1);

      index = this.items.indexOf(item);
      let element: ElementRef = this.chips.toArray()[index];
      let nativeElement = element.nativeElement;
      nativeElement.style.marginLeft = '0px';

      this.items.splice(index, 0, this.movingChip);

      this.movingChip = undefined;

      setTimeout(() => {
        this.items.splice(this.items.length - 1, 1);
      }, 0);
    }

  }
} 

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-support',
  templateUrl: './service-support.component.html',
  styleUrls: ['./service-support.component.scss']
})
export class ServiceSupportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

export class ReturnModel {
    trackingId: string
    resAdd: string
    city: string
    state: string
    pinCode: string
    tellNo: string
    pickUpDate: Date
    pickUpTime: string
    reasonId: number
    returnType: number
    bankName: string
    nameAsPerInBank: string
    ifsc: string
    accountNumber: string
    description: string
    orderId: number
    orderDetailId: number
    titleId: number
    packageId: number
    sizeRecNo: number
    variantId: number
    amount: number
    productPrice: number
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TextFieldComponent } from './text-field/text-field.component';

@NgModule({
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [TextFieldComponent],
  declarations: [
    TextFieldComponent
  ],
})
export class LsTextFieldModule {


}
import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common';
import { HttpService } from './http.service'
import { Constants } from '../common/constants/lsnetx.constants'
import { ProductModel } from '../common/models/productModel'
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * It fetches the product details and general settings.
 * @class  ProductDetailsService 
 */
@Injectable()
export class ProductDetailsService {

  /**
   * productDetailObservable - observable for product detail. 
   */
  productDetailObservable: Observable<ProductModel>;

  /**
   *  productDetailBehaviour - product detail behaviour subject.
   */
  productDetailBehaviour: BehaviorSubject<ProductModel>;
  /**
   * List of DI
   * @param httpService HttpService
   * @param platformId PLATFORM_ID
   */
  constructor(
    public httpService: HttpService,
    @Inject(PLATFORM_ID) private platformId: Object,

  ) {
    this.productDetailBehaviour = <BehaviorSubject<ProductModel>>new BehaviorSubject(new ProductModel());
    this.productDetailObservable = this.productDetailBehaviour.asObservable();
  }

  /**
   * It fetches the product details.
   * @param getReviews - get reviews or not.
   * @param titleIdList - list of title id's 
   * @param mode - (1 - product details in list  or  2 - in detail)
   * @param offerBookCall - offer book call true or false
   * @param callback fn 
   */
  getProductDetails(getReviews, titleIdList, mode, offerBookCall, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getProductDetails";
    let requestParams = new Map();
    requestParams.set("getReviews", getReviews);
    // requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId);
    requestParams.set("titleIds", titleIdList)
    requestParams.set("mode", mode)
    requestParams.set("offerBookCall", offerBookCall)
    if (isPlatformBrowser(this.platformId)) {
      if (window.sessionStorage.getItem('currencyId') != null) {
        requestParams.set("currencyId", JSON.parse(window.sessionStorage.getItem('currencyId')))
      }
    }
    this.httpService.get(url, requestParams, (resp) => {
      if (resp.data) {
        this.productDetailBehaviour.next(resp.data[0]);
      }
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the general settings.
   * @param callback fn
   */
  getGeneralSettings(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getStoreSettings/general";
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * This method verifies that the product can be delivered to the entered pin-code or not.
   * @param pincodes - list of pincodes
   * @param callback fn
   */
  checkDeliverableLocation(pincodes: Array<string>, callback: (data) => void) {
    let url = Constants.BASE_URL + "/checkDeliverableLocation";
    let requestParams = new Map();
    requestParams.set('pincode', pincodes);
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }


}

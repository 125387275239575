import { Directive, HostListener, ElementRef, Output, EventEmitter, Input } from '@angular/core';
/**
 * Allow data to be loaded on demand
 */
@Directive({
  selector: '[LsLazyLoader]'
})
export class LsLazyLoaderDirective {
  @Input() isVertical: boolean
    = true;

  /**
   * Triger a lazy load event when scroll reaches to the bottom of the page or the container
   */
  @Output() lazyLoadEvent = new EventEmitter<any>();
  /**
   * The constructor
   * @param elementRef 
   */
  constructor(private elementRef: ElementRef) { }
  /**
   * scroll event listner
   * load data on scroll
   * @param event 
   */
  @HostListener('scroll', ['$event'])
  onScrollEvent(event) {
    if (this.isVertical) {
      let scrollTop = this.elementRef.nativeElement.scrollTop;
      let innerHeight = this.elementRef.nativeElement.offsetHeight;
      let scrollHeight = this.elementRef.nativeElement.scrollHeight;
      if ((scrollTop + innerHeight) >= scrollHeight) {
        this.lazyLoadEvent.emit(event);
      }
    }
    else {
      let scrollLeft = this.elementRef.nativeElement.scrollLeft;
      let innerWidth = this.elementRef.nativeElement.offsetWidth;
      let scrollWidth = this.elementRef.nativeElement.scrollWidth;
      if ((scrollLeft + innerWidth) >= scrollWidth) {
        this.lazyLoadEvent.emit(event);
      }
    }
  }

}

import { Component, OnInit , Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { GetReviewListService } from '../../../services/get-review-list.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { UserModel } from '../../../common/models/user-model';
import { ReviewAvgModel } from '../../../common/models/reviewAvgModel';
import { ReviewModel } from '../../../common/models/reviewModel';

@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.scss']
})
export class ProductReviewComponent implements OnInit {

  dialog: LsMdDialog<ProductReviewComponent>;
  lblAddReview = "Add Reviews/Rating"
  note = "*Either Review or Rating is Mandatory"
  lblTitle = "Title*:"
  lblReview = "Review*:"
  lblRating = "Rating*:"
  lblButton = "Add Review"
  data //data sent to parent
  addReviewForm: FormGroup
  pageNum = 1
  numRecords = 28
  ratingAndReviewData: ReviewAvgModel 
  titleId
  variantId
  title
  review
  rating
  userId
  reviewId
  currUser: UserModel
  edit = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,    
    public formBuilder: FormBuilder,
    public getReviewListService: GetReviewListService,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,    
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)){
      this.addReviewForm = this.formBuilder.group({
        title: ["", [Validators.required]],
        review: [""],
        rating: [""]
      })
      if (window.localStorage.getItem('currentUser') != null) {
        this.currUser = JSON.parse(window.localStorage.getItem('currentUser'));
        this.userId = this.currUser.userId;
      }
      this.titleId = this.activatedRoute.snapshot.queryParamMap.get("t").toString();
      if (this.activatedRoute.snapshot.queryParamMap.has("sizeRecNum")) {
        this.variantId = this.activatedRoute.snapshot.queryParamMap.get("sizeRecNum").toString();
      } else {
        this.variantId = "";
      }
      this.checkForEdit();
    }   
  }

  closeDialog() {
    this.dialog.close(this.data);
  }

  checkForEdit(){
    this.getReviewListService.getReviewList(this.activatedRoute.snapshot.queryParamMap.get("t").toString(), this.pageNum, this.numRecords,  (data) => {
      if (data != undefined) {
        this.ratingAndReviewData = data.data;
        if(this.ratingAndReviewData && this.ratingAndReviewData.reviewModels){
          this.ratingAndReviewData.reviewModels.forEach(model => {
            if(model.userId == this.userId){
              this.edit = true;
              this.setData(model);
            }
          })
        }
      }
    })
  }

  setData(model: ReviewModel){
    this.addReviewForm.setValue({
      title : model.reviewTitle,
      review : model.reviewText,
      rating : model.productRating
    })
    this.reviewId = model.reviewId;
  }

  addReviewSubmit($event) {
    let reviewModel: ReviewModel = new ReviewModel();    
    if (this.addReviewForm.value.title != "" && (this.addReviewForm.value.review != "" || this.addReviewForm.value.rating != "")) {
      reviewModel.reviewTitle = this.addReviewForm.value.title;
      reviewModel.reviewText = this.addReviewForm.value.review;
      reviewModel.productRating = this.addReviewForm.value.rating;
      reviewModel.userId = this.userId
      reviewModel.titleId = this.titleId
      reviewModel.productVariantId = this.variantId
      reviewModel.reviewId = this.reviewId
      
      this.getReviewListService.addReview(reviewModel, (resp) => {
        if (!resp.error) {
          if (resp.data != null) {
            this.dialog.close(null);
            this.okayMessageDialogService.open(OkayMessageComponent, {}, Constants.POPUP_MSGS.REVIEW_SUCCESSFUL_UPDATED).subscribe(response => {
              if (isPlatformBrowser(this.platformId)) {
                window.location.reload();
              }
            });
          }
        } else {
          this.okayMessageDialogService.open(OkayMessageComponent, {}, Constants.POPUP_MSGS.REVIEW_UPDATION_UNSUCCESSFUL).subscribe(response => {
          });
          this.dialog.close(null)
        }
      })

    }
    //   this.getReviewListService.addReview(this.userId, this.titleId, this.variantId, this.title, this.review, this.rating, this.reviewId, (resp) => {        
    //   if (resp.data != null) {
    //     this.dialog.close(null);
    //     if(!resp.error){
    //       this.okayMessageDialogService.open(OkayMessageComponent, {}, Constants.POPUP_MSGS.REVIEW_SUCCESSFUL_UPDATED).subscribe(response => {
    //         if(isPlatformBrowser(this.platformId)){
    //           window.location.reload();    
    //         }   
    //       });
    //     }else{
    //       this.okayMessageDialogService.open(OkayMessageComponent, {}, Constants.POPUP_MSGS.REVIEW_UPDATION_UNSUCCESSFUL).subscribe(response => {
    //       });
    //     }
    //   }
    // })
  }

}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for preparing query-parameters for product-detail page.
 */
@Pipe({
  name: 'productQuery'
})
export class ProductQueryPipe implements PipeTransform {

  /**
   * prepares query-parameters by adding titleId as 't'.
   * @param product - product details
   * @param args - none
   */
  transform(product: any, args?: any): any {
    let queryParams;
    if (product && product.titleId) {
      queryParams = { 't': product.titleId };
    }
    return queryParams;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

<div id="suggestion-container" (click)="focusTextField()">
    <input (blur)="blurEvent.emit($event)" type="text" (keydown)="handleKeys($event)" [(ngModel)]="textValue"
        [placeholder]="getPlaceholder()" #textfield [attr.maxlength]=maxLength>
    <div class="cdk-overlay-container" *ngIf="openSuggestionModal" (click)="closeSuggestionModal()"
        style="pointer-events: all;"></div>
    <div #modal id="options-overlay" *ngIf="openSuggestionModal" class="z-depth-3 cdk-overlay-pane" [style.top]="top"
        [style.left]="left" [style.maxHeight]="maxHeight">
        <div class="text" *ngIf="dataList1 && dataList1.length">Search Suggestions</div>
        <ng-container *ngIf="dataList1 && dataList1.length">
            <div *ngFor="let suggestion of dataList1;let i = index" class="waves-effect option"
                (click)="selectOption(suggestion)" #option>
                <ng-template lsSuggestionOptionHost></ng-template>
            </div>
        </ng-container>
        <div class="text" *ngIf="dataList2 && dataList2.length">Suggested Titles</div>
        <ng-container *ngIf="dataList2 && dataList2.length">
            <div *ngFor="let suggestion of dataList2;let i = index" class="waves-effect option"
                (click)="selectOption(suggestion)" #option>
                <ng-template lsSuggestionOptionHost></ng-template>
            </div>
        </ng-container>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RadioComponent } from './radio/radio.component';

@NgModule({
    imports: [
        MatRadioModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [RadioComponent],
    declarations: [
        RadioComponent
    ],
})
export class LsRadioModule {


}
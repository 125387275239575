import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';

/**
 * It is used to track and cancel order .
 * It is used to get order query info.
 * It is used to cancel order Item. 
 * @class TrackOrderService
 */
@Injectable()
export class TrackOrderService {
  /**
   * List of DI
   * @param platformId PLATFORM_ID
   * @param httpService HttpService
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private httpService: HttpService
  ) { }

  /**
   * It is used to track order.
   * @param orderId - id of order
   * @param pageNum - page number
   * @param callback fn
   */
  trackOrder(orderId, pageNum, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/getMyOrders";
    let requestParams = new Map();
    let params = {};
    requestParams.set('orderId', orderId);
    requestParams.set('pageNum', pageNum);
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('currencyId') != null) {
        requestParams.set("currencyId", JSON.parse(sessionStorage.getItem('currencyId')))
      }
    }
    this.httpService.post(url, params, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It is used to cancel order corresponding to the id.
   * @param orderId - id of the order.
   *  @param callback fn
   */
  cancelOrder(orderId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/cancelOrder"
    let requestParams = new Map()
    let params = {}
    requestParams.set('orderId', orderId)
    this.httpService.post(url, params, requestParams, (resp) => {
      callback(resp)
    }, (error) => {
      console.log(error)
    })
  }

  /**
   * It is used to cancel item from the order.
   * @param orderId - id of the order.
   * @param orderDetailId - id of the order detail.
   * @param callback fn
   */
  cancelItemFromOrder(orderId, orderDetailId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/cancelItemFromOrder"
    let requestParams = new Map()
    let params = {}
    requestParams.set('orderId', orderId)
    requestParams.set('orderDetailId', orderDetailId)
    this.httpService.post(url, params, requestParams, (resp) => {
      callback(resp)
    }, (error) => {
      console.log(error)
    })
  }

  /**
   * It is used to get order query info.
   * @param orderQuery - query for the order.
   * @param callback fn
   */
  getOrderQueryInfo(orderQuery, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/getOrderQueryInfo"
    let requestParams = new Map()
    let params = {}
    params = JSON.parse(JSON.stringify(orderQuery))
    this.httpService.post(url, params, requestParams, (resp) => {
      callback(resp)
    }, (error) => {
      console.log(error)
    })
  }

  /**
   * It is used to return items.
   * @param returnModel - contains information about the return .
   * @param callback fn
   */
  returnItems(returnModel, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/returnItems"
    let requestParams = new Map()
    let params = {}
    params = JSON.parse(JSON.stringify(returnModel))
    this.httpService.post(url, params, requestParams, (resp) => {
      if (resp && resp.otherInfo && resp.otherInfo.returnTypes && resp.data && resp.data.list && resp.data.list.length) {
        resp.data.list[0].returnType = resp.otherInfo.returnTypes
      }
      callback(resp)
    }, (error) => {
      console.log(error)
    })
  }

  /**
   * It is used to track order of the id.
   * @param email - email 
   * @param orderId - id of the order
   * @param callback fn
   */
  trackOrderUsingOrderId(email, orderId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/trackOrder";
    let requestParams = new Map();
    let params = {};
    requestParams.set('userEmailId', email);
    requestParams.set('orderId', orderId);
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('currencyId') != null) {
        requestParams.set("currencyId", JSON.parse(sessionStorage.getItem('currencyId')))
      }
    }
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It is used to send invoice to user.
   * @param orderId - id of the order.
   * @param invoiceNo - invoice no.
   * @param callback fn
   */
  sendInvoiceToUser(orderId, invoiceNo, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/sendInvoiceToUser";
    let requestParams = new Map();
    let params = {};
    let body
    requestParams.set('orderDisplayId', orderId);
    requestParams.set('invoiceNo', invoiceNo);
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches return details for the product
   * @param OrderProductModel - contains information about order .
   * @param callback fn
   */
  getReturnDetails(OrderProductModel, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/getReturnDetails";
    let requestParams = new Map();
    let params = {};
    let body = JSON.parse(JSON.stringify(OrderProductModel));
    this.httpService.post(url, body, requestParams, (resp) => {
      if (resp && resp.otherInfo && resp.otherInfo.returnTypes && resp.data && resp.data.list && resp.data.list.length) {
        resp.data.list[0].returnType = resp.otherInfo.returnTypes
      }
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It is used to track package delivery.
   * @param podNum : 
   * @param courierAgencyId : id of the courier agency.
   * @param callback fn
   */
  trackPackageDelivery(podNum: string, courierAgencyId: number, callback: (data) => void) {
    let url = Constants.BASE_URL + "/trackPackageDelivery";
    let requestParams = new Map();
    requestParams.set('podNum', podNum);
    requestParams.set('courierAgencyId', courierAgencyId);
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

}

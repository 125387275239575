import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpService } from './http.service'
import { Constants } from '../common/constants/lsnetx.constants';
import { CartModel } from '../common/models/cartModel';
import { CartProductModel } from '../common/models/cartProductModel';
import { CartCountService } from './cart-count.service';
import { LoginService } from './login.service';
import { LoginRequestModel } from '../common/models/loginRequestModel';
import { Router } from '@angular/router';
import { OkayMessageComponent } from '../published/commonComponent/okay-message/okay-message.component';
import { LsDialogService } from '../LSNG/components/ls-dialog/ls-dialog.service';

/**
 * It contains all the operations to fetch ,apply and remove coupons.
 * It contains operations to update and fetch cart.
 * @class CartService
 */
@Injectable()
export class CartService {

  /**
   *List of DI
   * @param platformId platformId DI 
   * @param cartCountService cartCountService DI 
   * @param loginService loginService DI 
   * @param httpService httpService DI 
   * @param router router DI 
   * @param okayMessageDialogService okayMessageDialogService DI 
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public cartCountService: CartCountService,
    private loginService: LoginService,
    public httpService: HttpService,
    private router: Router,
    public okayMessageDialogService: LsDialogService<OkayMessageComponent>,
  ) { }

  /**
   * Fetches cart.
   * @param {CartModel} cartModel - contains current cart information
   * @param {boolean} validateOffers
   * @param {boolean} forceFetch - if user is logged-in then forceFetch is true o/w false.
   * @param callback fn 
   */
  getCart(cartModel: CartModel, validateOffers: boolean, forceFetch: boolean, callback: (data) => void) {
    if (isPlatformBrowser(this.platformId)) {
      let currencyId = parseInt(window.sessionStorage.getItem('currencyId'));
      if (currencyId) {
        cartModel.currencyId = currencyId;
        cartModel.cartId = 0;
      }
    }
    let url = Constants.BASE_URL + "/cart/get";
    let requestParams = new Map();
    requestParams.set('forceFetch', forceFetch);
    requestParams.set('validateOffers', validateOffers);
    let body = cartModel;
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      callback({ error: true })
      console.log(error);
    })
  }

  /**
   * Does cart computations; deletion, updation, insertion of items in cart
   * @param {CartModel} cartModel - contains current cart information
   * @param callback fn
   */
  computeCart(cartModel: CartModel, callback: (data) => void) {
    let url = Constants.BASE_URL + "/cart/api/compute";
    let requestParams = new Map();
    let body = cartModel;
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches all valid coupons 
   * @param {boolean} fetchEnquiries-for crm 
   * @param callback fn
   */
  getValidCoupons(fetchEnquiries: boolean, callback: (data) => void) {
    let url = Constants.BASE_URL + "/cart/api/getValidCoupons";
    let requestParams = new Map();
    if (fetchEnquiries != undefined && fetchEnquiries != null) {
      requestParams.set('fetchEnquiries', fetchEnquiries);
    }
    if (isPlatformBrowser(this.platformId)) {
      if (window.sessionStorage.getItem('currencyId') != null) {
        requestParams.set("currencyId", JSON.parse(window.sessionStorage.getItem('currencyId')))
      }
    }
    let body;
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It apply or remove coupon from the cart.
   * @param {CartModel} cartModel - It contains the information of the cart. 
   * @param {string} couponCode - coupon code. 
   * @param {boolean} toApply - It contains the information of whether to apply coupon or remove coupon.
   * @param callback fn
   */
  applyRemoveCoupon(cartModel: CartModel, couponCode: string, toApply: boolean, callback: (data) => void) {
    let url = Constants.BASE_URL + "/cart/api/applyRemoveCoupon";
    let requestParams = new Map();
    cartModel.cartId = 0;
    if (couponCode != undefined && couponCode != null) {
      requestParams.set('couponCode', couponCode);
    }
    if (toApply != undefined && toApply != null) {
      requestParams.set('toApply', toApply);
    }
    let body = cartModel;
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It preorder the product of given titleId.
   * @param {number} titleId - product titleId to be preorder. 
   * @param callback fn
   */
  addPreOrder(titleId: number, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/addPreOrder";
    let requestParams = new Map();
    requestParams.set('titleId', titleId);
    let body;
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    });
  }

  /**
   * If not Login, 
   * 1. check if localCart is empty, 
   *      a)if yes, then create and update new cart and save new cart in localStorage and update observable.
   *      b)if no, then update localCart and save updated cart in localStorage and update observable.
   * If Login (
   *      1. get prevUserCart and check if localCart is empty, then create and update new cart with prevUserCart
   *         and save it in localStorage and update cartObsevable.
   *        if local is not empty, then update prevUserCart with localCart and save it in localStorage and update
   *        cartObservable.
   *  )
   * 1. check if localCart is empty, 
   *       repeat 1.a), 1.b).
   * @param event titleId
   * */
  addToCartProduct(event) {

    if (isPlatformBrowser(this.platformId)) {
      let localCart: CartModel;
      let newProd: CartProductModel = new CartProductModel();
      newProd.titleId = Number.parseInt(event);
      newProd.numCopies = 1;
      if (localStorage.getItem('localCart')) {
        localCart = JSON.parse(localStorage.getItem('localCart'));
      }
      if (window.localStorage.getItem('currentUser') !== null) {
        //logged in
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
          //update localCart
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        //compute cart
        if (!this.checkProductAlreadyPresent(event, false)) {
          this.computeCart(newCart, (resp) => {
            if (!resp.error && resp.data) {
              this.setLocalCart(resp.data[0], false);
              this.cartCountService.updateCart(resp.data[0]);

              //todo Product Added to Cart!
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
            } else {
              //todo Error in adding product to cart!
              this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Error in adding product to cart!');
            }
          });
        } else {
          // todo alreadyAdded 
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      } else {
        //not logged in
        let newCart: CartModel = new CartModel();
        newCart.cartProductModels = [];
        newCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
        if (localCart) {
          localCart.cartProductModels.push(newProd);
          newCart.cartProductModels = localCart.cartProductModels;
        } else {
          newCart.cartProductModels.push(newProd);
        }
        if (!this.checkProductAlreadyPresent(event, false)) {
          this.setLocalCart(newCart, false);
          this.cartCountService.updateCart(newCart);
          //todo Product Added to Cart!
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Product Added to Cart!');
        } else {
          //todo alredy added
          this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
        }
      }
    }
  }

  /**
   * return true or false, if particular product is present in the shopping-cart or wish-list cart respectively.
   * @param id {number} - titleId of the product
   * @param isWishlist {boolean} - 'true' if wishlist else 'false'
   */
  checkProductAlreadyPresent(id: number, isWishlist: boolean): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false
    }
    let localCart;
    if (isWishlist) {
      localCart = JSON.parse(localStorage.getItem('wishlist'));
    } else {
      localCart = JSON.parse(localStorage.getItem('localCart'));
    }
    let present: boolean;
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      localCart.cartProductModels.forEach(element => {
        if (element.titleId == id) {
          present = true;
        }
      });
    }
    if (present) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * prepare local-cart for shopping-cart as well as wishlist-cart by setting titleId, variantId, numCopies in cartProductModels,
   * the set this local-cart in local-storage.
   * @param cart {CartModel} - shopping-cart or wishlist-cart
   * @param isWishlist {boolean} - 'true' is wishlist, else 'false'
   */
  setLocalCart(cart: CartModel, isWishlist: boolean) {
    if (!isPlatformBrowser(this.platformId)) { return }
    let newLocalCart: CartModel = new CartModel();
    if (cart && cart.cartProductModels && cart.cartProductModels.length > 0) {
      newLocalCart.cartProductModels = [];
      cart.cartProductModels.forEach((ele) => {
        if (!ele.offer) {
          let localCartEle: CartProductModel = new CartProductModel();
          if (ele.titleId)
            localCartEle.titleId = ele.titleId;
          if (ele.variantId)
            localCartEle.variantId = ele.variantId;
          if (ele.numCopies)
            localCartEle.numCopies = ele.numCopies;
          newLocalCart.cartProductModels.push(localCartEle);
        }
      });
    }
    if (!isWishlist) {
      localStorage.setItem('localCart', JSON.stringify(newLocalCart));
    } else {
      localStorage.setItem('wishlist', JSON.stringify(newLocalCart));
    }
  }

  /**
   * helper method to add a product to wishlist-cart.
   * If user is logged-in, then proceed to add product to the wishlist-cart, else
   * open perform login first.
   * @param event {CartProductModel} - product to be added
   */
  addToWishlist(event: CartProductModel) {
    if (isPlatformBrowser(this.platformId) && window.localStorage.getItem('currentUser') !== null) {
      this.addToCartWishlistUtility(event, false);
    } else {
      this.goToLogin(Constants.LOGIN_AFTER_ACTION.ADD_TO_WISHLIST, event);
    }
  }

  /**
   * prepares LoginRequestModel, which is used for actions to be performed after successful login.
   * navigates to dedicated login page.
   * @param actn {number} - action type
   * @param cartProductMdel {CartProductModel} - product detail
   */
  goToLogin(actn: number, cartProductMdel: CartProductModel) {
    let loginRequestModel: LoginRequestModel = new LoginRequestModel();
    loginRequestModel.moveTo = this.router.url;
    loginRequestModel.actn = actn;
    loginRequestModel.titleId = cartProductMdel.titleId.toString();
    loginRequestModel.isDetailPage = false;
    loginRequestModel.cartProductMdel = cartProductMdel;
    this.loginService.setLoginRequestModel(loginRequestModel);
    this.router.navigate(['/login']);
  }

  /**
   * This method adds a product to wishlist-cart.
   * First, fetch wishlist-cart from local-storage and then prepare new wishlist-cart after adding local-wishlist-cart and new product.
   * Now check if the product is already present in the wishlist-cart or not.
   * If 'yes', then display error-message, and If 'no', send computeCart request with new wishlist-cart.
   * After request is successful, update wishlist-cart in local-storage. 
   * @param event - product to be added to wishlist cart. 
   * @param isLoginAction {boolean} - 'true' is some action is to be performed after login, else 'false'
   */
  addToCartWishlistUtility(event: CartProductModel, isLoginAction: boolean) {
    if (!isPlatformBrowser(this.platformId)) { return }
    let msg = 'Added to your Wishlist!';
    let errMsg = 'Error in adding product to wishlist!';
    let localWishlistCart: CartModel;
    if (localStorage.getItem('wishlist')) {
      localWishlistCart = JSON.parse(localStorage.getItem('wishlist'));
    }
    let newWishlistCart: CartModel = new CartModel();
    newWishlistCart.cartProductModels = [];
    newWishlistCart.cartProductModels.push(event);
    newWishlistCart.cartWishListType = Constants.CART_WISHLIST_TYPE.WISHLIST;
    if (localWishlistCart && localWishlistCart.cartProductModels && localWishlistCart.cartProductModels.length > 0) {
      localWishlistCart.cartProductModels.forEach((ele: CartProductModel) => {
        newWishlistCart.cartProductModels.push(ele);
      });
    }
    if (!this.checkProductAlreadyPresent(event.titleId, true)) {
      this.computeCart(newWishlistCart, (resp) => {
        if (!resp.error && resp.data && resp.data[0]) {
          this.setLocalCart(resp.data[0], true);
          //todo okey message
          // this.okayMessageDialogService.open(OkayMessageComponent, {}, msg);
          if (isLoginAction) {
            this.loginService.setLoginRequestModel(new LoginRequestModel());
          }
        } else {
          // todo errmsg
          // this.okayMessageDialogService.open(OkayMessageComponent, {}, errMsg);
        }
      });
    } else {
      // todo already added
      // this.okayMessageDialogService.open(OkayMessageComponent, {}, 'Already Added!');
      if (isLoginAction) {
        this.loginService.setLoginRequestModel(new LoginRequestModel());
      }
    }
  }

}

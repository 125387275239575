<i class="fa fa-close" *ngIf="showCancel" (click)="clearTags()" style="cursor: pointer; float: right;"></i>
<div id="suggestion-container" (click)="focusTextField()">
  <ng-container *ngIf="showTags">
    <div *ngFor="let tag of tagList" class="tag">
      <ng-template lsSuggestionTag></ng-template>
      <a class="close-button" (click)="closeTag(tag)"><i class="fa fa-close"></i></a>
    </div>
  </ng-container>
  <input [class.ellipsis]="showEllipsis" (blur)="blurEvent.emit($event)" type="text" (keydown)="handleKeys($event)"
    [(ngModel)]="textValue" [placeholder]="getPlaceholder()" [attr.maxlength]=maxLength
    [title]="(textValue && textValue?.length && showTitle) ? textValue : '' " #textfield>
  <div class="cdk-overlay-container" *ngIf="openSuggestionModal" (click)="closeSuggestionModal()"
    style="pointer-events: all;"></div>
  <div #modal id="options-overlay" *ngIf="openSuggestionModal" class="z-depth-3 cdk-overlay-pane" [style.top]="top"
    [style.left]="left" [style.maxHeight]="maxHeight">
    <div *ngFor="let suggestion of dataList" class="waves-effect option" (click)="selectOption(suggestion)" #option>
      <ng-template lsSuggestionOptionHost></ng-template>
    </div>

  </div>
</div>
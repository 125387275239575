import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SuggestionOption } from '../suggestion-option'

@Component({
  selector: 'app-test-suggestion',
  templateUrl: './test-suggestion.component.html',
  styleUrls: ['./test-suggestion.component.css']
})
export class TestSuggestionComponent implements OnInit, SuggestionOption {

  built  = false;
  label = "";

  json : any;

  constructor(private _cdr : ChangeDetectorRef) { }

  ngOnInit() {
  }

  buildComponent(json : any) : void {
    // console.log(json);
    
    this.built = true;
    this.label = json.label;
    this._cdr.detectChanges();
  }



}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';
import { StaticPageModel } from '../common/models/static-page-model'
import { BlogModel } from '../common/models/blogModel';
import { CategoryModel } from '../common/models/category-model';
import { CollectionModel } from '../common/models/collectionModel';
import { BannerModel } from '../common/models/bannerModel';
import { OnlineStoreModel } from '../common/models/online-store-model';
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * It fetches banner and offer to be displayed on homepage,store settings, collections, quicklinks, brands, media gallery and color-codes.
 * It append the current cart to existing cart.
 * It subscribe and unsubscribe news letter.
 * @class HomePageService
 */
@Injectable()
export class HomePageService {
  /**
   * QuickLinkStore 
   */
  private quicklinkStore: StaticPageModel[] = [];
  /**
   *  quicLinksObservable - quick link observable. 
   */
  quicLinksObservable: Observable<Array<StaticPageModel>>;

  /**
   *  quicLinksBehaviour - quick link behaviour subject. 
   */
  quicLinksBehaviour: BehaviorSubject<Array<StaticPageModel>>;

  /**
   *  allCategoriesObservable - all categories observable.
   */
  allCategoriesObservable: Observable<Array<CategoryModel>>;

  /**
   *  allCategoriesBehaviour- all categories behaviour subject. 
   */
  allCategoriesBehaviour: BehaviorSubject<Array<CategoryModel>>;

  /**
   *  parentCategoriesObservable - parent categories observable. 
   */
  parentCategoriesObservable: Observable<Array<CategoryModel>>;

  /**
   *  parentCategoriesBehaviour - parent categories behaviour subject.
   */
  parentCategoriesBehaviour: BehaviorSubject<Array<CategoryModel>>;

  /**
   *  childCategoriesObservable - child categories observable. 
   */
  childCategoriesObservable: Observable<Array<CategoryModel>>;

  /**
   *  childCategoriesBehaviour - child categories behaviour subject.
   */
  childCategoriesBehaviour: BehaviorSubject<Array<CategoryModel>>;

  /**
   * collectionsObservable - collection observable. 
   */
  collectionsObservable: Observable<Array<CollectionModel>>;

  /**
   *  collectionBehaviour - collection behaviour subject.
   */
  collectionsBehaviour: BehaviorSubject<Array<CollectionModel>>;

  /**
   * behaviour and offers observable
   */
  bannersOffersObservable: Observable<Array<BannerModel>>;

  /**
   * banners and offers behaviour subject
   */
  bannersOffersBehaviour: BehaviorSubject<Array<BannerModel>>;

  /**
   * Not in used 
   * @deprecated Not in used 
   */
  storeObservable: Observable<Array<any>>;

  /**
   * Not in used
   * @deprecated Not in used
   */
  storeBehaviour: BehaviorSubject<Array<any>>;

  /**
   * is store selected observable, in case of multi-store.
   */
  isStoreSelectedObservable: Observable<boolean>;

  /**
   * is store selected behaviour subject, in case of multi-store.
   */
  isStoreSelectedBehaviour: BehaviorSubject<boolean>;

  /**
   * DI
   * @param platformId DI
   * @param _document DI
   * @param httpService DI
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public httpService: HttpService) {
    // this.allStoreDataBehaviour = <BehaviorSubject<any>>new BehaviorSubject([]);
    // this.allStoreDataObservable = this.allStoreDataBehaviour.asObservable();
    this.quicLinksBehaviour = <BehaviorSubject<Array<StaticPageModel>>>new BehaviorSubject(this.quicklinkStore);
    this.quicLinksObservable = this.quicLinksBehaviour.asObservable();
    this.allCategoriesBehaviour = <BehaviorSubject<Array<CategoryModel>>>new BehaviorSubject([]);
    this.allCategoriesObservable = this.allCategoriesBehaviour.asObservable();
    this.parentCategoriesBehaviour = <BehaviorSubject<Array<CategoryModel>>>new BehaviorSubject([]);
    this.parentCategoriesObservable = this.parentCategoriesBehaviour.asObservable();
    this.childCategoriesBehaviour = <BehaviorSubject<Array<CategoryModel>>>new BehaviorSubject([]);
    this.childCategoriesObservable = this.childCategoriesBehaviour.asObservable();
    this.collectionsBehaviour = <BehaviorSubject<Array<CollectionModel>>>new BehaviorSubject([]);
    this.collectionsObservable = this.collectionsBehaviour.asObservable();
    this.bannersOffersBehaviour = <BehaviorSubject<Array<BannerModel>>>new BehaviorSubject([]);
    this.bannersOffersObservable = this.bannersOffersBehaviour.asObservable();
    this.storeBehaviour = <BehaviorSubject<Array<any>>>new BehaviorSubject([]);
    this.storeObservable = this.storeBehaviour.asObservable();
    this.isStoreSelectedBehaviour = <BehaviorSubject<false>>new BehaviorSubject(false);
    this.isStoreSelectedObservable = this.isStoreSelectedBehaviour.asObservable();
  }

  /**
   * It fetches banners and offers to be displayed on home-page.
   * @param isBanner - if banner is true, it fetches banners else offers.
   * @param callback fn
   */
  getBannerOffer(isBanner, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getBannerOffers";
    let requestParams = new Map();
    if (isBanner != undefined && isBanner != null) {
      requestParams.set("isBanner", isBanner);
    }
    requestParams.set("templateId", Constants.VENDOR_DETAILS.templateId)
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches all the blogs. 
   * @param callback fn
   */
  getBlogOffer(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getBlogs";
    let requestParams = new Map();
    let blogModel = new BlogModel()
    requestParams.set("showContent", false)
    let body = blogModel
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the store settings cooresponding to the setting value.
   * @param settingValue settingValue
   * @param callback fn
   */
  getStoreSettings(settingValue, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getStoreSettings/" + settingValue;
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * Not in used 
    @deprecated not is used
   */
  getHomePageCollection(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getCollections";
    let requestParams = new Map();
    // requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId)
    requestParams.set("isDetailPage", false)
    requestParams.set("fetchProducts", true)
    if (isPlatformBrowser(this.platformId)) {
      if (window.sessionStorage.getItem('currencyId') != null) {
        requestParams.set("currencyId", JSON.parse(window.sessionStorage.getItem('currencyId')))
      }
    }
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches collection of home page.
   * @param isDetailPage - whether we are making detail page collection or home page collection.
   * @param fetchProducts - products is to be fetch or not.
   * @param {string} categoryId - category id. 
   * @param callback fn
   */
  getHomePageCollectionIn(isDetailPage, fetchProducts, categoryId: string, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getCollections";
    let requestParams = new Map();
    // requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId);
    requestParams.set("isDetailPage", isDetailPage);
    requestParams.set("fetchProducts", fetchProducts);
    requestParams.set("categoryId", categoryId);
    if (isPlatformBrowser(this.platformId)) {
      if (window.sessionStorage.getItem('currencyId') != null) {
        requestParams.set("currencyId", JSON.parse(window.sessionStorage.getItem('currencyId')))
      }
    }
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the quick links.
   * @param callback fn
   */
  getQuickLinks(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getStaticPages";
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * email Id subscribe to news letter
   * @param email - email id of the user.
   * @param callback fn
   */
  subscribeNewsletter(email, callback: (data) => void) {
    let url = Constants.BASE_URL + "/subscribeNewsletter";
    let requestParams = new Map();
    let params = {};
    requestParams.set("userEmailId", email);
    this.httpService.post(url, params, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It unsubscribe newsletter.
   * @param email - email id of the user.
   * @param {string} reason - reason to unsubscribe newsletter. 
   * @param callback fn
   */
  unsubscribeNewsletter(email, reason: string, callback: (data) => void) {
    let url = Constants.BASE_URL + "/unsubscribeNewsletter";
    let requestParams = new Map();
    let params = {};
    requestParams.set("userEmail", email);
    requestParams.set("reason", reason);
    this.httpService.post(url, params, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the currency details of the store.
   * @param callback fn
   */
  getCurrencyDetails(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getCurrenciesForStore";
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
 * It appends the existing cart with the new cart.
 * @param flag - whether the existing cart must appended or not.
 * @param callback fn
 */
  appendToCart(flag, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/appendPreviousCart";
    let requestParams = new Map(); let body;
    requestParams.set("addExistingCart", flag)
    if (isPlatformBrowser(this.platformId)) {
      body = JSON.parse(localStorage.getItem('localCart'))
    }
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the count of the cart.
   * @param callback fn
   */
  getCartCount(callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/getCartCount";
    let requestParams = new Map();
    this.httpService.post(url, {}, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches all the brands.
   * @param callback fn
   */
  fetchAllBrands(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getAllBrands";
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the media gallery. 
   * @param resourceType - type of resource.
   * @param resourceCategory - category of media resource.
   * @param pageNo - page number .
   * @param numRec - number of records to be fetch in each page.
   * @param year - year of which gallery is to be fetch .
   * @param callback fn
   */
  getMediaGallery(resourceType, resourceCategory, pageNo, numRec, year, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getMediaGallery";
    let requestParams = new Map();
    requestParams.set("resourceType", resourceType)
    requestParams.set("resourceCategory", resourceCategory)
    requestParams.set("pageNo", pageNo)
    requestParams.set("numRec", numRec)
    if (year != null) {
      requestParams.set("year", year)
    }
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the color codes.
   * @param callback fn
   */
  getColorCodes(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getColorCodes";
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * set siteId and storeId in request-parameters and fetch vendor-details.
   * After data is received, populate vendor-details and settings in global constants,
   * also update fav-icon of the application,
   * also update is store selected observable 'true'.
   * @param callback fn
   * @param errFun fn
   */
  getVendorDetails(callback: (data) => void, errFun: (data) => void = () => { }) {
    let url = Constants.BASE_URL + '/getVendorDetails';
    let requestParams = new Map();
    requestParams.set("siteId", Constants.SITE_ID + "");
    requestParams.set("storeId", Constants.STORE_ID + "");
    this.httpService.get(url, requestParams, (resp) => {
      if (resp.data) {
        Constants.WAR_NAME = resp.data.warName
        let vendorDetails = <OnlineStoreModel>resp.data;
        Constants.VENDOR_DETAILS = vendorDetails;
        /**
        * code for dynamic favicon icon
        */
        if (Constants.VENDOR_DETAILS && Constants.VENDOR_DETAILS.fabIcon) {
          this._document.getElementById('appFavicon').setAttribute('href', Constants.VENDOR_DETAILS.fabIcon);
        }
        if (vendorDetails.onlineStoreSettingDTO != undefined) {
          Constants.ONLINE_STORE_SETTING_MODEL = vendorDetails.onlineStoreSettingDTO
          if (vendorDetails.onlineStoreSettingDTO.cartSettingsDTO != undefined)
            Constants.CART_SETTINGS = vendorDetails.onlineStoreSettingDTO.cartSettingsDTO
          if (vendorDetails.onlineStoreSettingDTO.socialMediaSettingsDTO != undefined) {
            Constants.SOCIAL_SETTINGS = vendorDetails.onlineStoreSettingDTO.socialMediaSettingsDTO
          }
          if (vendorDetails.onlineStoreSettingDTO.seoSettingsDTO != undefined) {
            Constants.SEO_SETTINGS = vendorDetails.onlineStoreSettingDTO.seoSettingsDTO
          }
          if (vendorDetails.onlineStoreSettingDTO.paymentSettingsDTO != undefined)
            Constants.PAYMENT_SETTINGS = vendorDetails.onlineStoreSettingDTO.paymentSettingsDTO;
        }
        this.isStoreSelectedBehaviour.next(true);
        callback(resp);
      } else {
        callback(resp);
      }
    }, (error) => {
      console.log(error);
      callback(error);
    });
  }

  /**
   * set siteId and companyId in request-parameters and fetch list of all stores for SiteID.
   * If data is received, then populate list of stores in global-constants and populate first store's details in global-constants (as default store details).
   * fetch 'storeId' from local-storage, if it is present and not '0', then fetch specifc store-details using 'storeId', else
   * update is store selected observable 'false'.
   * @param callback fn
   */
  getAllStores(callback: (data) => void) {
    let _storeId;
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('storeId') || JSON.parse(localStorage.getItem('storeId')) == 0) {
        _storeId = JSON.parse(localStorage.getItem('storeId'));
        Constants.STORE_ID = _storeId;
      }
    }
    let url = Constants.BASE_URL + '/getAllStores';
    let requestParams = new Map();
    requestParams.set("siteId", Constants.SITE_ID + "")
    requestParams.set("companyId", Constants.COMPANY_ID + "")
    this.httpService.get(url, requestParams, (resp) => {
      Constants.ALL_STORES_DATA = resp;
      if (resp.data) {
        Constants.STORES = resp.data;
        if (Constants.STORES[0].onlineStoreSettingDTO) {
          Constants.VENDOR_DETAILS = Constants.STORES[0];
        }
        if (resp.otherInfo && resp.otherInfo.companyDetails) {
          Constants.COMPANY_DETAILS = resp.otherInfo.companyDetails;
          if (Constants.COMPANY_DETAILS.hasMultiStore == 1) {
            Constants.IS_MULTI_STORE = true;
          } else {
            Constants.IS_MULTI_STORE = false;
          }
        }
        if (Constants.STORES && Constants.STORES.length > 1) {
          Constants.IS_MULTI_STORE = true;
        } else {
          Constants.IS_MULTI_STORE = false;
        }
        if (Constants.IS_MULTI_STORE && _storeId) {
          /**
           * if, store is already selected, then get store-details or vendor-details
           */
          

            this.getVendorDetails((resp) => { });

          
        }
        this.isStoreSelectedBehaviour.next(false);
        this.storeBehaviour.next(resp);
        callback(resp);
      } else {
        callback(resp);
      }
    }, (error) => {
      console.log(error);
      callback(error);
    });
  }

  /**
   * When any store is selected from the list of all stores (in case of multi-case), then
   * update selected store's ID and domain-name in local-storage, so that state is maintained even after refresh.
   * Also update storeId in global-constant.
   * @param storeId store id 
   * @param storeDomain store domain 
   * @param callback fn 
   */
  storeUpdation(storeId, storeDomain, callback: (data) => void) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('storeId', JSON.stringify(storeId));
      localStorage.setItem('storeDomain', JSON.stringify(storeDomain));
      Constants.STORE_ID = storeId;
      this.getVendorDetails(resp => {
        if (resp) {
          callback(resp);
        } else {
          callback(false);
        }
      });
    }
  }

  /**
   * removes storeId and storeDomain name from local-storage.
   * set storeId as 0 in global-constant.
   * If list of stores is not present, then fetch all stores.
   * Finally set is-selected-store observable 'false'.
   */
  removeSelectedStore() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.getSelectedStoreId() && this.getSelectedStoreDomain()) {
        localStorage.removeItem('storeId');
        localStorage.removeItem('storeDomain');
        Constants.STORE_ID = 0;
      }
    }
    if (Constants.STORES.length == 0) {
      this.getAllStores(resp => {
      });
    } else {
      this.isStoreSelectedBehaviour.next(false);
    }
  }

  /**
   * returns 'storeId' from local-storage.
   */
  getSelectedStoreId() {
    return JSON.parse(localStorage.getItem('storeId'));
  }

  /**
   * returns 'storeDomain' from local-storage
   */
  getSelectedStoreDomain() {
    return JSON.parse(localStorage.getItem('storeDomain'));
  }

  /**
   * updates quick-links observable.
   * @param staticPageModel StaticPageModel[] 
   */
  updateQuickLink(staticPageModel: StaticPageModel[]) {
    this.quicklinkStore = staticPageModel;
    this.quicLinksBehaviour.next([...this.quicklinkStore]);
  }


}
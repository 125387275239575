import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';

/**
 * It gives the contact information of the store,dealer.
 * It submits the query form. 
 * It submits and adds the career information.
 * @class ContactService
 */
@Injectable()
export class ContactService {
  /**
   * DI
   * @param httpService HttpService DI
   */
  constructor(public httpService: HttpService) { }

  /**
   * It gives the google map location of the store.
   * @param callback fn 
   */
  getMap(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getStoreSettings/general";
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It submits the contact query. 
   * @param formData - It stores the information of the form. 
   * @param callback fn
   */
  sendContactQuery(formData, callback: (data) => void) {
    let url = Constants.BASE_URL + "/addContactUs";
    let requestParams = new Map();
    this.httpService.post(url, formData, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It submits the request of product if it is not available in the portal.
   * @param formData - It contains the form data. 
   * @param email - email
   * @param name - name
   * @param callback fn 
   */
  sendGetForMeRequest(formData, email, name, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getForMe";
    let requestParams = new Map();
    requestParams.set('userEmailId', email);
    requestParams.set('userName', name);
    this.httpService.post(url, formData, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It submits the service support form.
   * @param serviceSupportDTO 
   * @param callback fn
   */
  addServiceRequest(serviceSupportDTO, callback: (data) => void) {
    let url = Constants.BASE_URL + "/addServiceRequest";
    let requestParams = new Map();
    this.httpService.post(url, serviceSupportDTO, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the states of the dealer. 
   * @param callback fn
   */
  getStates(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getDealerStates";
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the cities of the state. 
   * @param stateCode - contains the code of the state.
   * @param callback fn
   */
  getCitiesByState(stateCode, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getDealerCitiesByState";
    let requestParams = new Map();
    requestParams.set("stateCode", stateCode)
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the dealers in the city.
   * @param stateCode - code of the state.
   * @param cityName - name of the city.
   * @param callback fn
   */
  getDealersByCity(stateCode, cityName, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getDealersByCity";
    let requestParams = new Map();
    requestParams.set("stateCode", stateCode)
    requestParams.set("cityName", cityName)
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the career departments.
   * @param callback fn
   */
  getCareerDepartments(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getCareerDepartments";
    let requestParams = new Map();
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /** 
   * It submits the career details.
   * @param formData -contains the data of the form. 
   * @param callback fn
   */
  addCareerDetail(formData, callback: (data) => void) {
    let url = Constants.BASE_URL + "/addCareerDetail";
    let requestParams = new Map();
    this.httpService.post(url, formData, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

}

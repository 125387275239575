<mat-radio-group [disabled]="disabled" [value]="radioValue" (change)="onChangeEvent($event)" [required]="required">
    <mat-radio-button *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled"
        [hidden]="!option.visible">
        <ng-container *ngIf="option.icon && showIconBeforeText">
            <img class="radio-icon" [src]="option.icon">
        </ng-container> {{option.label}}
        <ng-container *ngIf="option.icon && !showIconBeforeText">
            <img class="radio-icon" [src]="option.icon">
        </ng-container>
    </mat-radio-button>
</mat-radio-group>
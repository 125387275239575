import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[dynamicTabContainer]'
})
export class DynamicTabContainerDirective {
  constructor(
    public viewContainerRef : ViewContainerRef
  ){}
}

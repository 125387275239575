export class ContactQueryModel {
    name: string;
    mobileNo: string;
    subject: string;
    desc: string;
    adminComment: string;
    attachments: Array<string>;
    address: string;
    pincode: string;
    queryStatus: number;
    email : string
    addlData: string;
    queryType: number;
}
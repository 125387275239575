import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';
import { ProductFilterModel } from '../common/models/productFilterModel'
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * It fetches data according to the filters applied,category and search results.
 * It fetches data of comparison of products and export pdf.
 * @class CategoryListPageService
 */
@Injectable()
export class CategoryListPageService {

  /**
   *  catMapObservable - observable for category .
   */
  catMapObservable: Observable<Object>;

  /**
   *  catMapBehaviour- behaviour subject for category.
   */
  catMapBehaviour: BehaviorSubject<Object>;
  /**
   * DI of HttpService 
   * @param httpService HttpService
   */
  constructor(public httpService: HttpService) {
    this.catMapBehaviour = <BehaviorSubject<Object>>new BehaviorSubject(new Object());
    this.catMapObservable = this.catMapBehaviour.asObservable();
  }

  /**
 * It fetches the data according to the category and filters applied.  
 * @param {number} reqMode - mode
 * If reqMode - 0, gives both products and count and hasMore.
 * If reqMode - 1, gives only products, doesn't give hasMore.
 * If reqMode - 2, gives only count and hasMore, doesn't give products.
 * @param {ProductFilterModel} productFilterModel - contains filterModel and other info.
 * @param callback fn
 */
  getDataOnCategory(productFilterModel: ProductFilterModel, reqMode, callback: (data) => void) {
    let url = Constants.BASE_URL + "/searchProducts";
    let body;
    let requestParams = new Map();
    productFilterModel.fetchCategoryMeta = true;
    productFilterModel.fetchCategorySiblingsMap = true;
    productFilterModel.fetchProductReviews = true;
    requestParams.set("reqMode", reqMode);
    body = productFilterModel;
    this.httpService.post(url, body, requestParams, (resp) => {
      if (resp.data != undefined) {
        if (resp.data.map != undefined)
          this.catMapBehaviour.next(resp.data.map);
      }
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches filters corresponding to the categoryId.
   * @param {string} categoryUrl - url of the category
   * @param callback fn
   */
  getFilters(categoryUrl, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getFiltersOnCategory";
    let body;
    let requestParams = new Map();
    let productFilterModel = new ProductFilterModel();
    productFilterModel.categoryUrl = categoryUrl;
    body = productFilterModel;
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the search results  according to the keyword type.
   * @param {number} currencyId - id of the currency in which price details to be shown.
   * @param {string} keyword - keyword according to which results are to be fetch.
   * @param {number} pageNum - page number according to which the data is to be fetch. 
   * @param {number} reqMode - 
   * If reqMode - 0, gives both products and count and hasMore.
   * If reqMode - 1, gives only products, doesn't give hasMore.
   * If reqMode - 2, gives only count and hasMore, doesn't give products.
   * @param {} callback fn
   */
  searchResults(currencyId, keyword, pageNum, reqMode, callback: (data) => void) {
    let url = Constants.BASE_URL + "/searchProducts";
    let body;
    let requestParams = new Map();
    requestParams.set("reqMode", reqMode);
    let productFilterModel = new ProductFilterModel();
    productFilterModel.pageNum = pageNum;
    productFilterModel.numRecords = Constants.CATEGORY_PAGE_NUM_RECORDS;
    productFilterModel.currencyId = currencyId;
    productFilterModel.isCategorySearch = false;
    productFilterModel.keyword = keyword;
    body = productFilterModel;
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches collection details based on url.
   * @param {number} pageNo - page number.
   * @param {number} numRec - number of records.
   * @param collectionUrl - url of the collection.
   * @param {number} currencyId - id of the currency.
   * @param callback fn
   */
  fetchCollectionDetailsOnUrl(pageNo, numRec, collectionUrl, currencyId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/fetchCollectionDetailsOnUrl";
    let requestParams = new Map();
    requestParams.set('numRec', Constants.CATEGORY_PAGE_NUM_RECORDS);
    requestParams.set('pageNo', pageNo)
    requestParams.set('collectionUrl', collectionUrl);
    requestParams.set('currencyId', currencyId);
    // requestParams.set('companyId', Constants.VENDOR_DETAILS.companyId);
    this.httpService.get(url, requestParams, resp => {
      callback(resp);
    })
  }

  /**
   * It export the pdf of the comparison of products  
   * @param {Array<number>} titleIds - title id's of the product to be compared.
   * @param {boolean} exportPdfs -to export pdf or not. 
   * @param callback fn
   */
  printCompareProducts(titleIds: Array<number>, exportPdfs: boolean, callback: (data) => void) {
    let url = Constants.BASE_URL + "/compareProducts"
    let body
    let requestParams = new Map()
    requestParams.set("titleIds", titleIds)
    requestParams.set("exportPdf", exportPdfs)
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * this methods fetches the list of product of a specific brand.
   * @param currencyId currencyId
   * @param brandUrl - brand's url
   * @param pageNum page number 
   * @param numRecords number of records to fetch 
   * @param divisionId division id 
   * @param callback fn 
   */
  getProductsByBrand(currencyId, brandUrl, pageNum, numRecords, divisionId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/getProductsByBrandUrl";
    let body;
    let requestParams = new Map();
    let productFilterModel = new ProductFilterModel();
    productFilterModel.brandUrl = brandUrl;
    productFilterModel.pageNum = pageNum;
    productFilterModel.numRecords = numRecords;
    productFilterModel.currencyId = currencyId;
    productFilterModel.divisionId = divisionId;
    productFilterModel.fetchCategoryMeta = true;
    productFilterModel.fetchCategorySiblingsMap = true;
    productFilterModel.fetchProductReviews = true;
    body = productFilterModel;
    this.httpService.post(url, body, requestParams, (resp) => {
      if (resp.data != undefined) {
        if (resp.data.map != undefined)
          this.catMapBehaviour.next(resp.data.map);
      }
      callback(resp);
    }, (error) => {
      console.log(error);
    });
  }

}

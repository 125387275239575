
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



/**
 * Fetchs the options
 */
@Injectable()
export class DataFetchService {
    /**
     * Constructor
     * @param _http 
     */
    constructor(private _http: HttpClient) { }
    /**
     * Send's the GET API request for fetching API
     * @param url 
     */
    public getJSONResponse(url: string) {
        return this._http.get(url).pipe(catchError(this._errorHandler));
    }
    /**
     * Send's the POST API request for fetching API
     * @param url 
     * @param body 
     */
    public postJSONResponse(url: string, body: any) {
        return this._http.post(url, body).pipe(catchError(this._errorHandler));
    }
    /**
     * Displays the error for response failure
     * @param error 
     */
    _errorHandler(error: Response) {
        // console.log(error);
        return observableThrowError(error || "Server error");
    }

}

export class BlogModel{
    mobilUrl:string
    author:string
    blogContent:string
    blogId:number
    blogImage:string
    blogTitle:string
    blogUrl:string
    creationDate:Date
    description:string
    keyword:string
    siteId:number
    storeId:number
    tags:string
    fromWebsite:boolean
    sortBy:number
    sortingField:string
}

import {
  map, startWith
} from 'rxjs/operators';
import { Component, OnInit, forwardRef, Output, EventEmitter, Input, ViewChildren, QueryList, ComponentFactoryResolver, Type, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';


import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { DataFetchService } from '../data-fetch.service';
import { SuggestionOption } from '../suggestion-option';
import { Suggestion } from '../suggestion';
import { SuggestionOptionHostDirective } from '../suggestion-option-host.directive';
/**
 * Displays the Suggestion field.
 */
@Component({
  selector: 'ls-suggestion-field',
  //changeDetection : ChangeDetectionStrategy.OnPush,
  templateUrl: './suggestion-field.component.html',
  styleUrls: ['./suggestion-field.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SuggestionFieldComponent),
    multi: true
  }]
})
export class SuggestionFieldComponent implements OnInit, AfterViewInit {
  /**
   * HTTP request reponse
   */
  _response;
  /**
   * HTTP request error
   */
  _errorMsg;
  /**
   * Placeholder of the input field
   */
  @Input('placeholder') placeholder;
  /**
   * Color of the input field
   */
  @Input('underline-color') underlineColor;
  /**
   * Base URL for sending the fetch request
   */
  @Input('base-url') baseurl;
  /**
   * Search Param Name
   */
  @Input('searchon-param-name') searchOnName;
  /**
   * Search Param Value
   */
  @Input('searchon-param-value') searchOnValue;
  /**
   * Current Search param Name
   */
  @Input('current-param-name') currentName;
  /**
 * 1 - get
 * 2 - post
 */
  @Input('request-type') requestType: number = 1;
  /**
   * Options List
   */
  dataList;
  /**
   * Event emitter for option seletion
   */
  @Output('selectOption') change = new EventEmitter<number>();
  /**
   * Container Reference for displaying options
   */
  @Input('container') container: Type<any>;
  /**
   * Form reference for text field
   */
  autocompleteForm: UntypedFormControl;
  /**
   * List of the options Observable
   */
  optionList: Observable<any[]>;
  /**
   * Input field text value
   */
  inputTextValue: any;
  /**
   * Rest api end point URL
   */
  _fetchurl: string;
  /**
   * Current String to find
   */
  current: string;
  /**
   * List of display element for options to display.
   */
  @ViewChildren(SuggestionOptionHostDirective) suggestionOptions: QueryList<SuggestionOptionHostDirective>;
  /**
   * List data options
   */
  items = [];
  /**
   * Constructor
   * @param _dataFetchService 
   * @param componentFactoryResolver 
   */
  constructor(private _dataFetchService: DataFetchService, private componentFactoryResolver: ComponentFactoryResolver) { }

  /**
   * On the basis of request type sends the BE request for fetching results
   */
  ngOnInit() {
    if (this.requestType == 1) {
      this._fetchurl = this.baseurl + '?' + this.searchOnName + '=' + this.searchOnValue + '&' + this.currentName + '=';
      this._dataFetchService.getJSONResponse(this._fetchurl).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.initAutoComplete(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);
      this.autocompleteForm = new UntypedFormControl();
    } else if (this.requestType == 2) {
      let body;
      this._fetchurl = this.baseurl + '?' + this.searchOnName + '=' + this.searchOnValue;
      this._dataFetchService.postJSONResponse(this._fetchurl, body).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.initAutoComplete(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);
      this.autocompleteForm = new UntypedFormControl();
    }
  }

  /**
   * Builds options list on update.
   * @param data 
   */
  initAutoComplete(data) {
    this.dataList = data;
    this.optionList = this.autocompleteForm.valueChanges.pipe(startWith(null),
      map(option => option ? this.dataList.slice() : this.dataList.slice()));

  }

  /**
   * FormControl Binding
   * @param value 
   */
  writeValue(value: number) {
    if (value !== undefined) {
      for (let i = 0; i < this.dataList.length; i++)
        if (value == i)
          this.inputTextValue = this.dataList[i].value;
    }
  }
  /**
   * Formcontrol Binding
   */
  propagateChange = (_: any) => { };

  /**
   * Formcontrol Binding
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  /**
   * Formcontrol Binding
   */
  registerOnTouched() { }

  /**
   * Display's options on text changes and emit the changes.
   * @param event 
   */
  onChange(event) {
    let index: number = -1;
    for (let i = 0; i < this.dataList.length; i++) {
      if (this.dataList[i].value === event.option.value)
        index = i;
    }
    this.propagateChange(this.dataList[index]);
    this.change.emit(this.dataList[index]);
    this.items.push(this.dataList[index].value);
  }
  /**
   * Intialize the option component and display options label. 
   */
  initComponents() {
    if (this.suggestionOptions === undefined || this.suggestionOptions.length <= 0)
      return;
    let suggCompList = this.suggestionOptions.toArray();
    for (let i = 0; i < this.dataList.length; i++) {
      if (suggCompList[i] === undefined)
        continue;
      let factory = this.componentFactoryResolver.resolveComponentFactory(this.container);
      let viewContainerRef = suggCompList[i].viewContainerRef;
      viewContainerRef.clear();
      let componentRef = viewContainerRef.createComponent(factory);
      (<SuggestionOption>componentRef.instance).buildComponent(this.dataList[i]);
    }
  }
  /**
   * Refetches the options on text changes.
   * @param event 
   */
  reFetch(event) {
    if (this.requestType == 1) {
      this.reFetchGet(event);
    } else {
      this.reFetchPost(event);
    }
  }
  /**
   * Refetch for get type api
   */
  reFetchGet(event) {
    this._fetchurl = this.baseurl + '?' + this.searchOnName + '=' + this.searchOnValue + '&' + this.currentName + '=' + this.current;
    this._dataFetchService.getJSONResponse(this._fetchurl).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.initAutoComplete(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);
  }

  /**
   * Refetch for post type api
   */
  reFetchPost(event) {
    this._fetchurl = this.baseurl + '?' + this.searchOnName + '=' + this.searchOnValue;
    let body = {
      label: this.current
    }
    this._dataFetchService.postJSONResponse(this._fetchurl, body).subscribe(responseEmployeeData => { this._response = responseEmployeeData; this.initAutoComplete(responseEmployeeData); return this._response }, errorMessage => this._errorMsg = errorMessage);
  }
  /**
   * @ignore
   */
  ngAfterViewInit() {
    this.suggestionOptions.changes.subscribe(() => {
      this.initComponents();
    });
  }
  /**
   * Removes options from the options list.
   */
  remove(item) {
    let index = this.items.indexOf(item);
    if (index >= 0)
      this.items.splice(index, 1);
  }

}

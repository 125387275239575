import { OnlineStoreModel } from '../models/online-store-model';
import { StaticPageModel } from '../models/static-page-model';
import { OnlineStoreSettingModel } from '../models/onlineStoreSettingModel';
import { CartSettingsModel } from '../models/cartSettingsModel';
import { SocialSettingsModel } from '../models/social-settings-model';
import { SEOSettingsModel } from '../models/seoSettingsModel';
import { CategoryModel } from '../models/category-model';
import { PaymentSettingsModel } from '../models/paymentSettingsModel';
import { CompanyModel } from '../models/company-model';

/**
 * global constants
 */
export class Constants {
    public static ALL_STORES_DATA;
    public static ASSETS_PATH = 'assets/images';
    public static TIME_OUT: number;
    public static SITE_ID: number;
    public static STORE_ID: number;
    public static STORE_TYPE: number = 0;
    public static COMPANY_ID: number = 0;
    public static IS_MULTI_STORE: boolean;
    public static BASE_URL: string = ''
    public static WAR_NAME: string;
    public static STORES: Array<OnlineStoreModel> = [];
    public static COMPANY_DETAILS: CompanyModel;
    public static VENDOR_DETAILS: OnlineStoreModel;
    public static CART_SETTINGS: CartSettingsModel;
    public static SOCIAL_SETTINGS: SocialSettingsModel;
    public static SEO_SETTINGS: SEOSettingsModel;
    public static PAYMENT_SETTINGS: PaymentSettingsModel;
    public static ONLINE_STORE_SETTING_MODEL: OnlineStoreSettingModel;
    public static QUICK_LINKS: Array<StaticPageModel>;
    public static DEFAULT_SHIPPING_DAYS = 5;
    public static PRODUCT_SHIPPING_DAYS_ADDITION: number = 2;
    public static BUY_MODE: number = 1;
    public static ENQUIRY_MODE: number = 2;
    public static BUY_AND_ENQUIRY_MODE: number = 3;
    public static FB_HASH_VALUE = 1;
    public static GOOGLE_HASH_VALUE = 2;
    public static LINKEDIN_HASH_VALUE = 3;
    public static TWITTER_HASH_VALUE = 4;
    public static YOUTUBE_HASH_VALUE = 5;
    public static INSTAGRAM_HASH_VALUE = 6;
    public static PINTEREST_HASH_VALUE = 7;
    public static LOGIN_COOKIE = "UserId";
    public static INVALID_TOKEN = "Not an authenticated user";
    public static MENU_BAR_NAV: any;
    public static CATEGORY_MAP: Array<CategoryModel>
    public static CATEGORY_PAGE_NUM_RECORDS = 28;
    public static CND_URL: string;
    public static CND_UPLOAD: string;
    public static CND_DOWNLOAD: string;
    public static POPUP_ACTION=false;
    public static collectionProductLinkType = class {
        public static PRODUCT = 0
        public static OFFER = 1
        public static VIDEO = 2
    }
    public static POPUP_MSGS = class {
        public static REVIEW_SUCCESSFUL_UPDATED = "your review has been submitted for consideration"
        public static REVIEW_UPDATION_UNSUCCESSFUL = "There was some error in review submission"
        public static ITEM_ADDED_TO_CART = "Added to your Wish List"
        public static ITEM_ADDED_TO_CART_UNSUCESSFUL = "Alreay Added to Shop Wish List"
    }
    public static FUN_FACTS = class {
        public static FUN_FACT_1 = "Bamboo trees emits 30% more O\u2082  into the atmosphere as compared to other equivalent mass of trees"
        public static FUN_FACT_2 = "Bamboo is Anti-bacterial & Anti-fungal, perfect for clothing for babies  or people with sensitive skin"
        public static FUN_FACT_3 = "Bamboo grows without or minimal use of pesticides unlike cotton"
        public static FUN_FACT_4 = "Bamboo fiber soaks 4-5 times more water than cotton and dries faster too"
    }
    public static GENDER = class {
        public static MALE = 0
        public static FEMALE = 1
    }

    public static UserType = class {
        public static registered = 1;
        public static guest = 2;
        public static channelPartner = 3;
        public static dealer = 4;
    }

    public static LOGIN_MODE = class {
        public static DEFAULT = 0;
        public static VIA_PHONE = 1;
        public static OAUTH = 2;
    }

    public static ORDER_STATUS = class {
        public static RECEIVED = 0;
        public static CONFIRMED = 1;
        public static PARTIAL_PICKED = 2;
        public static PICKED = 3;
        public static PARTIAL_PACKED = 4;
        public static PACKED = 5;
        public static PARTIAL_DISPATCHED = 6;
        public static DISPATCHED = 7;
        public static PARTIAL_COMPLETED = 8;
        public static COMPLETED = 9;
        public static PARTIAL_CANCELLED = 10;
        public static CANCELLED = 11;
        public static PARTIAL_CANCEL_REFUND = 12;
        public static CANCEL_REFUND = 13;
        public static PARTIAL_REFUND_REQUEST = 14;
        public static REFUND_REQUEST = 15;
    }

    public static DISPLAY_PAGE_AFTER_CHECKOUT = class {
        public static CART = 1;
        public static CHECKOUT = 2;
    }

    public static handleURLValues(str: String): String {
        let value: String = "";
        if (str == undefined)
            return value;
        value = str.replace("null", "").replace(" ", "-").trim().toLowerCase();
        return value;
    }

    public static SERVICE_SUPPORT_KIND_OF_OPTIONS = class {
        public static DEMO_REQUEST = 1;
        public static SERVICE_REQUEST = 2;
    }

    public static GET_CATEGORY_MODE = class {
        public static ALL = 0
        public static PARENT_ONLY = 1
        public static CHILD_ONLY = 2
    }

    public static RESOURCE_TYPE = class {
        public static IMAGES = 1
        public static VIDEOS = 2
    }

    public static RESOURCE_CATEGORY = class {
        public static SHOW_ON_STORE = 1
        public static SHOW_ON_COLLECTION = 2
    }

    public static LOGIN_AFTER_ACTION = class {
        public static ADD_TO_WISHLIST = 1
        public static WRITE_REVIEW = 2
        public static ADD_TO_CART = 3
    }

    public static COLOR_MAP: Array<{ colorCode: any, colorName: any }> = []

    public static SURVEY_QUESTIONS_TYPE = class {
        public static TEXT_FIELD = 1
        public static TEXT_AREA = 2
        public static RADIO = 3
        public static CHECKBOX = 4
        public static COMBO = 5
        public static IMAGE = 6
        public static RATING = 7
        public static RADIO_TEXT = 8
    }

    public static CART_WISHLIST_TYPE = class {
        public static CART = 1
        public static WISHLIST = 2
    }

    public static ACTION_TYPES = class {
        public static STANDARD = 0;
        public static FREE_PRODUCTS = 1;
    }

    public static CONDITION_TYPES = class {
        public static CART = 1;
        public static PRODUCT_COLLECTION = 2;
        public static CATEGORY = 3;
        public static BRAND = 4;
        public static PRODUCT = 5;
        public static ORDER = 6;
        public static USER = 7;
        public static SURVEY = 8;
    }

    public static OFFER_TYPE = class {
        public static OFFER = 1;
        public static COUPON = 2;
        public static GIFT_CARD = 3;
    }

    public static DEFAULT_COLLECTION_TYPE = class {
        public static dealOfTheDay = 1;
        public static trendingProducts = 2;
        public static recentlyViewed = 3;
        public static recentlySold = 4;
        public static browseByAuthor = 5;
        public static relatedProducts = 6;
        public static newArrivals = 7;
        public static bestSellers = 8;
    }

    public static GIFT_WRAPPING_TYPE = class {
        public static ENABLE = 1;
        public static DISABLE = 0;
    }

    public static BANNER_TYPE = class {
        public static BANNER = 1;
        public static OFFER = 2;
    }

    public static SEARCH_PRODUCTS_REQ_MODE = class {
        public static BOTH = 0;
        public static ONLY_PRODUCTS = 1;
        public static ONLY_COUNT = 2;
    }

    public static RETURN_TYPE = class {
        public static REFUND = 1;
        public static REPLACEMENT = 2;
    }

    public static ORDER_FROM_TYPE = class {
        public static WEB = 1;
        public static MOBILE = 2;
        public static PRE_ORDER = 3;
        public static FLIPKART = 4;
        public static POS = 5;
        public static AMAZON = 6;
        public static ENQUIRY = 7;
    }

    public static PAYMENT_OPTION_TYPE = class {
        public static ONLINE_CARD = 1;
        public static COD = 2;
        public static WALLET = 3;
    }

    public static PRODUCT_DETAIL_MODE = class {
        public static LIST = 1;
        public static DETAIL = 2;
    }

    public static REGX = class {
        public static emailRegex = new RegExp('^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+[.]{1}[a-zA-Z0-9]+$');
        public static mobileRegex = new RegExp(/^([+]\d{2})?\d{10}$/);
        public static pincodeRegex = '^[1-9][0-9]{5}$';
    }
    public static ULR_FORMATTER = class {
        public static COLLECTION = 1;
    }

}
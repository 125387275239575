import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';
import { StoreUserModel } from '../common/models/storeUserModel';

/**
 * It fetches and updates the user information.
 * It fetches the state list. 
 * @class GetUserInfoService
 */
@Injectable()
export class GetUserInfoService {
  /**
   * List of  DI
   * @param httpService Di
   */
  constructor(public httpService: HttpService) { }

  /**
   * It fetches the user information corresponding to the email id of the user.
   * @param emailId - email id of the user.
   * @param callback fn
   */
  getUserInfo(emailId, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/getUserInfo";
    let requestParams = new Map();
    // requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId)
    requestParams.set("userEmailId", emailId)
    let body
    this.httpService.post(url, body, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It fetches the list of the states.
   * @param callback fn
   */
  getStateList(callback: (data) => void) {
    let url = Constants.BASE_URL + "/getStateList";
    let requestParams = new Map();
    // requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId)
    requestParams.set("countryId", 1)
    let body
    this.httpService.get(url, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

  /**
   * It edits the user information.
   * @param storeUserModel StoreUserModel
   * @param callback fn
   */
  editUserInfo(storeUserModel, callback: (data) => void) {
    let url = Constants.BASE_URL + "/api/editUserInfo";
    let requestParams = new Map();
    // requestParams.set("companyId", Constants.VENDOR_DETAILS.companyId)
    // let body = JSON.parse(storeUserModel);
    let usermodel: StoreUserModel
    usermodel = storeUserModel;
    this.httpService.post(url, usermodel, requestParams, (resp) => {
      callback(resp);
    }, (error) => {
      console.log(error);
    })
  }

}

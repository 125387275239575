import { Component, OnInit, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
/**
 * This component is a wrapper for the material based rating field.
 */
@Component({
  selector: 'ls-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RatingComponent),
    multi: true
  }]
})
export class RatingComponent implements OnInit, ControlValueAccessor {
  /**
   * max rating count
   */
  @Input('max-rating') maxRating: number;
  /**
   * rating value given by user
   */
  @Input('rating-value') ratingValue: number;
  /**
   * color of the rating stars
   */
  @Input('color') color: string;
  /**
   * show label on rating
   */
  @Input('show-label') showLabel: boolean;
  /**
   * is rating field editable
   */
  @Input('editable') isEditable: boolean;
  /**
   * emit event on rating value change
   */
  @Output('changeRating') change = new EventEmitter<any>();
  /**
   * list of rating array
   */
  ratingArr: any[];
  /**
   * current value of the rating at a particular time
   */
  ratingCurrentVal;
  /**
   * The constructor
   */
  constructor() {
    this.maxRating = 5;
  }
  /**
   * Write the rating value
   */
  writeValue(value: any) {
    if (value !== undefined) {
      this.ratingValue = value;
      this.calcRatingArray(this.ratingValue);
    }
  }
  /**
   * propage value changes
   */
  propagateChange = (_: any) => { };
  /**
   * regiser on value change
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  /**
   * register on value touch
   */
  registerOnTouched() { }
  /**
   * @ignore
   */
  ngOnInit() {
    if (this.ratingValue == undefined) {
      this.ratingValue = 0;
    }
    this.calcRatingArray(this.ratingValue);
  }
  /**
   * calculate the rating
   */
  calcRatingArray(n: number): void {
    this.ratingCurrentVal = n;
    this.ratingArr = [];
    let i = 0;
    while (n > 0) {
      this.ratingArr.push((n > 0.5) ? "star" : "star_half");
      n = n - 1;
      i++;
    }
    while (i < this.maxRating) {
      this.ratingArr.push("star_border");
      i++;
    }


  }
  /**
   * trigger when user hover on the rating
   */
  onMouseOver(event) {
    
    if (event.target.nodeName == "I") {
      let rating = event.target.getAttribute("star");
      if(((event.clientX - event.target.getBoundingClientRect().left) / event.target.clientWidth) < 0.5){
        rating = rating - 0.5
      }
      this.calcRatingArray(rating);
    }
    event.preventDefault();
  }
  /**
   * trigger when user leave the rating
   */
  onMouseOut(event) {
    if (event.target.nodeName == "DIV") {
      this.calcRatingArray(this.ratingValue);
    }
    event.preventDefault();
  }
  /**
   * On rating click
   */
  onClick(event) {
    this.ratingValue = this.ratingCurrentVal
    this.calcRatingArray(this.ratingValue);
    this.propagateChange(this.ratingValue);
    this.change.emit(this.ratingValue);
  }

}

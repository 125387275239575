export class ReviewModel {
    storeId: number;
    titleId: number;
    productVariantId: number;
    packageId: number;
    userId: number;
    reviewId: number;
    reviewTitle: string;
    reviewText: string;
    productRating: number;
    rating1: number;
    rating2: number;
    rating3: number;
    rating4: number;
    rating5: number;
    rating6: number;
    createDate: Date;
    username: string;
}
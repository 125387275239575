import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './http.service'
import { ProductModel } from '../common/models/productModel'
import { Observable, BehaviorSubject } from 'rxjs';
/**
 * CompareProduct class
 */
export class CompareProduct {
  /**
   * event
   */
  event: boolean;
  /**
   * titleId
   */
  titleId: number;
  /**
   * productModel
   */
  productModel: ProductModel
}

/**
 * it is used to store list of products to compare
 * @deprecated not in used
 * 
 */
@Injectable()
export class AddToCartWishlistService {
  /**
   * compProList list CompareProduct
   */
  compProList: Observable<Array<CompareProduct>>;
  /**
   * BS of list products to compare 
   */
  private compProListBehaviourSubject: BehaviorSubject<Array<CompareProduct>>;
  /**
   * List of DI
   * @param platformId platformId 
   * @param httpService httpService 
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public httpService: HttpService
  ) {
    this.compProListBehaviourSubject = <BehaviorSubject<Array<CompareProduct>>>new BehaviorSubject(new Array());
    this.compProList = this.compProListBehaviourSubject.asObservable()
  }

}

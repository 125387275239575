import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RoutingModule } from './routing/app.routing.module';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { AgmCoreModule } from '@agm/core'
import { AppComponent } from './app.component';
import { ScrollUpComponent } from './published/commonComponent/scroll-up/scroll-up.component';
import { SocialShareComponent } from './published/commonComponent/social-share/social-share.component';
import { CustomBreakpointsModule } from './custom.breakpoints';
import { CartComponent } from './published/components/cart/cart.component';
import { LoginComponent } from './published/components/login/login.component';
import { CollectionsComponent } from './published/components/collections/collections.component';
import { SearchComponent } from './published/components/search/search.component';
import { DetailsComponent } from './published/components/details/details.component';
// import { OnlineStoreComponent } from './published/components/online-store/online-store.component';
import { ContactUsComponent } from './published/components/contact-us/contact-us.component';
import { ForgotPasswordComponent } from './published/components/forgot-password/forgot-password.component';
import { TrackOrderComponent } from './published/components/track-order/track-order.component';
import { OrderConfirmedComponent } from './published/components/order-confirmed/order-confirmed.component';
import { OrderCancelledComponent } from './published/components/order-cancelled/order-cancelled.component';
import { UnsubscribeNewsLetterComponent } from './published/components/unsubscribe-news-letter/unsubscribe-news-letter.component';
import { StoreLocatorComponent } from './published/components/store-locator/store-locator.component';
import { PartnersComponent } from './published/components/partners/partners.component';
import { MediaGalleryComponent } from './published/components/media-gallery/media-gallery.component';
import { BuyingGuideComponent } from './published/components/buying-guide/buying-guide.component';
import { PremiumDealerComponent } from './published/components/premium-dealer/premium-dealer.component';
import { MapDealerComponent } from './published/components/map-dealer/map-dealer.component';
import { DistributorComponent } from './published/components/distributor/distributor.component';
import { CareerComponent } from './published/components/career/career.component';
import { ServiceSupportComponent } from './published/components/service-support/service-support.component';
import { CompareComponent } from './published/components/compare/compare.component';
import { ProductSetComponent } from './published/components/product-set/product-set.component';
import { DivisionComponent } from './published/components/division/division.component';
import { EnquiryComponent } from './published/components/enquiry/enquiry.component';
import { HeaderComponent } from './published/commonComponent/header/header.component';
import { FooterComponent } from './published/commonComponent/footer/footer.component';
import { OkayMessageComponent } from './published/commonComponent/okay-message/okay-message.component';
import { ProductImgDialogComponent } from './published/components/details/product-img-dialog/product-img-dialog.component';
import { QueryComponent } from './published/commonComponent/query/query.component';
import { YesNoPopupComponent } from './published/commonComponent/yes-no-popup/yes-no-popup.component';
import { StaticPageComponent } from './published/components/static-page/static-page.component';
import { ProductReviewComponent } from './published/components/product-review/product-review.component';
import { OrderTrackingComponent } from './published/components/order-tracking/order-tracking.component';
import { LoaderComponent } from './published/commonComponent/loader/loader.component';
import { StartUpService } from './services/start-up.service';
import { LoaderService } from './services/loader.service';
import { HttpService } from './services/http.service';
import { LoginService } from './services/login.service';
import { UserService } from './services/user.service';
import { CategoryService } from './services/category.service';
import { ContactService } from './services/contact.service';
import { LsDialogService } from './LSNG/components/ls-dialog/ls-dialog.service';
import { ProductDetailsService } from './services/product-details.service';
import { GetReviewListService } from './services/get-review-list.service';
import { TrackOrderService } from './services/track-order.service';
import { EnquireService } from './services/enquire.service';
import { AddToCartWishlistService } from './services/addToCartWishlist.service';
import { CategoryListPageService } from './services/categoryListPage.service';
import { SuggestionOptionComponent } from './published/components/search/suggestion-option.component';
import { SearchTagComponent } from './published/components/search/suggestion-tag.component';
import { DashboardOptionComponent } from './published/commonComponent/mobile-dashboard-options/dashboard-options.component';
import { EnquiryMessageComponent } from './published/components/enquiry-message/enquiry-message.component';
import { BreadcrumbsComponent } from './published/commonComponent/breadcrumbs/breadcrumbs.component';
import { FiltersComponent } from './published/components/filters/filters.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { CartCountService } from '../app/services/cart-count.service';
import { SeoService } from './services/seo.service';
import { Constants } from './common/constants/lsnetx.constants';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { GiftWrapComponent } from './published/payment/gift-wrap/gift-wrap.component';
// import { GiftWrapComponent } from './published/commonComponent/gift-wrap/gift-wrap.component';
import { MasterComponent } from './published/commonComponent/master/master.component';
import { CustomerSurveyService } from './services/customerSurvey.service';
import { CartService } from './services/cart.service';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { WINDOW_PROVIDERS } from './services/window.service';
import { GetUserInfoService } from './services/getUserInfo.service';
// import { PdfViewerModule } from 'ng2-pdf-viewer';

//for temp-002
import { ChannelPartnerComponent } from './published/components/channel-partner/channel-partner.component'
import { BrandsComponent } from './published/components/brands/brands.component'
import { MediaGalleryDialogComponent } from './published/components/media-gallery/media-gallery-dialog/media-gallery-dialog.component'
import { LoginPopupComponent } from './published/commonComponent/login-popup/login-popup.component'

//for temp-001
import { CustomerSurveyComponent } from './published/commonComponent/customer-survey/customer-survey.component'
import { LsLazyLoaderDirective } from './LSNG/directives/ls-lazy-loader/ls-lazy-loader.directive';
import { OrdinalPipe } from './LSNG/commons/ordinal.pipe';
import { DetailGuard } from './services/detail.guard';
import { LoginAuthService } from './services/login-auth.service';
import { HomePageService } from './services/home-page.service';
import { FeaturesModule } from './published/features/features.module';
import { ICONARRAY } from './published/common-data/icon.array';
import { CustomHttpClient } from './services/custom-http.service';
import { LoginRedirectService } from './services/login-redirect.service';
import { PaymentService } from './services/payment.service';
import { DialogRef } from './published/common-data/dialog-ref';
import { GetloginService } from './services/getlogin.service';

/**
 * Startup Service Factory 
 */
export function startUpServiceFactory(_startUpService: StartUpService) {
  return () => _startUpService.load();
}
/**
 * DEFAULT_PERFECT_SCROLLBAR_CONFIG
 */
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
}
/**
 * HammerGestureConfig
 */
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  /**
   * overrides Gesture swipe velocity
   */
  overrides = <any>{
    'swipe': { velocity: 0.4 }
  }
}
/**
 * Get Social Auth config 
 */
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.fbAppId) ///
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.googleJSONFile)
      },
    ]);
  return config;
}

@NgModule({
    declarations: [
        AppComponent,
        ScrollUpComponent,
        SocialShareComponent,
        CartComponent,
        LoginComponent,
        CollectionsComponent,
        SearchComponent,
        DetailsComponent,
        // OnlineStoreComponent,
        ContactUsComponent,
        ForgotPasswordComponent,
        TrackOrderComponent,
        OrderConfirmedComponent,
        OrderCancelledComponent,
        UnsubscribeNewsLetterComponent,
        StoreLocatorComponent,
        PartnersComponent,
        MediaGalleryComponent,
        BuyingGuideComponent,
        PremiumDealerComponent,
        MapDealerComponent,
        DistributorComponent,
        CareerComponent,
        ServiceSupportComponent,
        CompareComponent,
        ProductSetComponent,
        DivisionComponent,
        EnquiryComponent,
        HeaderComponent,
        FooterComponent,
        SuggestionOptionComponent,
        SearchTagComponent,
        StaticPageComponent,
        ProductReviewComponent,
        OkayMessageComponent,
        YesNoPopupComponent,
        ProductReviewComponent,
        DashboardOptionComponent,
        OrderTrackingComponent,
        QueryComponent,
        EnquiryMessageComponent,
        LoaderComponent,
        ProductImgDialogComponent,
        BreadcrumbsComponent,
        FiltersComponent,
        BreadcrumbsComponent,
        MasterComponent,
        //for temp -002
        ChannelPartnerComponent,
        BrandsComponent,
        MediaGalleryDialogComponent,
        LoginPopupComponent,
        //for temp- 001
        CustomerSurveyComponent,
        LsLazyLoaderDirective,
        OrdinalPipe,
        GiftWrapComponent
    ],
    imports: [
        // PdfViewerModule,
        BrowserModule.withServerTransition({ appId: 'lsnetx' }),
        FeaturesModule,
        RoutingModule,
        CustomBreakpointsModule,
        BrowserAnimationsModule,
        SocialLoginModule,
        PerfectScrollbarModule,
        MatSidenavModule,
        SharedModuleModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD84yPRhJz_NjTWIznLRrgh3Yp9idPCy5Y',
            libraries: ['places']
        }),
    ],
    providers: [
        StartUpService,
        {
            provide: APP_INITIALIZER,
            useFactory: startUpServiceFactory,
            deps: [StartUpService],
            multi: true
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        HttpService,
        ContactService,
        CategoryService,
        ProductDetailsService,
        GetReviewListService,
        LsDialogService,
        LoginService,
        UserService,
        AddToCartWishlistService,
        TrackOrderService,
        EnquireService,
        CartCountService,
        LoaderService,
        SeoService,
        DetailGuard,
        AuthGuardService,
        AuthService,
        CategoryListPageService,
        CustomHttpClient,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        },
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs
        },
        WINDOW_PROVIDERS,
        GetUserInfoService,
        CustomerSurveyService,
        CartService,
        LoginAuthService,
        HomePageService,
        LoginRedirectService,
        PaymentService,
        DialogRef,
        GetloginService
    ],
    bootstrap: [AppComponent],
    exports: [GiftWrapComponent]
})



export class AppModule {
  /**
   * svg icons 
   */
  icons = ICONARRAY
  /**
   * list of DI
   * @param platformId PLATFORM_ID
   * @param matIconRegistry MatIconRegistry
   * @param domSanatizer DomSanitizer
   * @param appId APP_ID
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private matIconRegistry: MatIconRegistry,
    private domSanatizer: DomSanitizer,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'on the server' : 'in the browser';
    console.log(`Running ${platform} with appId=${appId}`);
    if (this.icons) {
      this.icons.forEach(icon => {
        this.matIconRegistry.addSvgIcon(
          icon.name,
          this.domSanatizer.bypassSecurityTrustResourceUrl(icon.url)
        )
      })
    }
  }

}
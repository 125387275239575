import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeStyle, SafeScript,SafeUrl, SafeResourceUrl} from '@angular/platform-browser';

/**
 * pipe for adding HTML-template in DOM safely.
 */
@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  /**
   * @constructor
   * @param sanitizer - DOM sanitizer 
   */
  constructor(private sanitizer:DomSanitizer){}

  /**
   * @param value - HTML template
   * @param args - none 
   */
  transform(value: any, args?: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  
}

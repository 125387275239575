import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RteComponent } from './rte/rte.component';
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [RteComponent],
  exports: [RteComponent]
})
export class LsRteModule { }

import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Router } from '@angular/router';
/**
 * Custom HttpClient to break Circular Dependecy with router 
 */
@Injectable()
export class CustomHttpClient extends HttpClient {
  /**
   * List of DI
   * @param injector Injector
   * @param httpHandler httpHandler
   */
  constructor(
    private injector: Injector,
    private httpHandler: HttpHandler
  ) {
    super(httpHandler);
  }
  /**
   * get router instance 
   */
  public get router(): Router {
    return this.injector.get(Router)
  }

}

import { Directive, HostListener, EventEmitter, Output  } from '@angular/core';

@Directive({
  selector: '[LsDragEnter]'
})
export class LsDragEnterDirective {

  @Output() dragEnterEvent = new EventEmitter<any>();

  constructor() { }

  @HostListener("dragenter",['$event']) 
  onDragEnter( event ) {
    this.dragEnterEvent.emit(event);
  }
}

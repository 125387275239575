import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * stores the state of loader.
 * @class LoaderStateModel 
 */
export class LoaderStateModel {
    /**
     *  show - to show or hide loader.
     */
    show: boolean;
}

/**
 * It stores the hide or show state of loader.
 *@class  LoaderService 
 */
@Injectable()
export class LoaderService {

    /**
     *  loaderState - loader observable.
     */
    loaderState: Observable<LoaderStateModel>;

    /**
     *  loaderStateBehaviourSubject - loader behaviour subject.
     */
    private loaderStateBehaviourSubject: BehaviorSubject<LoaderStateModel>;
    /**
     * Initialization 
     */
    constructor() {
        this.loaderStateBehaviourSubject = <BehaviorSubject<LoaderStateModel>>new BehaviorSubject(new LoaderStateModel());
        this.loaderState = this.loaderStateBehaviourSubject.asObservable();
    }

    /**
     * update loader show to true.
     */
    show() {
        this.loaderStateBehaviourSubject.next(<LoaderStateModel>{ show: true });
    }

    /**
     * update loader show to false.
     */
    hide() {
        this.loaderStateBehaviourSubject.next(<LoaderStateModel>{ show: false });
    }

    /**
     * fetches the behaviour subject value.
     */
    getState() {
        return this.loaderStateBehaviourSubject.getValue();
    }

}


import { UserCartDetailDto } from './userCartDetailDto';

export class UserCartDTO {
    siteId: number;
    storeType: number;
    storeId: number;
    userId: number;
    cartWishListType: number;
    terminalId: number;
    batchId: number;
    storeUserId: number; // offline store adminUserId
    holdSaleName: string;
    cartDetails: Array<UserCartDetailDto>;
    visibility: number;
    cartId: number;
}
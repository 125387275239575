import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Constants } from '../common/constants/lsnetx.constants';
/**
 * This Service Class is used to validate user login 
 */
@Injectable()
export class AuthService {
  /**
   * DI
   * @param httpService httpService
   */
  constructor(
    public httpService: HttpService
  ) { }

  /**
   * Checks if user is logged in or not.
   * @param callback - a callback to run 
   * @method isAuthenticated method
   */
  public isAuthenticated(callback: (data) => void) {
    let url = Constants.BASE_URL + "/checkUserLogin";
    let requestParams = new Map();
    this.httpService.post(url, {}, requestParams, (resp) => {
      callback(resp);
    })
  }

}

import { Component, AfterViewInit, Input, OnDestroy, QueryList, ViewChildren, OnInit, Output, EventEmitter, OnChanges, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations'
import { isPlatformBrowser } from '@angular/common';

/**
 * Model for carousel-element
 */
export class carouselElement {
  imageUrl: string
  title: string
}

/**
 * Model for co-ordinates
 */
export class CoOrdinates {
  xCoOrdinate: number
  yCoOrdinate: number
}

/**
 * constants for all possible swipe directions
 */
export class DIRECTIONS {
  static readonly NO_SWIPE = 'no_swipe'
  static readonly LEFT = 'left'
  static readonly RIGHT = 'right'
  static readonly UP = 'up'
  static readonly DOWN = 'down'

}

/**
 * This component is used for creating carousel.
 */
@Component({
  selector: 'ls-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  animations: [
    trigger('carouselElement', [
      state('in', style({ left: '0' })),
      state('left', style({ left: '-100%' })),
      state('right', style({ left: '100%' })),
      state('out', style({ left: '100%' })),
      transition('* =>  in', [animate('0.5s ease-out')]),
      transition('in =>  left', [animate('0.5s ease-in')]),
      transition('in =>  right', [animate('0.5s ease-in')])
    ])
  ]

})
export class CarouselComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {

  /**
   * height of carousel
   */
  @Input('height') height: string;
  /**
   * width of carousel
   */
  @Input('width') width: string;
  /**
   * time-interval for moving up to next image in carousel 
   */
  @Input('timeout') imageTimeout: number;
  /**
   * list of carousel elements
   */
  @Input('carousel-elements') carouselElements: Array<carouselElement>;
  /**
   * display indicators (little dots to indicate total no. of elements and current element position)
   */
  @Input('show-indicators') showIndicators: boolean;
  /**
   * allow auto-rotation of carousel-elements
   */
  @Input('auto-rotate') autoRotate: boolean = true;
  /**
   * event for when user clicks on carousel element
   */
  @Output('clickAction') clickAction = new EventEmitter<number>()

  /**
   * @ignore
   */
  interval: any;
  /**
   * @ignore
   */
  currentUrl: string;
  /**
   * @ignore
   */
  currentTitle: string;
  /**
   * @ignore
   */
  currentClickAction: string;
  /**
   * current-index maintains current carousel-element
   */
  currentIndex: number = 0;
  /**
   * contains list of indicator-states
   */
  indicators = [];
  /**
   * contains subscription
   */
  private _subscription; 
  /**
   * @ignore
   */
  indicatorsLeftValue: number = 0;

  /**
   * left value for each carousel-element
   */
  lefts: Array<string> = ['0'];
  /**
   * state of each caroursel-element in string (in/out/left/right)
   */
  states: Array<string> = ['in'];

  /**
   * element-reference of list of indicators
   */
  @ViewChildren('indicator') indicatorList;

  /**
   * @constructor
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  /**
   * whenever changes are detected, initialize:
   * 1. left-values,
   * 2. states,
   * 3. indicators and 
   * 4. timer
   */
  ngOnChanges() {
    this.indicators = [];
    if (isPlatformBrowser(this.platformId) && this.carouselElements && this.carouselElements.length > 0) {
      for (let i = 0; i < this.carouselElements.length; i++) {
        this.lefts.push('100%')
        this.states.push('out')
      }
      this._subscription = interval(this.imageTimeout).subscribe(x => this.slideRight());
      for (let i = 0; i < this.carouselElements.length; i++) {
        this.indicators.push(i);
      }
    }
  }

  /**
   * initialize timer for auto-rotation of carousel-elements
   */
  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.carouselElements && this.carouselElements.length > 0) {
      if (this.autoRotate) {
        this._subscription = interval(this.imageTimeout).subscribe(x => this.slideRight());
      }
    }
  }

  /**
   * afterViewInit(), mark first indicator state as selected
   */
  ngAfterViewInit() {
    this.selectFirstIndicator();
  }

  /**
   * unsubcribe subscription of timer when component destroys
   */
  ngOnDestroy() {
    if (this._subscription != undefined) {
      this._subscription.unsubscribe();
    }
  }

  /**
   * for indicator selection, add and remove specific classes to the indicator's element reference
   */
  selectFirstIndicator() {
    if (this.indicatorList && this.indicatorList.length > 0) {
      let indicatorArr = this.indicatorList.toArray();
      indicatorArr[0].nativeElement.classList.remove('unselected');//style.borderWidth = '2px';
      indicatorArr[0].nativeElement.classList.add('selected');
    }
  }  

  /**
   * slides carousel-element to the left by updating left-value and state and
   * also updates indicator-list.
   */
  slideLeft(): void {
    let indicatorArr = this.indicatorList.toArray();
    if (indicatorArr && indicatorArr.length > 0 && indicatorArr[this.currentIndex] && this.showIndicators) {
      indicatorArr[this.currentIndex].nativeElement.classList.remove('selected')//style.borderWidth = '2px';
      indicatorArr[this.currentIndex].nativeElement.classList.add('unselected')
    }
    this.states[this.currentIndex] = 'right'
    this.currentIndex = (this.currentIndex - 1 + this.carouselElements.length) % this.carouselElements.length;
    if (indicatorArr && indicatorArr.length > 0 && indicatorArr[this.currentIndex] && this.showIndicators) {
      indicatorArr[this.currentIndex].nativeElement.classList.remove('unselected')
      indicatorArr[this.currentIndex].nativeElement.classList.add('selected')//style.borderWidth = '10px'; 
    }
    this.slideIn(this.currentIndex, 'left')
  }

  /**
   * slides carousel-element to the right by updating left-value and state and
   * also updates indicator-list.
   */
  slideRight(): void {
    let indicatorArr = this.indicatorList.toArray();
    if (indicatorArr && indicatorArr.length > 0 && indicatorArr[this.currentIndex] && this.showIndicators) {
      indicatorArr[this.currentIndex].nativeElement.classList.remove('selected')//style.borderWidth = '2px';
      indicatorArr[this.currentIndex].nativeElement.classList.add('unselected')
    }
    this.states[this.currentIndex] = 'left'
    this.currentIndex = (this.currentIndex + 1) % this.carouselElements.length;
    if (indicatorArr && indicatorArr.length > 0 && indicatorArr[this.currentIndex] && this.showIndicators) {
      indicatorArr[this.currentIndex].nativeElement.classList.remove('unselected')
      indicatorArr[this.currentIndex].nativeElement.classList.add('selected')//style.borderWidth = '10px';    
    }
    this.slideIn(this.currentIndex, 'right')
  }

  /**
   * If mouse enters the carousel, pause the auto-rotation.
   */
  pauseCarousel(): void {
    if (this._subscription)
      this._subscription.unsubscribe();
  }

  /**
   * If mouse exists out of carousel, again start the time for auto-rotation
   */
  resumeCarousel(): void {
    if (isPlatformBrowser(this.platformId) && this.autoRotate) {
      this._subscription = interval(this.imageTimeout).subscribe(x => this.slideRight());
    }
  }

  /**
   * 
   * @param index - index of caruosel element
   */
  openImage(index): void {
    let indicatorArr = this.indicatorList.toArray();
    if(indicatorArr.length<1 || index< 0 || index> indicatorArr.length-1){
      return;
    }
    indicatorArr[this.currentIndex].nativeElement.classList.remove('selected')//style.borderWidth = '2px';
    indicatorArr[this.currentIndex].nativeElement.classList.add('unselected')

    if (index < this.currentIndex) {
      this.slideIn(index, 'left')
      this.states[this.currentIndex] = 'right'

    } else if (index > this.currentIndex) {
      this.slideIn(index, 'right')
      this.states[this.currentIndex] = 'left'

    }

    this.currentIndex = index;
    indicatorArr[this.currentIndex].nativeElement.classList.remove('unselected')
    indicatorArr[this.currentIndex].nativeElement.classList.add('selected')//style.borderWidth = '10px'; 
    indicatorArr[this.currentIndex].nativeElement.offsetParent.clientWidth
    let childWidth=indicatorArr[0].nativeElement.offsetParent.clientWidth;
    let parentWidth=  indicatorArr[0].nativeElement.offsetParent.offsetParent.clientWidth
    let totalWidth= childWidth * indicatorArr.length;
    let visibleLength = Math.ceil((parentWidth/childWidth));
    if(this.currentIndex ==0 ){
      this.indicatorsLeftValue=0;
    }
    if(this.currentIndex >=visibleLength  && this.indicatorsLeftValue <=0){
      this.indicatorsLeftValue-=childWidth;
    }
    // else if(  visibleLength  < this.currentIndex){
    //   this.indicatorsLeftValue+=childWidth;
    // }
    // if(childWidth * (this.currentIndex+ 1) > parentWidth){
    //   this.indicatorsLeftValue= -1 * ((childWidth * (this.currentIndex + 2)) - parentWidth);
    // }else{
    //   if(childWidth * (this.currentIndex+ 1)< parentWidth){
    //     this.indicatorsLeftValue -=childWidth
    //   }else{
    //   this.indicatorsLeftValue= -1 * ((childWidth * (this.currentIndex + 2)) - parentWidth);
    //   }
    // }
  }

  /**
   * this method maintains left-value and state of carousel-elements based upon direction
   */
  slideIn(index: number, direction: 'left' | 'right'): void {
    if (direction === 'left') {
      this.lefts[index] = '-100%'
      this.states[index] = 'left'
    } else {
      this.lefts[index] = '100%'
      this.states[index] = 'right'
    }
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.states[index] = 'in'
      }, 100)
    }
  }

  /**
   * @ignore
   */
  current: number = 0

  /**
   * emits event when clicked on carousel-element
   */
  handleClick(index: number): void {
    this.clickAction.emit(index)
  }

  /**
   * contains touch-start co-ordinates
   */
  private startCordinates: CoOrdinates = {
    xCoOrdinate: 0,
    yCoOrdinate: 0
  }

  /**
   * contains touch-end co-ordinates
   */
  private endCordinates: CoOrdinates = {
    xCoOrdinate: 0,
    yCoOrdinate: 0
  }

  /**
   * whenever touch is detected on carousel, this function calculated swipe type and handles slide accordingly.
   */
  touchHandler(event: TouchEvent, type: string): void {
    if (type === 'start') {
      this.startCordinates = {
        xCoOrdinate: event.changedTouches[0].pageX,
        yCoOrdinate: event.changedTouches[0].pageY
      }
    } else {
      this.endCordinates = {
        xCoOrdinate: event.changedTouches[0].pageX,
        yCoOrdinate: event.changedTouches[0].pageY
      }
      this.handleCarouselSwipe()
    }
  }

  /**
   * based on swipe direction, handles slide functionality
   */
  private handleCarouselSwipe(): void {
    let direction = this.xDirectionFinder()
    if (direction != DIRECTIONS.NO_SWIPE) {
      if (direction === DIRECTIONS.LEFT) {
        this.slideLeft()
      } else {
        this.slideRight()
      }
    }
  }

  /**
   * returns swipe direction
   */
  private xDirectionFinder(): string {
    if (Math.abs(this.startCordinates.xCoOrdinate - this.endCordinates.xCoOrdinate) < 20)
      return DIRECTIONS.NO_SWIPE
    if (this.startCordinates.xCoOrdinate <= this.endCordinates.xCoOrdinate) {
      return DIRECTIONS.LEFT
    } else {
      return DIRECTIONS.RIGHT
    }
  }

}
import { Pipe, PipeTransform } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Constants } from '../constants/lsnetx.constants';

@Pipe({
  name: 'isPopupMode'
})
export class IsPopupModePipe implements PipeTransform {
  constructor(
    private media: MediaObserver
  ) { }

  transform(dialog: any, ...args: any[]): any {
    return Constants.POPUP_ACTION && (!!dialog || !this.media.isActive('lt-sm'))
  }

}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * pipe for filtering null valued elements from array.
 */
@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  /**
   * @param value - array which is to be filtered.
   * @param args - none
   */
  transform(value: string[], args?: any): string[] {
    value.forEach((ele,index)=> {
      if(!ele){
        value.splice(index, 1)
      }
    })
    return value;
  }

}

<mat-form-field [floatLabel]="floatPlaceholder"> 
    <mat-select (focus)="focusHandler()" #f [value]="optionlist" [placeholder]="placeholder" (selectionChange)="onchange(f.value)"
        [disabled]="disabled" [multiple]="multiple" [required]="required" [(ngModel)]="optionlist" [tabIndex]="tabIndex">

        <mat-select-trigger *ngIf="allSelected">
            <span>{{allSelectedLabel}}</span>
        </mat-select-trigger>

        <mat-select-trigger *ngIf="showValueAsLabel">
            <span>{{ f.value }}</span>
        </mat-select-trigger>

        <mat-option style="position: sticky;top: 0;z-index: 2;border-bottom: 1px solid gray;" *ngIf="allow_searching" >
            <ngx-mat-select-search placeholderLabel="Start at..." [formControl]="filterCtrl" noEntriesFoundLabel="'No Match Found...'" ></ngx-mat-select-search>
        </mat-option>

        <mat-option [ngClass]="{'border-required' : border_required && i != options.length -1}" [value]="option.value" *ngFor="let option of (allow_searching ? filteredData : options);let i = index" [disabled]="option.disabled" style="z-index: 1;">{{option.label}}</mat-option>
    </mat-select>
    <mat-hint _ngcontent-c7="" class="mat-hint" [id]="id">
        {{hintText}} 
    </mat-hint>
</mat-form-field>
import { StoreUserAddressDto } from './storeUserAddressDto'

export class UserModel{
	userId : number
	email : string
	password : string
	userType : number
	userName : string
	fName : string
	mName : string
	lName : string
	gender : number
	mobile : string
	permAddrLine1 : string
	permAddrLine2 : string
	city : string
	state : string
	postalCode : string
	countryId : number
	dateOfBirth : Date
	currentlyLogin : number
	storeUserAddressDTOList:Array<StoreUserAddressDto>
	walletBalance: number
}